import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { cloneDeep, merge } from 'lodash';
import { ActiveToast, GlobalConfig, IndividualConfig, ToastrService } from 'ngx-toastr';
import { McToastComponent } from '../components/mc-toast/mc-toast.component';

@Injectable({
    providedIn: 'root',
})
export class McToastService {
    options: GlobalConfig;
    private lastInserted: number[] = [];

    constructor(private toastr: ToastrService, private store: Store) {
        this.options = this.toastr.toastrConfig;
    }

    clearToasts() {
        this.toastr.clear();
    }
    clearLastToast() {
        this.toastr.clear(this.lastInserted.pop());
    }

    private show(
        type: 'success' | 'danger' | 'warning' | 'info',
        message?: string,
        messageLength: 'short' | 'medium' | 'large' = 'short',
        options?: Partial<IndividualConfig>
    ): ActiveToast<any> {
        const clonedOptions = cloneDeep(this.options);
        const isLogged = true;
        let inserted = null;

        options = options ? merge(clonedOptions, options) : clonedOptions;

        options.toastComponent = McToastComponent;
        options.toastClass = `mc-toast mc-toast--${type}`;
        options.onActivateTick = true;

        if (isLogged) {
            inserted = this.toastr.show(message, messageLength, options);
            inserted.toastRef.componentInstance.type = type;
            inserted.toastRef.componentInstance.messageLength = messageLength;
            if (inserted.toastId) {
                this.lastInserted.push(inserted.toastId);
            }
        } else {
            this.clearToasts();
        }

        return inserted;
    }

    success(
        message?: string,
        messageLength?: 'short' | 'medium' | 'large',
        options?: Partial<IndividualConfig>
    ): ActiveToast<any> {
        return this.show('success', message, messageLength, options);
    }

    danger(
        message?: string,
        messageLength?: 'short' | 'medium' | 'large',
        options?: Partial<IndividualConfig>
    ): ActiveToast<any> {
        return this.show('danger', message, messageLength, options);
    }

    warning(
        message?: string,
        messageLength?: 'short' | 'medium' | 'large',
        options?: Partial<IndividualConfig>
    ): ActiveToast<any> {
        return this.show('warning', message, messageLength, options);
    }

    info(
        message?: string,
        messageLength?: 'short' | 'medium' | 'large',
        options?: Partial<IndividualConfig>
    ): ActiveToast<any> {
        return this.show('info', message, messageLength, options);
    }
}
