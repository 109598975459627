import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { CommomHeaders } from '@app/_shared/httpHeaders/CommonHeaders';

@Injectable({
    providedIn: 'root',
})
export class DesignService {
    constructor(private api: ApiService, private headers: CommomHeaders) {}

    getSiteStructure(): Observable<any> {
        return this.api.getFz(`/api/v1/back/layout/structure`, this.headers.getAuthHeaders());
    }

    setSiteStructure(data): Observable<any> {
        return this.api.put(`/api/v1/back/layout/structure`, data, this.headers.getAuthHeaders());
    }

    getSiteHeaderInfo(): Observable<any> {
        return this.api.getFz(`/api/v1/back/layout/header`, this.headers.getAuthHeaders());
    }

    setSiteHeaderInfo(data): Observable<any> {
        return this.api.put(`/api/v1/back/layout/header`, data, this.headers.getAuthHeaders());
    }

    getSiteFooterInfo(): Observable<any> {
        return this.api.getFz(`/api/v1/back/layout/footer`, this.headers.getAuthHeaders());
    }

    setSiteFooterInfo(data): Observable<any> {
        return this.api.put(`/api/v1/back/layout/footer`, data, this.headers.getAuthHeaders());
    }

    getCssAdvanced(): Observable<any> {
        return this.api.getFz(`/api/v1/back/config/custom-css`, this.headers.getAuthHeaders());
    }

    setCssAdvanced(data): Observable<any> {
        return this.api.put(`/api/v1/back/config/custom-css`, data, this.headers.getAuthHeaders());
    }

    setSocialImage(data: FormData) {
        return this.api.postFz(`/api/v1/back/config/social-image`, data, this.headers.getAuthHeaders());
    }
}
