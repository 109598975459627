import { Injectable } from '@angular/core';
import {
    ConectionData,
    CreateListPayload,
    MailListItem,
    ManualSyncPayload,
    ManualSyncResponse,
    UpdateConectionPayload,
} from '@app/_models/integrations/envialo-simple.model';
import { ApiService } from '@app/_services/api.service';
import { CommomHeaders } from '@app/_shared/httpHeaders/CommonHeaders';
import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EnvialoSimpleService {
    constructor(private api: ApiService, private headers: CommomHeaders) {}

    getContactList() {
        return this.api.post(`https://api.esmsv.com/v1/contacts/getall`, this.headers.getAuthHeaders());
    }

    createContact(contact) {
        return this.api.postFz(`https://api.esmsv.com/v1/contacts/create`, contact, this.headers.getAuthHeaders());
    }

    suscribeContact(contact) {
        return this.api.postFz(`https://api.esmsv.com/v1/contacts/suscribe`, contact, this.headers.getAuthHeaders());
    }

    // Guarda los valores de configuración seleccionados por el usuario.
    updateConection(payload: UpdateConectionPayload) {
        return this.api.put(`/api/v1/back/envialosimple/UpdateConection`, payload, this.headers.getAuthHeaders());
    }

    // Muestra los valores seteados en la configuración
    showConection(): Observable<ConectionData> {
        return this.api
            .getFz(`/api/v1/back/envialosimple/ShowConection`, this.headers.getAuthHeaders())
            .pipe(map((response) => response?.data));
    }

    /*** Sincroniza manualmente con Envialo Simple mediante un archivo csv temporal que se genera en base
     * a los clientes y sus compras(registrados e invitados). Para realizar la exportación toma en cuenta
     * los valores seteados en la configuración. ***/
    manualSync(payload: ManualSyncPayload): Observable<ManualSyncResponse> {
        return this.api.postFz(`/api/v1/back/envialosimple/SyncUp`, payload, this.headers.getAuthHeaders());
    }

    // Muestra las listas de correo existentes
    showList(): Observable<MailListItem[]> {
        return this.api
            .postFz(`/api/v1/back/envialosimple/ShowLists`, this.headers.getAuthHeaders())
            .pipe(map((response) => response?.data?.data?.data));
    }

    // Crea una nueva lista de correo
    createList(payload: CreateListPayload) {
        return this.api.postFz(`/api/v1/back/envialosimple/CreateList`, payload, this.headers.getAuthHeaders());
    }
}
