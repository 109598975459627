import { Component } from '@angular/core';
import { ShowAppVersionBanner } from '@app/core/store/app.actions';
import { slideInOut } from '@app/_animations';
import { utils } from '@app/_utils';
import { Store } from '@ngxs/store';

@Component({
    selector: 'version-bar',
    templateUrl: './version-bar.component.html',
    styleUrls: ['./version-bar.component.scss'],
    animations: [slideInOut],
})
export class VersionBarComponent {
    constructor(private store: Store) {}

    close() {
        this.store.dispatch(new ShowAppVersionBanner(false));
    }
    reload() {
        utils.clearCache(true);
        this.store.dispatch(new ShowAppVersionBanner(false));
    }
}
