import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { UserHelper } from '@app/_shared/helpers/UserHelper';
import { CookieData, TokensResponse, UserData } from '@app/_models/users/user';
import { environment } from '@env/environment.local';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    constructor(public userHelper: UserHelper) {}
    private isRefreshing: boolean = false;
    private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token;
        this.userHelper.getTokenFromParams() === null
            ? (token = this.userHelper.getUserData()?.token)
            : (token = this.userHelper.getTokenFromParams());
        if (token) {
            request = this.updateHeaders(request, token);
        }

        return next.handle(request).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse && error.error.code === '002') {
                    return this.handleError(request, next);
                } else if (error.error.code === '001') {
                    this.userHelper.logout(environment.name === 'PROD');
                } else if (error.error.code === '003') {
                    this.userHelper.logout();
                } else {
                    return throwError(error);
                }
            })
        );
    }

    private updateHeaders(req: HttpRequest<any>, token: string) {
        return req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    }

    private handleError(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            return this.userHelper.getJWTCookie().pipe(
                switchMap((res) => {
                    if (res) {
                        return this.userHelper.refreshToken(res?.refreshToken).pipe(
                            switchMap((tokens: TokensResponse) => {
                                const userDataToUpdate = this.userHelper.getUserData();
                                const data: UserData = {
                                    token: tokens?.token,
                                    userName: userDataToUpdate.userName,
                                    rememberMe: userDataToUpdate.rememberMe,
                                };
                                const cookieData: CookieData = {
                                    value: tokens.refreshToken,
                                    rememberMe: userDataToUpdate.rememberMe,
                                };
                                this.userHelper.setJWTCookie(cookieData);
                                this.userHelper.storageUserData(data);
                                this.isRefreshing = false;
                                this.refreshTokenSubject.next(tokens.token);
                                return next.handle(this.updateHeaders(request, tokens.token));
                            }),
                            catchError((error) => {
                                this.userHelper.logout();
                                throw error;
                            })
                        );
                    }
                }),
                catchError((error) => {
                    this.userHelper.logout();
                    throw error;
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter((token: string | null) => {
                    return token != null;
                }),
                take(1),
                switchMap((jwt: string) => {
                    return next.handle(this.updateHeaders(request, jwt));
                })
            );
        }
    }
}
