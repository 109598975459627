export const associatedVerticals = [
    {
        id: 3,
        name: 'Tienda online',
    },
    {
        id: 2,
        name: 'Restaurante',
    },
    {
        id: 3,
        name: 'Personal / Profesional',
    },
    {
        id: 4,
        name: 'Diseño',
    },
    {
        id: 5,
        name: 'Fotografía',
    },
    {
        id: 6,
        name: 'Música',
    },
    {
        id: 7,
        name: 'Belleza & Bienestar',
    },
    {
        id: 8,
        name: 'Mi portfolio',
    },
    {
        id: 9,
        name: 'Eventos',
    },
    {
        id: 10,
        name: 'Turismo',
    },
    {
        id: 11,
        name: 'Otro',
    },
    {
        id: 3,
        name: 'Negocios',
    },
    {
        id: 3,
        name: 'Distribuidora',
    },
    {
        id: 3,
        name: 'Ferretería',
    },
    {
        id: 3,
        name: 'Bebidas',
    },
    {
        id: 3,
        name: 'Alimentos',
    },
    {
        id: 3,
        name: 'Accesorios',
    },
    {
        id: 2,
        name: 'Comida/Gastronomía',
    },
    {
        id: 3,
        name: 'Tecnología e informática',
    },
    {
        id: 3,
        name: 'Electrónica',
    },
    {
        id: 3,
        name: 'Transporte',
    },
    {
        id: 3,
        name: 'Automóviles',
    },
    {
        id: 3,
        name: 'Juguetes / Videojuegos',
    },
    {
        id: 3,
        name: 'Artes',
    },
    {
        id: 7,
        name: 'Salud',
    },
    {
        id: 3,
        name: 'Medicina',
    },
    {
        id: 7,
        name: 'Recreación',
    },
    {
        id: 7,
        name: 'Deporte',
    },
    {
        id: 8,
        name: 'Curriculum Vitae',
    },
    {
        id: 3,
        name: 'Comunidad',
    },
    {
        id: 3,
        name: 'Consultoría',
    },
    {
        id: 5,
        name: 'Revista',
    },
    {
        id: 3,
        name: 'Joyería',
    },
    {
        id: 3,
        name: 'Marketing',
    },
    {
        id: 3,
        name: 'Educación',
    },
    {
        id: 5,
        name: 'Moda',
    },
    {
        id: 10,
        name: 'Viajes',
    },
    {
        id: 3,
        name: 'Política',
    },
    {
        id: 3,
        name: 'Organizaciones benéficas / Sin fines de lucro',
    },
    {
        id: 3,
        name: 'Carpintería',
    },
    {
        id: 3,
        name: 'Fontanería',
    },
    {
        id: 7,
        name: 'Barbería y peluquería',
    },
    {
        id: 4,
        name: 'Diseño gráfico',
    },
    {
        id: 3,
        name: 'Recaudación de fondos',
    },
    {
        id: 3,
        name: 'Ingeniería',
    },
    {
        id: 7,
        name: 'Nutrición',
    },
    {
        id: 3,
        name: 'Inmobiliarias',
    },
    {
        id: 7,
        name: 'Yoga',
    },
    {
        id: 6,
        name: 'Sonido',
    },
    {
        id: 7,
        name: 'Fitness',
    },
    {
        id: 2,
        name: 'Bares',
    },
    {
        id: 4,
        name: 'Renovaciones del hogar',
    },
    {
        id: 7,
        name: 'Fútbol',
    },
    {
        id: 7,
        name: 'Ciclismo',
    },
    {
        id: 7,
        name: 'Mente y espíritu',
    },
    {
        id: 2,
        name: 'Cafetería y panadería',
    },
    {
        id: 6,
        name: 'Dj',
    },
    {
        id: 9,
        name: 'Bodas',
    },
    {
        id: 7,
        name: 'Motociclismo',
    },
    {
        id: 3,
        name: 'Hostelería y alquileres',
    },
    {
        id: 5,
        name: 'Perros',
    },
    {
        id: 5,
        name: 'Animales',
    },
    {
        id: 5,
        name: 'Naturaleza',
    },
    {
        id: 3,
        name: 'Libros',
    },
    {
        id: 5,
        name: 'Familia y amigos',
    },
    {
        id: 9,
        name: 'Clubes y asociaciones',
    },
    {
        id: 7,
        name: 'Spa',
    },
    {
        id: 7,
        name: 'Masaje y ejercicio físico',
    },
    {
        id: 7,
        name: 'Terapia',
    },
    {
        id: 4,
        name: 'Arquitectura',
    },
    {
        id: 7,
        name: 'Peluquería y maquillaje',
    },
    {
        id: 5,
        name: 'Navegación',
    },
    {
        id: 3,
        name: 'Finanzas / Legal',
    },
    {
        id: 3,
        name: 'Formación y enseñanza',
    },
    {
        id: 3,
        name: 'Artesanía',
    },
    {
        id: 5,
        name: 'Caballos y equitación',
    },
    {
        id: 3,
        name: 'Construcción',
    },
    {
        id: 3,
        name: 'Oficios',
    },
    {
        id: 7,
        name: 'Actividades al aire libre',
    },
    {
        id: 9,
        name: 'Danza y espectáculos',
    },
    {
        id: 5,
        name: 'Paisajismo y jardinería',
    },
    {
        id: 3,
        name: 'Relaciones públicas',
    },
    {
        id: 8,
        name: 'Blog',
    },
];
