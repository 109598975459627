import { SalesFilters } from '@app/_models/e-commerce/sales/sales.model';

export class GetSales {
    public static readonly type = '[Sales] GetSales';
    constructor() {}
}

export class SetFilters {
    public static readonly type = '[Sales] SetFilters';
    constructor(public filter: SalesFilters) {}
}

export class GetOrder {
    public static readonly type = '[Sales] GetOrder';
    constructor(public order_id: any) {}
}

export class EditOrder {
    public static readonly type = '[Sales] EditOrder';
    constructor(public data: any) {}
}

export class GetAbandonedCarts {
    public static readonly type = '[Sales] GetAbandonedCarts';
    constructor() {}
}

export class SetFiltersCarts {
    public static readonly type = '[Sales] SetFiltersCarts';
    constructor(public filterCarts: any) {}
}

export class GetCouponsList {
    public static readonly type = '[Sales] GetCouponsList';
    constructor() {}
}

export class SendEmail {
    public static readonly type = '[Sales] SendEmail';
    constructor(public data: any) {}
}

export class DownloadOrder {
    public static readonly type = '[Sales] DownloadOrder';
    constructor(public order_id: number) {}
}
