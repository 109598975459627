import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { StepperState } from '@app/components/ss-stepper/store/stepper.state';
import { SidebarState } from '@app/core/sidebar/store/sidebar.state';
import { SaveToken } from '@app/pages/dashboard/store/dashboard.actions';
import { McRouter } from '@app/_services';
import { MessagingService } from '@app/_services/push-notifications/messaging-service';
import { LanguageHelper } from '@app/_shared/helpers/LanguageHelper';
import { UserHelper } from '@app/_shared/helpers/UserHelper';
import { environment } from '@env/environment.local';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { combineLatest, fromEvent, merge, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Session } from '../_interfaces';
import {
    GetServiceSummary,
    Logout,
    SetGlobalLoading,
    SetIsMobile,
    SetIsOnline,
    SetLastVisit,
    SetUserName,
} from './store/app.actions';
import { AppState } from './store/app.state';

declare var Chat: any;
declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    isMobileEvent: Subject<boolean> = new Subject();
    rootSubscription: Subscription;
    chatSubscription: Subscription;
    lastVisitSubscription: Subscription;
    scrollToSubscription: Subscription;
    isMobileSubscription: Subscription;
    isOnlineSubscription: Subscription;
    trialEndedNotificationHeight: number;
    remainingDemoDays: number;
    showBanner: boolean = false;
    bannerType: 'expired' | 'deleted' | 'disabled';

    @Select(AppState.loading)
    loading$: Observable<boolean>;

    @Select(AppState.session)
    session$: Observable<Session>;

    @Select(AppState.lastVisit)
    lastVisit$: Observable<string>;

    @Select(AppState.getConfig)
    config$: Observable<any>;

    @Select(AppState.isLogged)
    isLogged$: Observable<boolean>;

    @Select(AppState.hideSidebar)
    hideSidebar$: Observable<boolean>;

    @Select(AppState.isMobile)
    isMobile$: Observable<boolean>;

    @Select(SidebarState.collapsed)
    sidebarCollapsed$: Observable<boolean>;

    @Select(AppState.wizardStatus)
    wizardStatus$: Observable<boolean>;

    @Select(StepperState.isLoading)
    isWizardLoading$: Observable<boolean>;

    @Select(AppState.getInternalServerError)
    internalServerError$: Observable<boolean>;

    @Select(AppState.showAppVersionBanner)
    showAppVersionBanner$: Observable<boolean>;

    @HostListener('window:resize', ['$event'])
    onWindowResize(event?: any) {
        const isMobile = window.innerWidth < 768;
        this.isMobileEvent.next(isMobile);
    }

    constructor(
        private router: Router,
        private store: Store,
        private actions: Actions,
        private mcRouter: McRouter,
        private languageHelper: LanguageHelper,
        private translate: TranslateService,
        private userHelper: UserHelper,
        private messagingService: MessagingService
    ) {
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang(this.languageHelper.getLanguage());
        this.saveHostName();

        if (environment.enablePushNotifications) {
            const fcmToken = localStorage.getItem('fcmToken');
            if (fcmToken && fcmToken !== 'null') {
                this.messagingService.receiveMessaging();
                this.store.dispatch(new SaveToken(fcmToken));
            } else {
                this.messagingService.requestPermission();
            }
        }
    }

    ngOnInit(): void {
        // Obtenemos resumenDeServicios si no es /ingresar.
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                tap((event: NavigationEnd) => {
                    if (this.isLogin(event)) {
                        this.store.dispatch(new SetGlobalLoading(false));
                    }
                }),
                filter((event: NavigationEnd) => !this.isLogin(event)),
                take(1),
                switchMap(() => this.store.dispatch(new GetServiceSummary()))
            )
            .subscribe(() => {
                this.store.dispatch(new SetGlobalLoading(false));
            });

        // Redirecciona al locale correspondiente si viene por defecto: admin
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                filter((event: NavigationEnd) => event.urlAfterRedirects.indexOf('admin') > -1),
                map((event: NavigationEnd) => event.urlAfterRedirects),
                switchMap(() => this.isLogged$.pipe(filter((isLogged) => !!isLogged))),
                take(1)
            )
            .subscribe(() => {
                const url = this.router.url.replace('admin', this.store.selectSnapshot(AppState.adminPrefix));
                this.router.navigateByUrl(url);
            });

        // Evento de Logout con éxito
        this.actions.pipe(ofActionSuccessful(Logout)).subscribe(() => {
            this.mcRouter.navigate(`/`, {
                externalLink: true,
                target: '_self',
            });
        });

        // En caso de ingresar a "/", redirecciona al Dashboard o al Login
        this.rootSubscription = combineLatest(
            this.router.events.pipe(
                filter((event) => event instanceof NavigationEnd),
                filter((event: NavigationEnd) => event.urlAfterRedirects === '/'),
                map((event: NavigationEnd) => event.urlAfterRedirects),
                take(1)
            ),
            this.isLogged$
        ).subscribe(([url, isLogged]) => {
            if (isLogged) this.router.navigate(['admin']);
            else this.router.navigate(['ingresar']);
        });

        // Chequeamos si es mobile o no.
        this.isMobileSubscription = this.isMobileEvent
            .pipe(debounceTime(10), distinctUntilChanged())
            .subscribe((isMobile) => {
                this.store.dispatch([new SetIsMobile(isMobile)]);
            });

        // Chequeamos si está online o offline.
        this.isOnlineSubscription = merge<any>(
            fromEvent(window, 'offline').pipe(map(() => false)),
            fromEvent(window, 'online').pipe(map(() => true))
        ).subscribe((value) => {
            this.store.dispatch(new SetIsOnline(value));
        });

        // LAST VISIT
        this.lastVisitSubscription = this.router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                map((event: NavigationStart) => decodeURIComponent(event.url))
            )
            .subscribe((url: string) => this.store.dispatch(new SetLastVisit(url)));

        // SCROLL TO TOP
        this.scrollToSubscription = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => window.scroll(0, 0));

        // Trigger resize al iniciar
        this.onWindowResize();

        // live-chat configuration
        this.config$.subscribe((config) => {
            (<any>window).LiveChatWidget.call('set_session_variables', {
                email: config.emailcontacto,
                marketId: config.idMarket,
                isDemo: !!config.esdemo,
            });
        });
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (event.url === '/admin/ingresar') {
                    (<any>window).LiveChatWidget.call('minimize');
                } else {
                    (<any>window).LiveChatWidget.call('hide');
                }
            }
        });

        this.setTrialEndedNotificationHeight();
        this.setSiteInfo();
        this.userHelper.getUserName() ? this.store.dispatch(new SetUserName(this.userHelper.getUserName())) : null;
        this.setUpAnalytics();
    }

    ngOnDestroy(): void {
        this.rootSubscription.unsubscribe();
        this.chatSubscription.unsubscribe();
        this.lastVisitSubscription.unsubscribe();
        this.scrollToSubscription.unsubscribe();
        this.isMobileSubscription.unsubscribe();
        this.isOnlineSubscription.unsubscribe();
    }

    isLogin(event: NavigationEnd): boolean {
        return event.urlAfterRedirects.indexOf('ingresar') > -1;
    }

    setTrialEndedNotificationHeight() {
        this.config$.subscribe(() => {
            const trialEndedElement = document.querySelector('.trial-container');
            if (trialEndedElement) {
                this.trialEndedNotificationHeight = trialEndedElement.clientHeight;
            }
        });
    }

    saveHostName(): void {
        localStorage.setItem('hostName', document.location.host);
    }

    setSiteInfo() {
        this.config$.subscribe((config) => {
            const { is_demo, borrado, habilitado, diasdepruebarestantes } = config;
            this.remainingDemoDays = diasdepruebarestantes;
            this.showBanner = (is_demo && diasdepruebarestantes <= -1) || borrado || habilitado === false;
            if (is_demo && diasdepruebarestantes <= -1) this.bannerType = 'expired';
            if (!habilitado) this.bannerType = 'disabled';
            if (borrado) this.bannerType = 'deleted';
        });
    }

    setUpAnalytics() {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            gtag('config', 'G-YOUR-GOOGLE-ID', {
                page_path: event.urlAfterRedirects,
            });
        });
    }
}
