import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ss-img',
    templateUrl: './ss-image.component.html',
    styleUrls: ['./ss-image.component.scss'],
})
export class SsImageComponent {
    @Input() imgClass: string = 'w-100 h-100';
    @Input() src: string = '';
    @Input() alt: string = '';
    @Output() loadImgError: EventEmitter<string> = new EventEmitter<string>();

    loading: boolean = true;

    constructor() {}

    setLoading(e?) {
        this.loading = false;
        if (e) {
            const croppedUrl: string[] = this.src.split('/');
            const imgFullName = croppedUrl[croppedUrl.length - 1];
            const fileExtension = imgFullName.split('-')[1].split('.')[1];
            const croppedImg = imgFullName.split('-')[0] + '.' + fileExtension;
            this.loadImgError.emit(croppedImg);
        }
    }
}
