import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { UserHelper } from '@app/_shared/helpers/UserHelper';

@Injectable({
    providedIn: 'root',
})
export class ResponseHelper {
    constructor(private userHelper: UserHelper) {}

    checkError(response: any): any {
        if (!response.data) {
            return {
                success: false,
            };
        } else {
            return {
                success: true,
            };
        }
    }

    checkEcommerceResponse(response): boolean {
        return response.success ? true : false;
    }
}
