import { Page } from '@app/_models/pages/page.model';

export class GetPageListAction {
    public static readonly type = '[SitePages] List pages';
    constructor(public idLang?: number) {}
}
export class CreatePage {
    public static readonly type = '[SitePages] CreatePage';
    constructor(public page: any) {}
}
export class CreateFolder {
    public static readonly type = '[SitePages] CreateFolder';
    constructor(public page: any) {}
}

export class CreateNewLink {
    public static readonly type = '[SitePages] CreateNewLink';
    constructor(public link: any) {}
}
export class DeletePageAction {
    public static readonly type = '[SitePages] Delete page';
    constructor(public id: number, public language: any) {}
}

export class DuplicatePageAction {
    public static readonly type = '[SitePages] Duplicate page';
    constructor(public id: any, public idLang: number) {}
}

export class ReorderPagesAction {
    public static readonly type = '[SitePages] ReorderPages';
    constructor(public pages: any, public language: number) {}
}

export class GetCategoriesForNewPage {
    public static readonly type = '[SitePages] Get categories for new page';
    constructor() {}
}

export class EditMode {
    public static readonly type = '[SitePages] EditMode';
    constructor() {}
}

export class DisableEditMode {
    public static readonly type = '[SitePages] DisableEditMode';
    constructor() {}
}
export class UpdatePageAction {
    public static readonly type = '[SitePages] UpdatePageAction';
    constructor(public page: any) {}
}

export class ReloadIframeUrl {
    public static readonly type = '[SitePages] ReloadIframeUrl';
    constructor() {}
}
