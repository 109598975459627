import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
    selector: 'app-keyword-pills',
    templateUrl: './keyword-pills.component.html',
    styleUrls: ['./keyword-pills.component.scss'],
})
export class KeywordPillsComponent implements OnChanges {
    chipCtrl = new UntypedFormControl();
    addPageForm: UntypedFormGroup;
    selectable = true;
    removable = true;
    addOnBlur = true;

    readonly separatorKeysCodes = [ENTER, COMMA] as const;

    @Input() pills: any = [];
    @Input() maxPills: number = 8;
    @Input() placeholder: string = 'Escriba sus palabras clave aquí';
    @Input() hasError: boolean = false;
    @Input() label: string;
    @Input() tooltipText: string;
    @Input() tooltipCustomClass: string;
    @Input() colorType: boolean = false;
    @Input() externalValidationbeforeRemove: boolean = false;
    @Input() inputLabel: string;
    @Input() deletedPill: { index?: number; deleted?: boolean };
    @Input() nonCreatedPill: string;
    @Output() emmitChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() doubleClickPillIndex: EventEmitter<number> = new EventEmitter<number>();
    @Output() validatePillToRemove: EventEmitter<number> = new EventEmitter<number>();
    @Output() addedPill: EventEmitter<any> = new EventEmitter<any>();

    removePillsList: { key?: number; loading?: boolean } = {};

    constructor(private fb: UntypedFormBuilder) {}
    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.deletedPill?.currentValue) {
            this.removePillsList[changes?.deletedPill?.currentValue.index] = false;
            if (changes?.deletedPill?.currentValue.deleted) {
                const pillToRemove = this.pills[changes?.deletedPill?.currentValue.index];
                if (pillToRemove) {
                    this.remove(pillToRemove);
                }
            }
        }

        if (changes?.nonCreatedPill?.currentValue) {
            this.pills = this.pills.filter((p) => p.name !== changes?.nonCreatedPill?.currentValue);
        }
    }

    add(event: MatChipInputEvent): void {
        const value: string = (event.value || '').trim();

        if (value?.length <= 35 && value?.length >= 1 && this.pills?.length < this.maxPills && !this.hasError) {
            this.pills.push({ name: value });
            this.addedPill.emit(this.pills[this.pills.length - 1]);
        }
        event.chipInput!.clear();

        this.chipCtrl.setValue(null);
        this.emmitChange.emit(true);
    }

    remove(pills: any): void {
        const index = this.pills.indexOf(pills);

        if (index >= 0) {
            this.pills.splice(index, 1);
        }
        this.emmitChange.emit(true);
    }

    editPill(index: number): void {
        this.doubleClickPillIndex.emit(index);
    }

    validateRemoveChip(index: number): void {
        this.validatePillToRemove.emit(index);
        this.removePillsList[index] = true;
    }
}
