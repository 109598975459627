import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import { Component, Input, OnInit } from '@angular/core';
import { AppState } from '@app/core/store/app.state';
import { StepperHelper } from '@app/_shared/helpers/StepperHelper';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GetMarketSections, GetTemplates, SetMarketSections } from '../../store/stepper.actions';
import { StepperState } from '../../store/stepper.state';
import { environment } from '@env/environment.local';

@Component({
    selector: 'market-sections',
    templateUrl: './market-sections.component.html',
    styleUrls: ['./market-sections.component.scss'],
})
export class MarketSectionsComponent implements OnInit {
    loading: boolean;
    sectionsOther: any = [];
    idSectionsList: number[] = [];
    otherListId: number[] = [];
    recommendedListId: number[] = [];
    executeRequest: boolean;
    sectionsRecommended: any = [];
    sectionsToSend: any = [];
    assetsUrl: string = environment.assets_url;
    mfsPath: string = environment.mfs_path;

    @Input() stepName: string;

    @Select(StepperState.marketSectionsRecommended)
    marketSectionsRecommended$: Observable<any>;

    @Select(StepperState.marketSectionsOther)
    marketSectionsOther$: Observable<any>;

    @Select(AppState.getVerticalType)
    verticalType$: Observable<number>;

    constructor(
        private store: Store,
        private stepperHelper: StepperHelper,
        private cdkStepper: CdkStepper,
        private cdkStep: CdkStep
    ) {}

    ngOnInit(): void {
        this.loading = true;
        this.store.dispatch(new GetMarketSections()).subscribe(() => (this.loading = false));
        this.stepperHelper.setCurrentStepName(this.stepName);
        this.stepperHelper.rememberStatus(this.stepName, this.cdkStep);
        this.verticalType$.subscribe((id) => {
            const selectedTemplate = this.store.selectSnapshot(StepperState.templateSelected);
            if (Object.keys(selectedTemplate).length === 0) {
                this.store.dispatch(new GetTemplates(id)).subscribe(() => this.hasDataLoaded());
            } else {
                this.hasDataLoaded();
            }
        });
    }

    sendSections() {
        this.loading = true;
        const dataToSend = [...this.sectionsRecommended, ...this.sectionsOther];
        this.store.dispatch(new SetMarketSections(dataToSend)).subscribe({
            next: () => {
                this.updateDataSections();
                this.loading = false;
                this.stepperHelper.sendStepperStatus();
                this.cdkStepper.next();
            },
        });
    }

    hasDataLoaded() {
        const dataLoaded = this.stepperHelper.getDataStep(this.stepName);
        const templateUsedBefore = dataLoaded?.selectedTemplate;
        const selectedTemplate = this.store.selectSnapshot(StepperState.templateSelected);
        const isSameTemplate = selectedTemplate?.id === templateUsedBefore?.id;
        if (dataLoaded && isSameTemplate) {
            this.idSectionsList = [];
            dataLoaded.other ? (this.sectionsOther = dataLoaded.other) : (this.sectionsOther = []);
            this.setIdSectionsList(this.sectionsOther);
            dataLoaded.recommended ? (this.sectionsRecommended = dataLoaded.recommended) : this.loadDataRecommended();
            this.setIdSectionsList(this.sectionsRecommended);
        } else {
            this.loadDataRecommended();
        }
    }

    setIdSectionsList(data): void {
        if (data) {
            data.forEach((element) => {
                this.idSectionsList.push(element.id);
            });
        }
    }

    updateDataSections(): void {
        const sectionsSelected = {
            other: this.sectionsOther,
            recommended: this.sectionsRecommended,
            selectedTemplate: this.store.selectSnapshot(StepperState.templateSelected),
        };
        this.stepperHelper.updateDataStep(this.stepName, sectionsSelected);
    }

    loadDataRecommended() {
        this.marketSectionsRecommended$.subscribe((value) => {
            this.idSectionsList = [];
            this.sectionsRecommended = value;
            this.setIdSectionsList(this.sectionsRecommended);
        });
    }

    selectRecommendedSection(id) {
        const existIdInList = this.idSectionsList.includes(id);

        if (!existIdInList) {
            this.recommendedListId.push(id);
            const section = { id: id };
            this.sectionsRecommended.push(section);
            this.addIdInList(id);
        } else {
            const section = this.sectionsRecommended.find((section) => section.id == id);
            if (section) {
                const recommendedIdFiltered = this.recommendedListId.filter((section) => section != id);
                this.recommendedListId = recommendedIdFiltered;
                const newSectionsSelectedList = this.sectionsRecommended.filter((section) => section.id != id);
                this.sectionsRecommended = newSectionsSelectedList;
                this.removeIdFromList(id);
            }
        }
        this.updateDataSections();
    }

    removeIdFromList(value: number) {
        const index = this.idSectionsList.indexOf(value);
        if (index > -1) {
            this.idSectionsList.splice(index, 1);
        }
    }

    addIdInList(value: number) {
        this.idSectionsList.push(value);
    }

    selectOtherSection(id) {
        const existIdInList = this.idSectionsList.includes(id);

        if (!existIdInList) {
            this.otherListId.push(id);
            const section = { id: id };
            this.sectionsOther.push(section);
            this.addIdInList(id);
        } else {
            const section = this.sectionsOther.find((section) => section.id == id);
            if (section) {
                const othersIdFiltered = this.otherListId.filter((section) => section != id);
                this.otherListId = othersIdFiltered;
                const newSectionsSelectedList = this.sectionsOther.filter((section) => section.id != id);
                this.sectionsOther = newSectionsSelectedList;
                this.removeIdFromList(id);
            }
        }
        this.updateDataSections();
    }
}
