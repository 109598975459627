<div class="d-flex popup-input p-3 p-lg-0">
    <div class="card w-100">
        <div>
            <h5>{{ parametros.title }}</h5>
        </div>
        <div class="card-body p-0">
            <form
                [formGroup]="renamePageForm"
                (ngSubmit)="onFormSubmit(renamePageForm.valid)"
                class="d-flex flex-column justify-content-between h-100 w-100"
            >
                <div class="d-block mt-2">
                    <input
                        type="text"
                        formControlName="rename_page"
                        class="form-control"
                        [placeholder]="'app.pagina.mi_blog' | translate"
                    />
                </div>
                <div class="d-flex justify-content-between justify-content-sm-end mt-3 mt-lg-4">
                    <button class="btn btn-skip mr-0 mr-sm-4" mat-dialog-close>
                        {{ 'app.domains.cancelar' | translate }}
                    </button>
                    <app-ss-button [loading]="loading" [text]="'app.domains.aceptar' | translate"></app-ss-button>
                </div>
            </form>
        </div>
    </div>
</div>
