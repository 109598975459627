export class GetBasicInfo {
    public static readonly type = '[BasicInfo] GetBasicInfo';
    constructor() {}
}
export class SetBasicInfo {
    public static readonly type = '[BasicInfo] SetBasicInfo';
    constructor(public basicInfo: any) {}
}
export class SetPrivacyData {
    public static readonly type = '[BasicInfo] SetPrivacyData';
    constructor(public basicInfo: any) {}
}
