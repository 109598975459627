import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { ApiService } from '../api.service';
import { CommomHeaders } from '@app/_shared/httpHeaders/CommonHeaders';
import { InitialInfo, SidebarMenuItem } from '@app/_interfaces';

@Injectable({
    providedIn: 'root',
})
export class SidebarService {
    constructor(private api: ApiService, private headers: CommomHeaders) {}

    getSidebarMenu(): Observable<SidebarMenuItem[]> {
        return this.api.getFz(`/api/v1/back/menu`, this.headers.getAuthHeaders());
    }

    getInitialInfo(): Observable<InitialInfo> {
        return this.api.getFz(`/api/v1/back/info/initial`, this.headers.getAuthHeaders());
    }
}
