import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommomHeaders } from '@app/_shared/httpHeaders/CommonHeaders';
import { Step } from '@app/_models/steps/step.model';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class StepsService {
    constructor(private api: ApiService, private headers: CommomHeaders) {}

    getStepsList(wizardName: string): Observable<Step[]> {
        return this.api.getFz(`/api/v1/back/wizard/${wizardName}`, this.headers.getAuthHeaders());
    }

    setStepperStatus(stepperStatus: any) {
        return this.api.patch(
            `/api/v1/back/wizard/wizard-inicial/status`,
            stepperStatus,
            this.headers.getAuthHeaders()
        );
    }
    getMarketSections(): Observable<any> {
        return this.api.getFz(`/api/v1/back/pages/default`, this.headers.getAuthHeaders());
    }
    setMarketSections(sectionsList): Observable<any> {
        return this.api.postFz(`/api/v1/back/pages/default`, { pages: sectionsList }, this.headers.getAuthHeaders());
    }
    updateGeneralData(data): Observable<any> {
        return this.api.postFz(`/api/v1/back/config/main`, data, this.headers.getHeaderImage());
    }
}
