import { AbstractControl, ValidatorFn } from '@angular/forms';

export function passwordFerozo(excludeSepecialChars?: boolean): ValidatorFn {
    const checkMayus: RegExp = new RegExp(/[A-Z]/);
    const checkMinus: RegExp = new RegExp(/[a-z]/);
    const checkNumber: RegExp = new RegExp(/[0-9]/);
    const checkSpecialCharacters: RegExp = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/);
    const passwordConsecutiveNumbers: RegExp = new RegExp(/(012|123|234|345|456|567|678|789)/);

    return (control: AbstractControl): { [key: string]: any } | null => {
        const valid =
            checkNumber.test(control.value) &&
            !passwordConsecutiveNumbers.test(control.value) &&
            checkMayus.test(control.value) &&
            checkMinus.test(control.value) &&
            (excludeSepecialChars ?? checkSpecialCharacters.test(control.value));

        return valid
            ? null
            : {
                  validators: {
                      checkMayus: !checkMayus.test(control.value),
                      checkMinus: !checkMinus.test(control.value),
                      checkNumber: !checkNumber.test(control.value),
                      checkSpecialCharacters: !checkSpecialCharacters.test(control.value),
                      consecutiveNumbers: control.value !== '' ? passwordConsecutiveNumbers.test(control.value) : true,
                  },
              };
    };
}
