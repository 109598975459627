<div class="full-screen-loading">
    <div class="d-flex container justify-content-center align-items-center w-100 h-100">
        <div class="col-12 d-flex flex-column align-items-center justify-content-center">
            <img
                src="{{ assetsUrl }}{{ mfsPath }}/img/front/wizard/finish/loading-wizard.png"
                alt=""
                class="img-loading"
            />
            <p class="texto-loading-title text-center mb-4">
                {{ 'app.wizard.trabajando_en_los_ultimos_detalles' | translate }}
            </p>
            <span class="texto-loading-subtitle text-center">{{ 'app.wizard.ya_casi_terminamos' | translate }}</span>
        </div>
    </div>
</div>
