<div class="trial-container" [ngStyle]="{ 'padding-left': 'calc(3rem + ' + sideBarWidth + 'px)' }">
    <div class="trial-container__wrapper">
        <div class="trial-container__wrapper__notification">
            <div class="trial-container__wrapper__notification__icon">
                <i class="fal fa-clock"></i>
            </div>
            <div class="trial-container__wrapper__notification__text">
                <p class="trial-container__wrapper__notification__text-title">
                    <span>{{ title.get(bannerType) }}</span> {{ subtitle.get(bannerType) }}
                </p>
                <p class="trial-container__wrapper__notification__text-subtitle">
                    {{ content.get(bannerType) }}
                </p>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <a
                class="trial-container__wrapper-link"
                href="https://sitiosimple.com/es-ar/planes-precios-pagina-web"
                target="_blank"
            >
                {{ ctaText.get(bannerType) }}
            </a>
        </div>
    </div>
    <div class="trial-container__overlayExpired" [ngStyle]="{ 'top.px': alertHeight }"></div>
</div>
