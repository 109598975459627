import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'mc-loading',
    templateUrl: './mc-loading.component.html',
    styleUrls: ['./mc-loading.component.scss'],
})
export class McLoadingComponent implements OnInit {
    @Input() overlay = false;
    @Input() fixed = false;
    @Input() label = '';
    @Input() type: 'border' | 'grow' = 'border';
    @Input() size: 'sm' | '' = '';
    @Input() color:
        | 'text-info'
        | 'text-warning'
        | 'text-danger'
        | 'text-success'
        | 'text-light'
        | 'text-dark'
        | 'color-secundario' = 'color-secundario';

    constructor() {}

    ngOnInit() {
        if (this.fixed) {
            this.overlay = true;
        }
    }
}
