import { Language } from '@app/_models/languages/language';

export const Languages: Language[] = [
    {
        id: 1,
        label: 'Español / Spanish',
        value: 'es',
        iso: 'es_AR',
        default: true,
        visible: true,
    },
    {
        id: 2,
        label: 'Inglés / English',
        value: 'en',
        iso: 'en_US',
        default: false,
        visible: true,
    },
    {
        id: 3,
        label: 'Portugués / Portuguese',
        value: 'pt',
        iso: 'pt_BR',
        default: false,
        visible: false,
    },
];
