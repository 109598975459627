import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import {
    CollapseSidebar,
    CurrencyAction,
    ForceCollapseSidebar,
    ForceExpandSidebar,
    GetInitialInfo,
    SetSidebarMenu,
    ToggleSidebarCollapse,
} from '@app/core/sidebar/store/sidebar.actions';
import { SidebarState } from '@app/core/sidebar/store/sidebar.state';
import { GetGeneralInfoApp } from '@app/core/store/app.actions';
import { AppState } from '@app/core/store/app.state';
import { DashboardState } from '@app/pages/dashboard/store/dashboard.state';
import { slideInOut } from '@app/_animations';
import { InitialInfo, Session, SidebarMenuItem } from '@app/_interfaces';
import { UserHelper } from '@app/_shared/helpers/UserHelper';
import { environment } from '@env/environment.local';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { ClipboardService } from 'ngx-clipboard';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import firebase from 'firebase/compat/app';

@Component({
    selector: 'mc-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    animations: [slideInOut],
})
export class SidebarComponent implements OnInit, OnDestroy {
    @ViewChild('htmlData', { static: true }) htmlData: ElementRef;
    url: string = ''; // environment.basePath
    uri: string = environment.url_slug;
    profile: string = '';
    value = '';
    userName: string = '';
    sendRequest: boolean = false;
    preventRequest: boolean = false;
    expandSidebarMobile: boolean = false;
    assetsUrl: string = environment.assets_url;
    mfsPath: string = environment.mfs_path;

    @Select(AppState.adminPrefix)
    adminPrefix$: Observable<string>;

    @Select(AppState.userName)
    userName$: Observable<string>;

    @Select(AppState.session)
    session$: Observable<Session>;

    @Select(AppState.siteAliases)
    siteAliases$: Observable<string>;

    @Select(AppState.marketSubdomain)
    marketSubdomain$: Observable<string>;

    @Select(AppState.getVerticalType)
    verticalName$: Observable<string>;

    @Select(AppState.isMobile)
    isMobile$: Observable<boolean>;

    @Select(SidebarState.menu)
    menu$: Observable<SidebarMenuItem[]>;

    @Select(SidebarState.collapsed)
    collapsed$: Observable<boolean>;

    @Select(SidebarState.collapseForced)
    collapseForced$: Observable<boolean>;

    @Select(AppState.wizardStatus)
    wizardStatus$: Observable<boolean>;

    @Select(AppState.lastVisit)
    lastVisit$: Observable<string>;

    @Select(SidebarState.initialInfo)
    initialInfo$: Observable<InitialInfo>;
    initialInfo: InitialInfo;

    collapseForcedSubscription: Subscription;
    collapseSidebarForClass: boolean;
    verticalName: string;
    route: string;
    urlQrHelper;
    userData: any;

    @Select(DashboardState.getNotifications)
    notifications$: Observable<any>;

    notifications: firebase.messaging.MessagePayload[] = [];

    constructor(
        private store: Store,
        private router: Router,
        private clipboardService: ClipboardService,
        private renderer: Renderer2,
        private userHelper: UserHelper,
        private translate: TranslateService
    ) {
        this.store.dispatch(new GetInitialInfo());

        this.router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationStart) {
                if (event.url !== '/admin/dashboard') this.sendRequest = true;
            }

            if (event instanceof NavigationEnd) {
                if (event.url !== '/admin/dashboard') this.sendRequest = true;
            }

            if (this.sendRequest && !this.preventRequest) {
                this.store.selectOnce(SidebarState.menu).subscribe((val) => {
                    if (val.length === 0) this.store.dispatch(new GetGeneralInfoApp());
                    this.preventRequest = true;
                });
            }
        });
        this.collapsed$.subscribe((el) => (this.collapseSidebarForClass = el));
        this.collapseForcedSubscription = this.collapseForced$.pipe(take(1)).subscribe((collapseForced: boolean) => {
            if (collapseForced) this.collapseSidebar();
        });
        this.getCurrencies();

        this.notifications$.subscribe((notifications) => {
            this.notifications = notifications;
        });
    }

    ngOnInit() {
        this.userName = this.userHelper?.getUserName();
        this.lastVisit$.subscribe((status) => {
            this.route = status;
        });

        this.initialInfo$.subscribe({
            next: (response) => {
                this.initialInfo = response;
            },
            error: (error) => {
                console.error(error);
            },
        });
    }

    ngOnDestroy(): void {
        this.collapseForcedSubscription.unsubscribe();
    }

    toggleSidebarCollapseForced() {
        const collapseForced = this.store.selectSnapshot(SidebarState.collapseForced);
        const collapsed = this.store.selectSnapshot(SidebarState.collapsed);
        if (collapseForced && collapsed) {
            this.store.dispatch(new ForceExpandSidebar());
        } else {
            this.store.dispatch(new ForceCollapseSidebar());
        }
    }

    toggleSidebarCollapse() {
        this.store.dispatch(new ToggleSidebarCollapse());
    }

    getCurrencies() {
        this.store.dispatch(new CurrencyAction());
    }

    collapseSidebar() {
        this.store.dispatch(new CollapseSidebar());
    }

    collapseMobileSidebar() {
        const menu = document.querySelector('.menu-mobile-expanded');
        menu?.classList.toggle('d-none');
        this.expandSidebarMobile = !this.expandSidebarMobile;
    }

    toggleMenuItem(menuItem: SidebarMenuItem, tooltipRef?: NgbTooltip) {
        const sidebarCollapsed = this.store.selectSnapshot(SidebarState.collapsed);
        const element = document.getElementById(`${menuItem.id}`);
        if (tooltipRef) tooltipRef.close();
        if (sidebarCollapsed) {
            this.store.dispatch(new ForceExpandSidebar());
            this.renderer.removeClass(element, 'collapsed');
            this.renderer.addClass(element, 'extended');
        } else {
            this.toggleCollapsedMenuItem(menuItem);
        }
    }

    goToMarket() {
        const marketSubdomainUrl = this.store.selectSnapshot(AppState.marketSubdomain);
        window.open(marketSubdomainUrl + '?stats=off', '_blank');
        this.expandSidebarMobile = false;
    }

    goToProfile() {
        const adminPrefixUrl = this.store.selectSnapshot(AppState.adminPrefix);
        this.router.navigate([adminPrefixUrl, 'usuario', 'perfil']);
        this.expandSidebarMobile = false;
    }

    // Method PDF
    getBase64Image(img) {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL('image/png');
        return dataURL;
    }

    toggleCollapsedMenuItem(menuItem: SidebarMenuItem) {
        let menu = this.store.selectSnapshot(SidebarState.menu);
        const menuItemIndex = menu.findIndex((child) => child.id === menuItem.id);
        menu[menuItemIndex].collapsed = !menu[menuItemIndex].collapsed;
        this.store.dispatch(new SetSidebarMenu(menu));
    }

    public newNotifications(): boolean {
        return this.notifications.some((n) => !n.data.readed || n.data.readed === 'false');
    }

    logout(): void {
        this.userHelper.logout(environment.name === 'PROD');
    }
}
