import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
    AddNotification,
    ClearAllNotifications,
    DeleteNotification,
    GetNotifications,
    GetThumbnail,
    GetWelcomeData,
    SaveToken,
    SetDashboardStatistics,
    SetDashboardStatisticsConTienda,
    SetWelcomeData,
} from './dashboard.actions';
import { DashboardService } from '@app/_services/dashboard/dashboard.service';
import { ResponseHelper } from '@app/_shared/helpers/ResponseHelper';
import { MessagingService } from '@app/_services/push-notifications/messaging-service';
import firebase from 'firebase/compat/app';
import { StatisticsConTienda } from '@app/_models/statistics/statistics.model';

export interface DashboardStateModel {
    welcomeBanner: any;
    welcomeTasks: any;
    welcomeQuickActions: any;
    welcomeCards: any;
    statistics: {};
    notifications: firebase.messaging.MessagePayload[];
    dashboardThumbnail: string;
    statisticsConTienda: StatisticsConTienda;
}

const initialState: DashboardStateModel = {
    welcomeBanner: [],
    welcomeTasks: [],
    welcomeQuickActions: [],
    welcomeCards: [],
    statistics: null,
    notifications: [],
    dashboardThumbnail: null,
    statisticsConTienda: null,
};

@State<DashboardStateModel>({
    name: 'Dashboard',
    defaults: initialState,
})
@Injectable()
export class DashboardState {
    constructor(
        private dashboardService: DashboardService,
        private responseHelper: ResponseHelper,
        private messagingService: MessagingService
    ) {}

    @Selector()
    public static welcomeBanner(state: DashboardStateModel): any[] {
        return state.welcomeBanner;
    }

    @Selector()
    public static welcomeTasks(state: DashboardStateModel): any[] {
        return state.welcomeTasks;
    }

    @Selector()
    public static welcomeQuickActions(state: DashboardStateModel): any[] {
        return state.welcomeQuickActions;
    }

    @Selector()
    public static welcomeCards(state: DashboardStateModel): any[] {
        return state.welcomeCards;
    }

    @Selector()
    public static getStatistics(state: DashboardStateModel) {
        return state.statistics;
    }

    @Selector()
    public static getNotifications(state: DashboardStateModel) {
        return state.notifications;
    }

    @Selector()
    public static getThumbnail(state: DashboardStateModel) {
        return state.dashboardThumbnail;
    }

    @Selector()
    public static getStatisticsConTienda(state: DashboardStateModel) {
        return state.statisticsConTienda;
    }

    @Action(GetWelcomeData)
    public getWelcomeData({ getState, patchState, dispatch }: StateContext<DashboardStateModel>) {
        return this.dashboardService.getWelcomeData().pipe(
            tap((response: any) => {
                if (response) {
                    const checkResponse = this.responseHelper.checkError(response);
                    if (checkResponse.success) {
                        const dataBanner = response.data.alertSection.items;
                        const dataTasks = response.data.firtsStepsSection.items;
                        const dataQuickActions = response.data.quickActionSection.items;

                        patchState({
                            welcomeBanner: dataBanner,
                            welcomeTasks: dataTasks,
                            welcomeQuickActions: dataQuickActions,
                        });
                    }
                }
            }),
            catchError((error) => of(error))
        );
    }

    @Action(SetWelcomeData)
    public setWelcomeData(
        { getState, patchState, dispatch }: StateContext<DashboardStateModel>,
        { data }: SetWelcomeData
    ) {
        if (Object.entries(data).length > 0) {
            let dataBanner = [];
            let dataTasks = [];
            let dataQuickActions = [];
            let dataCards = [];

            if (data.alertSection.items) {
                dataBanner = data.alertSection.items;
            }
            if (data.firtsStepsSection.items) {
                dataTasks = data.firtsStepsSection.items;
            }
            if (data.quickActionSection.items) {
                dataQuickActions = data.quickActionSection.items;
            }

            patchState({
                welcomeBanner: dataBanner,
                welcomeTasks: dataTasks,
                welcomeQuickActions: dataQuickActions,
            });
        }
    }

    @Action(SetDashboardStatistics)
    public setDashboardStatistics({ getState, patchState, dispatch }: StateContext<DashboardStateModel>) {
        let totalVisits = 0;
        this.dashboardService.getStatistics().subscribe((res) => {
            res.data.active_visits.map((item) => {
                totalVisits = totalVisits + Number(item.visits);
            });

            patchState({
                statistics: {
                    active_visits: totalVisits,
                    unique_visitors: res.data.unique_visitors !== null ? res.data.unique_visitors.value : 0,
                    totalVisitsPages: res.data.viewed_today.nb_pageviews,
                },
            });
        });
    }

    @Action(SetDashboardStatisticsConTienda)
    public setDashboardStatisticsConTienda({ patchState }: StateContext<DashboardStateModel>) {
        this.dashboardService.getStatisticsConTienda().subscribe((res) => {
            patchState({
                statisticsConTienda: {
                    sales: res?.sales ?? 0,
                    nb_visits: res?.visits[0]?.nb_visits ?? 0,
                    registered: res?.registered ?? 0,
                },
            });
        });
    }

    @Action(GetNotifications)
    public GetNotifications({ patchState }: StateContext<DashboardStateModel>) {
        return this.messagingService.getNotifications().pipe(
            tap((response) => {
                patchState({
                    notifications: response?.data,
                });
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    @Action(DeleteNotification)
    public DeleteNotification({ patchState, getState }: StateContext<DashboardStateModel>, { id }: DeleteNotification) {
        const state = getState();
        return this.messagingService.deleteNotification(id).pipe(
            tap((response) => {
                if (response) {
                    patchState({
                        notifications: state.notifications.filter((n) => +n.data.notification_id !== id),
                    });
                }
            }),
            catchError((error) => {
                // this.toast.danger(this.translate.instant('No se pudo eliminar el método'), 'large');
                throw error;
            })
        );
    }

    @Action(ClearAllNotifications)
    public ClearAllNotifications({ patchState }: StateContext<DashboardStateModel>) {
        return this.messagingService.clearAllNotifications().pipe(
            tap((response) => {
                if (response) {
                    patchState({
                        notifications: [],
                    });
                }
            }),
            catchError((error) => {
                // this.toast.danger(this.translate.instant('No se pudo eliminar el método'), 'large');
                throw error;
            })
        );
    }

    @Action(AddNotification)
    public AddNotification(
        { patchState, getState }: StateContext<DashboardStateModel>,
        { notification }: AddNotification
    ) {
        const state = getState();
        const updatedList = state.notifications;
        updatedList.unshift(notification);
        patchState({
            notifications: updatedList,
        });
    }

    @Action(SaveToken)
    public SaveToken({ patchState, getState }: StateContext<DashboardStateModel>, { token }: SaveToken) {
        return this.messagingService.saveToken(token).pipe(
            tap((response) => {
                // console.log('Save Token response >>> ', response);
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    @Action(GetThumbnail)
    public getThumbnail({ patchState }: StateContext<DashboardStateModel>) {
        const reader = new FileReader();
        return this.dashboardService.getThumbnail().subscribe({
            next: (response) => {
                reader.readAsDataURL(response.body);
                reader.onload = () => {
                    const dataUrl: any = reader.result;
                    const base64 = dataUrl.split(',')[1];
                    patchState({
                        dashboardThumbnail: `data:image/jpeg;base64,${base64}`,
                    });
                };
            },
            error: (error) => {
                throw error;
            },
        });
    }
}
