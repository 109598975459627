import { Injectable } from '@angular/core';
import { NavItemInterface } from '@app/_interfaces';
import { State, Action, Selector, StateContext, Select } from '@ngxs/store';
import { SetSettingsNav, SettingsExecuteSubmit } from './settings.actions';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '@app/core/store/app.state';
import { Observable } from 'rxjs';

export interface SettingsStateModel {
    nav: NavItemInterface[];
    settingsExecuteSubmit: boolean;
}

@State<SettingsStateModel>({
    name: 'settings',
    defaults: {
        nav: [],
        settingsExecuteSubmit: false,
    },
})
@Injectable()
export class SettingsState {
    @Select(AppState.getMarketLanguageValue)
    initialLanguage: Observable<string>;

    constructor(private translate: TranslateService) {}

    @Selector()
    public static getState(state: SettingsStateModel) {
        return state;
    }

    @Selector()
    public static nav(state: SettingsStateModel) {
        return state.nav;
    }

    @Selector()
    public static settingsExecuteSubmit(state: SettingsStateModel) {
        return state.settingsExecuteSubmit;
    }

    @Action(SetSettingsNav)
    public setNav({ patchState }: StateContext<SetSettingsNav>) {
        return patchState({
            nav: [
                {
                    label: this.translate.instant('app.nav.page_languages'),
                    link: 'page-languages',
                    icon: 'id-card',
                    show: true,
                },
                {
                    label: this.translate.instant('app.nav.market_reset'),
                    link: 'restart-wizard',
                    icon: 'align-left',
                    show: true,
                },
            ],
        });
    }

    @Action(SettingsExecuteSubmit)
    public settingsExecuteSubmit(
        { patchState, getState }: StateContext<SettingsStateModel>,
        { execute }: SettingsExecuteSubmit
    ) {
        patchState({
            settingsExecuteSubmit: execute,
        });
    }
}
