import { Pipe, PipeTransform } from '@angular/core';
/*
 * Show/Hide password
 * Usage:
 *   text | togglePassword:boolean
 */
@Pipe({ name: 'togglePassword' })
export class TogglePasswordPipe implements PipeTransform {
    transform(value: any, hide: boolean): any {
        let result = value;
        let resultArr = [];

        if (hide && result) {
            for (let i = 0; i < result.length; i++) {
                resultArr.push('&#9679;');
            }
            result = resultArr.join('');
        }

        return result;
    }
}
