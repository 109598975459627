import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-ss-alert',
    templateUrl: './ss-alert.component.html',
    styleUrls: ['./ss-alert.component.scss'],
})
export class SsAlertComponent {
    constructor(public dialogo: MatDialogRef<SsAlertComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

    close(): void {
        this.dialogo.close(false);
    }
    confirm(): void {
        this.dialogo.close(true);
    }
}
