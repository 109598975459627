import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DesignService } from './../../../../../_services/design/design.service';
import { ResponseHelper } from './../../../../../_shared/helpers/ResponseHelper';
import { getSiteHeaderInfoAction, setSiteHeaderInfoAction } from './header-customization.actions';
import { SetLoadingFormSubmitAction } from '@app/pages/web-site/design/store/design.actions';
import { HeaderCustomization } from '@app/_models/pages/design/headerCustomization.model';

export interface HeaderCustomizationStateModel {
    siteHeaderInfo: any;
}

@State<HeaderCustomizationStateModel>({
    name: 'headerCustomization',
    defaults: {
        siteHeaderInfo: {} as HeaderCustomization,
    },
})
@Injectable()
export class HeaderCustomizationState {
    constructor(private designService: DesignService, private responseHelper: ResponseHelper, private store: Store) {}

    @Selector()
    public static getState(state: HeaderCustomizationStateModel) {
        return state;
    }

    @Selector()
    public static getSiteHeaderInfo(state: HeaderCustomizationStateModel) {
        return state.siteHeaderInfo;
    }

    @Action(getSiteHeaderInfoAction)
    public getSiteHeaderInfoAction({ getState, patchState, dispatch }: StateContext<HeaderCustomizationStateModel>) {
        return this.designService.getSiteHeaderInfo().pipe(
            tap((res: any) => {
                if (res) {
                    const checkResponse = this.responseHelper.checkError(res);
                    if (checkResponse.success) {
                        const SiteStructure = res.data;
                        patchState({ siteHeaderInfo: SiteStructure });
                    }
                }
            }),
            catchError((error) => of(error))
        );
    }

    @Action(setSiteHeaderInfoAction)
    public setSiteHeaderInfoAction(
        { getState, patchState }: StateContext<HeaderCustomizationStateModel>,
        { data }: setSiteHeaderInfoAction
    ) {
        this.store.dispatch(new SetLoadingFormSubmitAction(true));
        return this.designService.setSiteHeaderInfo(data).pipe(
            tap(
                (res: any) => {
                    if (res) {
                        const checkResponse = this.responseHelper.checkError(res);
                        if (checkResponse.success) {
                            const SiteStructure = res.data[0];
                            patchState({ siteHeaderInfo: SiteStructure });
                            this.store.dispatch(new SetLoadingFormSubmitAction(false));
                        }
                    }
                },
                (err) => of(err)
            )
        );
    }
}
