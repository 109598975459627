import { Injectable } from '@angular/core';
import { ConfigurationService } from '@app/_services/configuration/configuration.service';
import { ResponseHelper } from '@app/_shared/helpers/ResponseHelper';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
    AddPageLanguageAction,
    DeletePageLanguageAction,
    GetAvailablePageLanguagesAction,
    setDefaultLanguageAction,
    setLanguageVisibility,
} from './page-languages.actions';

export interface PageLanguagesStateModel {
    languages: any;
    availableLanguages: any;
}

@State<PageLanguagesStateModel>({
    name: 'pageLanguages',
    defaults: {
        availableLanguages: [],
        languages: [],
    },
})
@Injectable()
export class PageLanguagesState {
    constructor(private configurationService: ConfigurationService, private responseHelper: ResponseHelper) {}

    @Selector()
    public static getState(state: PageLanguagesStateModel) {
        return state;
    }

    @Selector()
    public static availableLanguages(state: PageLanguagesStateModel) {
        return state.availableLanguages;
    }

    @Selector()
    public static languages(state: PageLanguagesStateModel) {
        return state.languages;
    }

    @Action(GetAvailablePageLanguagesAction)
    public getAllLanguages({ getState, patchState, dispatch }: StateContext<PageLanguagesStateModel>) {
        return this.configurationService.getAvailableLanguages().pipe(
            tap((res: any) => {
                if (res) {
                    const checkResponse = this.responseHelper.checkError(res);
                    if (checkResponse.success) {
                        const availableLanguages = res.data.langs;
                        patchState({ availableLanguages: availableLanguages });
                    }
                }
            }),
            catchError((error) => of(error))
        );
    }

    @Action(AddPageLanguageAction)
    public AddPageLanguageAction(
        { getState, patchState }: StateContext<PageLanguagesStateModel>,
        { id }: AddPageLanguageAction
    ) {
        return this.configurationService.addPageLanguage(id).pipe(
            tap(
                (res: any) => {
                    if (res) {
                        const checkResponse = this.responseHelper.checkError(res);
                        if (checkResponse.success) {
                            const state = getState();
                            patchState({
                                languages: [...state.languages, res.data],
                            });
                        }
                    }
                },
                (err) => of(err)
            )
        );
    }

    @Action(DeletePageLanguageAction)
    public DeletePageLanguageAction(
        { getState, setState }: StateContext<PageLanguagesStateModel>,
        { id }: DeletePageLanguageAction
    ) {
        return this.configurationService.deletePageLanguage(id).pipe(
            tap(
                (res: any) => {
                    if (res) {
                        const checkResponse = this.responseHelper.checkError(res);
                        if (checkResponse.success) {
                            const state = getState();
                            const filteredPages = state.languages.filter((item) => item.id !== id);
                            setState({
                                ...state,
                                languages: filteredPages,
                            });
                        }
                    }
                },
                (err) => of(err)
            )
        );
    }

    @Action(setLanguageVisibility)
    public setLanguageVisibility(
        { getState, patchState, setState }: StateContext<PageLanguagesStateModel>,
        { id, visible }: setLanguageVisibility
    ) {
        return this.configurationService.setLanguageVisibility(id, visible);
    }

    @Action(setDefaultLanguageAction)
    public setDefaultLanguageAction(
        { getState, patchState }: StateContext<PageLanguagesStateModel>,
        { id }: setDefaultLanguageAction
    ) {
        return this.configurationService.setDefaultLanguage(id);
    }
}
