<label for="metaKeywords" class="mb-0 mb-1">{{ label }}</label>
<i
    class="fal fa-info-circle ss-tooltip"
    *ngIf="tooltipText"
    [ngbTooltip]="tooltipText"
    [tooltipClass]="tooltipCustomClass"
    placement="right"
    triggers="click"
    [autoClose]="'outside'"
></i>
<div
    class="example-chip-list"
    appearance="fill"
    [ngClass]="
        (pills?.length >= maxPills && chipCtrl.value?.length >= 1) || chipCtrl.value?.length >= 35
            ? 'chips-container error'
            : 'chips-container'
    "
>
    <mat-chip-list #chipList>
        <mat-chip
            *ngFor="let pill of pills; let i = index"
            [removable]="removable"
            (removed)="remove(pill)"
            (dblclick)="editPill(i)"
        >
            <span *ngIf="colorType" matChipAvatar [ngStyle]="{ 'background-color': pill.value ?? '#808080' }"></span>
            {{ pill.name }}
            <button *ngIf="!externalValidationbeforeRemove" matChipRemove class="removeChipBtn">
                <i matChipRemove class="fas fa-times"></i>
            </button>
            <button *ngIf="externalValidationbeforeRemove" (click)="validateRemoveChip(i)" class="removeChipBtn">
                <i class="fas fa-times" *ngIf="!removePillsList[i]"></i>
                <mc-loading *ngIf="removePillsList[i]" size="sm"></mc-loading>
            </button>
        </mat-chip>
        <input
            ngDefaultControl
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [formControl]="chipCtrl"
            [placeholder]="inputLabel ?? 'app.global.modals.seo.escriba_sus_palabras_clave_aqui' | translate"
            placeholder="{{ placeholder }}"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)"
            name="metaKeywords"
            form="addPageForm"
        />
        <i
            class="fal fa-times-circle icon-error"
            *ngIf="(pills?.length >= maxPills && chipCtrl.value?.length >= 1) || chipCtrl.value?.length >= 35"
        ></i>
    </mat-chip-list>
</div>
<div class="mt-2">
    <span class="error-chip" *ngIf="pills?.length >= maxPills && chipCtrl.value?.length >= 1"
        >Sólo se permiten {{ maxPills }} palabras clave</span
    >
    <span class="error-chip" *ngIf="chipCtrl.value?.length >= 35"
        >La palabra clave debe contener menos de 35 caracteres</span
    >
</div>
