import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import packageJson from '../../../package.json';
import { AppState } from '@app/core/store/app.state';
import { ShowAppVersionBanner } from '@app/core/store/app.actions';
import { environment } from '@env/environment.local';

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {
    @Select(AppState.showAppVersionBanner)
    showAppVersionBanner$: Observable<boolean>;

    constructor(private store: Store) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse && event.status === 200) {
                    const backendVersion = event.body?.front_version ? event.body.front_version : null;
                    const currentVersion = packageJson.version;
                    const isProduction = environment.checkAppVersion;
                    if (backendVersion != null && backendVersion != currentVersion && isProduction)
                        this.store.dispatch(new ShowAppVersionBanner(true));
                }
            })
        );
    }
}
