import { Injectable, NgZone } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { McToastService } from '../_services';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { SetInternalServerError } from '@app/core/store/app.actions';
import { MatDialog } from '@angular/material/dialog';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    qtyOfExecutions: number = 0;

    constructor(
        private mcToast: McToastService,
        private store: Store,
        private zone: NgZone,
        private router: Router,
        public dialog: MatDialog
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {
                const errorResponse = err.error;
                const dialogsOnScreen = document.querySelectorAll('.mat-dialog-container');
                if (
                    dialogsOnScreen.length == 0 &&
                    this.qtyOfExecutions == 0 &&
                    typeof errorResponse.message === 'string'
                ) {
                    if (err.status === 500 || err.status === 422) {
                        this.store.dispatch(new SetInternalServerError(true));
                        this.zone.run(() => this.router.navigate(['admin/500']));
                    }
                }

                return throwError(err);
            }),

            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse && event.status === 200) {
                    if (
                        event.body.data &&
                        event.body.data.success == false &&
                        document.querySelectorAll('.mc-toast__wrapper').length == 0
                    ) {
                        this.mcToast.warning(event.body.data.message);
                    }
                }
            })
        );
    }
}
