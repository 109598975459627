import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Paginator } from '@app/_interfaces/paginator.interface';
import { PageEvent } from '@angular/material/paginator';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
    @Input() paginator: Paginator;
    @Output() pageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

    constructor() {}
}
