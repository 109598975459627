import { Injectable } from '@angular/core';
import { ApiService } from '@app/_services/api.service';
import { CommomHeaders } from '@app/_shared/httpHeaders/CommonHeaders';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class WebsiteService {
    constructor(private api: ApiService, private headers: CommomHeaders) {}

    addSiteToMatomo(): Observable<any> {
        return this.api.post(`/api/v1/back/analytics/add-site`, this.headers.getAuthHeaders());
    }

    getVisitsSummary(when): Observable<any> {
        return this.api.getFz(
            `/api/v1/back/analytics/get-metrics/visits-summary${when}`,
            this.headers.getAuthHeaders()
        );
    }

    getSales(when): Observable<any> {
        return this.api.getFz(`/api/v1/back/analytics/get-metrics/sales${when}`, this.headers.getAuthHeaders());
    }

    getVisitsSummaryByMonth(when): Observable<any> {
        return this.api.getFz(
            `/api/v1/back/analytics/get-metrics/visits-summary/month/${when}`,
            this.headers.getAuthHeaders()
        );
    }

    getVisitsByCountry(when): Observable<any> {
        return this.api.getFz(`/api/v1/back/analytics/get-metrics/user-country${when}`, this.headers.getAuthHeaders());
    }

    getVisitsByEntryPage(when): Observable<any> {
        return this.api.getFz(`/api/v1/back/analytics/get-metrics/entry-page${when}`, this.headers.getAuthHeaders());
    }

    getVisitsByDevices(when): Observable<any> {
        return this.api.getFz(
            `/api/v1/back/analytics/get-metrics/device-detection${when}`,
            this.headers.getAuthHeaders()
        );
    }

    getVisitsByOrigins(when): Observable<any> {
        return this.api.getFz(`/api/v1/back/analytics/get-metrics/referrers${when}`, this.headers.getAuthHeaders());
    }

    getVisitsByBrowser(when): Observable<any> {
        return this.api.getFz(`/api/v1/back/analytics/get-metrics/browsers${when}`, this.headers.getAuthHeaders());
    }
}
