<div>
    <div class="col-12 mt-2 p-0">
        <p class="design-modal-title">{{ params.title }}</p>
    </div>
    <div class="mt-3" *ngIf="params.actionDescription">
        <p class="description">{{ params.actionDescription }}</p>
    </div>
    <div class="d-flex justify-content-between justify-content-sm-end mt-3 mt-lg-4">
        <button *ngIf="showCancel" class="btn btn-skip mr-0 mr-sm-4" tabindex="0" (click)="closeDialog()">
            {{ 'app.domains.cancelar' | translate }}
        </button>
        <button class="btn btn-continue" type="submit" tabindex="0" (click)="sendData(true)">
            {{ params.btnText }}
        </button>
    </div>
</div>
