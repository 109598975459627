import { CdkStep } from '@angular/cdk/stepper';
import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { GetGeneralInfoApp } from '@app/core/store/app.actions';
import { AppState } from '@app/core/store/app.state';
import { AddSiteToMatomo } from '@app/pages/statistics/website/store/statistics.actions';
import {
    SetCountryCodePhoneNumber,
    SetCountryCodeWhatSapp,
} from '@app/pages/web-site/contact-data/store/contact-data.actions';
import { ContactDataState } from '@app/pages/web-site/contact-data/store/contact-data.state';
import { CountryCode } from '@app/_models/countries/countryCode.model';
import { FormDataStorage } from '@app/_interfaces/contact_data';
import { SocialRed } from '@app/_models/social-media/social-media.model';
import { McToastService } from '@app/_services';
import { SocialMediaHelper } from '@app/_shared/helpers/socialMediaHelper';
import { StepperHelper } from '@app/_shared/helpers/StepperHelper';
import { utils } from '@app/_utils';
import * as CustomValidators from '@app/_validators';
import { Select, Store } from '@ngxs/store';
import { Observable, debounceTime, tap } from 'rxjs';
import { countryCodes } from '../../../../_shared/data/country-codes';
import {
    InitializeWizard,
    SetIsLoading,
    SetOpenModalStepper,
    SetWizardStatus,
    WizardSetContactData,
    WizardSetWspData,
} from '../../store/stepper.actions';
import { StepperState } from '../../store/stepper.state';

@Component({
    selector: 'social-media',
    templateUrl: './social-media.component.html',
    styleUrls: ['./social-media.component.css'],
})
export class SocialMediaComponent implements OnInit, OnDestroy {
    @ViewChildren('inputElement') inputElements: QueryList<ElementRef>;

    contactDataForm: UntypedFormGroup;
    submitted: boolean;
    countries = countryCodes;
    selectedValue: string;
    formDataStorage: FormDataStorage;
    countryCodePhone: CountryCode;
    countryCodeWp: CountryCode;
    defaultCountryFlag: CountryCode = {
        id: 'argentina',
        name: 'AR',
        alpha2Code: 'AR',
        alpha3Code: 'ARG',
        numericCode: '54',
        image_url: 'argentina.jpg',
    };
    location: string = 'Argentina';
    direction: string = `https://maps.google.com/maps?q=${location}&amp;&output=embed`;

    @Input() stepName: string;

    @Select(ContactDataState.countryCodePhoneNumber)
    countryCodePhoneNumber$: Observable<string>;
    @Select(ContactDataState.countryCodeWp)
    countryCodeWp$: Observable<string>;
    @Select(StepperState.isLoading)
    isLoading$: Observable<boolean>;

    constructor(
        private fb: UntypedFormBuilder,
        private store: Store,
        private stepperHelper: StepperHelper,
        private cdkStep: CdkStep,
        private mcToast: McToastService,
        private socialMediaHelper: SocialMediaHelper
    ) {}

    ngOnInit(): void {
        this.stepperHelper.setCurrentStepName(this.stepName);
        this.hasDataLoaded();
        this.buildForm();
        this.stepperHelper.rememberStatus(this.stepName, this.cdkStep);
        this.changeAddress();
    }

    ngOnDestroy(): void {
        this.phoneNumberToStorage();
        this.stepperHelper.rememberFormData(this.stepName, this.contactDataForm?.value);
        this.stepperHelper.updateStatusStep(this.stepName, this.cdkStep);
    }

    phoneNumberToStorage(): void {
        let whatsappValue;
        let phoneNumberValue;
        if (this.contactDataForm?.value.whatsapp)
            whatsappValue = `+${this.countryCodeWp?.numericCode} ${this.contactDataForm?.value.whatsapp}`;
        if (this.contactDataForm?.value.phone)
            phoneNumberValue = `+${this.countryCodePhone?.numericCode} ${this.contactDataForm?.value.phone}`;
        this.contactDataForm?.controls['whatsapp'].setValue(whatsappValue);
        this.contactDataForm?.controls['phone'].setValue(phoneNumberValue);
    }

    buildForm(): void {
        this.contactDataForm = this.fb.group({
            adress: [this.formDataStorage ? this.formDataStorage.formValue.adress : '', Validators.maxLength(200)],
            phone: [
                this.formDataStorage ? this.formDataStorage.formValue.phone?.trim().split(' ')[1] : '',
                [CustomValidators.phoneNumber(), Validators.minLength(6)],
            ],
            whatsapp: [
                this.formDataStorage ? this.formDataStorage.formValue.whatsapp?.trim().split(' ')[1] : '',
                [CustomValidators.wp(), Validators.minLength(6)],
            ],
            email: [
                this.formDataStorage ? this.formDataStorage.formValue.email : '',
                [Validators.required, Validators.minLength(1), CustomValidators.email()],
            ],
            socialMedia: this.fb.array(
                this.createSocialControl(this.formDataStorage ? this.formDataStorage.formValue.socialMedia : '')
            ),
        });

        this.contactDataForm
            .get('adress')
            .valueChanges.pipe(
                tap(() => {}),
                debounceTime(300)
            )
            .subscribe((value) => {
                this.changeAddress(value);
            });
    }

    hasDataLoaded(): void {
        this.formDataStorage = this.stepperHelper.getFormData(this.stepName);
        if (this.formDataStorage?.formValue.phone) {
            const number = this.formDataStorage.formValue.phone;
            const country = utils.getCountryFlag(number);
            this.selectCountryCodePhone(country);
        } else {
            this.selectCountryCodePhone();
        }
        if (this.formDataStorage?.formValue.whatsapp) {
            const number = this.formDataStorage.formValue.whatsapp;
            const country = utils.getCountryFlag(number);
            this.selectCountryCodeWp(country);
        } else {
            this.selectCountryCodeWp();
        }
    }

    createSocialControl(data): Array<UntypedFormControl> {
        let controls = [];
        if (data && data.length >= 1 && data[0] != null) {
            data.forEach((element) => {
                if (element.url) {
                    controls.push(
                        new UntypedFormControl(element.url, CustomValidators.isSocialMedia(this.socialMediaHelper))
                    );
                } else {
                    controls.push(
                        new UntypedFormControl(element, CustomValidators.isSocialMedia(this.socialMediaHelper))
                    );
                }
            });
        } else {
            controls.push(new UntypedFormControl('', CustomValidators.isSocialMedia(this.socialMediaHelper)));
        }
        return controls;
    }

    get getSocialMedia() {
        return this.contactDataForm.get('socialMedia') as UntypedFormArray;
    }

    addSocialNetwork(): void {
        this.inputElements.changes.subscribe(() => {
            this.inputElements.last.nativeElement.focus();
        });
        let addSuccess: boolean = false;
        this.getSocialMedia.value.forEach((value) => {
            !value ? (addSuccess = false) : (addSuccess = true);
        });
        if (addSuccess && this.getSocialMedia.status == 'VALID') {
            if (this.getSocialMedia.controls.length < 8) {
                this.getSocialMedia.push(
                    this.fb.control('', [CustomValidators.isSocialMedia(this.socialMediaHelper), Validators.required])
                );
            } else {
                this.mcToast.warning('No se puede agregar más de ocho redes sociales');
            }
        }
    }

    deleteSocialNetwork(index): void {
        if (this.getSocialMedia.controls.length > 1) {
            const control = <UntypedFormArray>this.contactDataForm.controls['socialMedia'];
            control.removeAt(index);
            this.setValidators();
        }
    }

    selectCountryCodePhone(event?): void {
        const alpha2CodeBO = this.store.selectSnapshot(AppState.getConfig)?.isoPais;
        const country = utils.getCountryFlag(alpha2CodeBO);
        let countryCode;
        countryCode = this.defaultCountryFlag;
        if (alpha2CodeBO) countryCode = country;
        if (event) countryCode = event;
        this.countryCodePhone = countryCode;
        this.store.dispatch(new SetCountryCodePhoneNumber(countryCode));
    }

    selectCountryCodeWp(event?): void {
        const alpha2CodeBO = this.store.selectSnapshot(AppState.getConfig)?.isoPais;
        const country = utils.getCountryFlag(alpha2CodeBO);
        let countryCode;
        countryCode = this.defaultCountryFlag;
        if (alpha2CodeBO) countryCode = country;
        if (event) countryCode = event;
        this.countryCodeWp = countryCode;
        this.store.dispatch(new SetCountryCodeWhatSapp(countryCode));
    }

    dataToSend(formValue) {
        const socialDataValue: SocialRed[] = [];
        let whatsappValue;
        let phoneNumberValue;
        formValue.socialMedia.forEach((element) => {
            const socialRed = this.socialMediaHelper.socialExist(element);
            if (socialRed) {
                socialDataValue.push({ type: socialRed.type, url: element });
            }
        });
        if (formValue.whatsapp) {
            if (typeof this.countryCodeWp?.numericCode !== 'undefined') {
                whatsappValue = `+${this.countryCodeWp?.numericCode} ${formValue.whatsapp}`;
            } else {
                whatsappValue = `+54 ${formValue.whatsapp}`;
            }
        }
        if (formValue.phone) {
            if (typeof this.countryCodePhone?.numericCode !== 'undefined') {
                phoneNumberValue = `+${this.countryCodePhone?.numericCode} ${formValue.phone}`;
            } else {
                phoneNumberValue = `+54 ${formValue.phone}`;
            }
        }
        return {
            address: formValue.adress,
            phone: phoneNumberValue,
            whatsapp: whatsappValue,
            contactEmail: formValue.email,
            showEmail: true,
            socialMedia: socialDataValue,
            botonWhatsapp: {
                mostrar_boton_whatsapp_flotante: whatsappValue !== undefined,
                alinear_izquierda_boton_whatsapp: false,
                whatsapp_compartir_url_producto: false,
                whatsapp_texto_predeterminado: '',
            },
        };
    }

    onFormSubmit(): void {
        const formValue = this.contactDataForm.value;
        this.stepperHelper.sendStepperStatus();
        const contactDataValue = this.dataToSend(formValue);
        this.stepperHelper.updateDataStep(this.stepName, contactDataValue);
        this.submitted = true;
        if (this.contactDataForm.valid) {
            this.store
                .dispatch([
                    new SetIsLoading(true),
                    new WizardSetContactData(contactDataValue),
                    new WizardSetWspData(contactDataValue.botonWhatsapp),
                ])
                .subscribe({
                    next: () => {
                        this.finishInitialWizard();
                    },
                });
        }
    }

    finishInitialWizard(): void {
        this.store.dispatch(new SetWizardStatus(true)).subscribe({
            next: () => {
                this.store.dispatch(new GetGeneralInfoApp());
                this.store.dispatch(new AddSiteToMatomo());
                setTimeout(() => {
                    this.store.dispatch(new InitializeWizard(false));
                    this.store.dispatch(new SetIsLoading(false));
                    this.store.dispatch(new SetOpenModalStepper(true));
                    this.stepperHelper.deleteStatusWizard();
                }, 5000);
            },
        });
    }

    setValidators(): void {
        if (this.getSocialMedia.controls.length == 1) {
            this.getSocialMedia.controls[0].clearValidators();
            this.getSocialMedia.controls[0].setValidators([CustomValidators.isSocialMedia(this.socialMediaHelper)]);
            this.getSocialMedia.controls[0].updateValueAndValidity();
        }
    }

    changeAddress(value?: string): void {
        const alpha2CodeBO = this.store.selectSnapshot(AppState.getConfig)?.isoPais;
        const countryName = utils.getCountryFlag(alpha2CodeBO)?.id;
        let direction = this.location;
        direction = alpha2CodeBO && alpha2CodeBO.toLowerCase() !== 'to' ? countryName : direction;
        direction = this.formDataStorage?.formValue?.adress?.length
            ? this.formDataStorage?.formValue?.adress
            : direction;
        direction = value ?? direction;
        this.direction = `https://maps.google.com/maps?q=${direction}&amp;&output=embed`;
    }
}
