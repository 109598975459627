import { Injectable } from '@angular/core';
import { Language } from '@app/_models/languages/language';
import { Languages } from '@app/_shared/data/languages';
import { DefaultLang } from '@app/_shared/data/defaultLang';

@Injectable({
    providedIn: 'root',
})
export class LanguageHelper {
    constructor() {}

    getLanguageById(id: number): Language {
        let selectedLang: Language;
        Languages.forEach((lang) => {
            if (lang.id == id) {
                selectedLang = lang;
            }
        });
        if (selectedLang === undefined || selectedLang.id === null) {
            selectedLang = DefaultLang;
        }
        return selectedLang;
    }

    getLanguageByValue(value: string): Language {
        let selectedLang: Language;
        Languages.forEach((lang) => {
            if (lang.value == value) {
                selectedLang = lang;
            }
        });
        if (selectedLang === undefined || selectedLang.id === null) {
            selectedLang = DefaultLang;
        }
        return selectedLang;
    }

    setLanguage(value: string = 'es'): void {
        localStorage.setItem('lang', value);
    }

    getLanguage(): string {
        let lang: string = localStorage.getItem('lang');
        if (lang === undefined || lang === null) {
            lang = 'es';
        }
        return lang;
    }

    clearLanguage(): void {
        localStorage.removeItem('lang');
    }
}
