import { Component, Input } from '@angular/core';
import { NavItemInterface } from '@app/_interfaces';
import { NavigationStart, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
    selector: 'mc-section-contextual-menu',
    templateUrl: './mc-section-contextual-menu.component.html',
    styleUrls: ['./mc-section-contextual-menu.component.css'],
})
export class McSectionContextualMenuComponent {
    @Input() info: string;
    @Input() nav: NavItemInterface[] = [];

    open: boolean;

    constructor(private router: Router) {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationStart),
                debounceTime(100),
                distinctUntilChanged()
            )
            .subscribe(() => (this.open = false));
    }

    toggleOpen() {
        this.open = !this.open;
    }
}
