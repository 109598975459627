import { AbstractControl, ValidatorFn } from '@angular/forms';

function validateLenght(cbu: any) {
    if (!cbu) {
        return false;
    }
    return cbu.length === 22;
}

function validateBankCode(code) {
    if (code.length !== 8) {
        return false;
    }
    const bank = code.substr(0, 3);
    const verificatorDigit1 = code[3];
    const branch = code.substr(4, 3);
    const verificatorDigit2 = parseInt(code[7]);

    const sum =
        bank[0] * 7 + bank[1] * 1 + bank[2] * 3 + verificatorDigit1 * 9 + branch[0] * 7 + branch[1] * 1 + branch[2] * 3;

    const difference = (10 - (sum % 10)) % 10;

    return difference === verificatorDigit2;
}

function validateAccount(account) {
    if (account.length !== 14) {
        return false;
    }
    const verificatorDigit = parseInt(account[13]);
    const sum =
        account[0] * 3 +
        account[1] * 9 +
        account[2] * 7 +
        account[3] * 1 +
        account[4] * 3 +
        account[5] * 9 +
        account[6] * 7 +
        account[7] * 1 +
        account[8] * 3 +
        account[9] * 9 +
        account[10] * 7 +
        account[11] * 1 +
        account[12] * 3;
    const difference = (10 - (sum % 10)) % 10;

    return difference === verificatorDigit;
}

function validateCBU(cbu) {
    return validateLenght(cbu) && validateBankCode(cbu.substr(0, 8)) && validateAccount(cbu.substr(8, 14));
}

export function cbu(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
        (control.value && !validateCBU(control.value) && { cbu: true }) || null;
}
