import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import { Component, Input, OnDestroy, OnInit, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { AppState } from '@app/core/store/app.state';
import { Paginator } from '@app/_interfaces/paginator.interface';
import { Template } from '@app/_models/templates/template.model';
import { McToastService } from '@app/_services';
import { StepperHelper } from '@app/_shared/helpers/StepperHelper';
import { environment } from '@env/environment.local';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    GetTemplates,
    SetTemplate,
    SetButtonStatus,
    GetAllTemplates,
    ResetTemplatePaginator,
} from '../../store/stepper.actions';
import { StepperState } from '../../store/stepper.state';

@Component({
    selector: 'select-template',
    templateUrl: './select-template.component.html',
    styleUrls: ['./select-template.component.css'],
})
export class SelectTemplateComponent implements OnInit, OnDestroy {
    loading: boolean;
    selectedTemplate: Template;
    templateData: any;
    zoomPath: string;
    uri: string = environment.assets_url;
    mfsPath: string = environment.mfs_path;
    previousVerticalId: number = 0;
    ngDestroyed$ = new Subject<void>();
    button: boolean;
    paginator: Paginator;
    previousPage: number = 0;
    moreTemplatesBtn: boolean = true;
    @Input() stepName: string;
    @ViewChild('templateModal') templateModal: any;
    @ViewChild('templateList') templateList: ElementRef;

    @Select(StepperState.templateList)
    templateList$: Observable<any>;

    @Select(StepperState.templateSelected)
    templateSelected$: Observable<any>;

    @Select(StepperState.button)
    button$: Observable<boolean>;

    @Select(AppState.getVerticalType)
    verticalType$: Observable<number>;

    @Select(StepperState.getPaginator)
    templatesPaginator$: Observable<Paginator>;

    @Select(StepperState.showOthersTemplates)
    showOthersTemplates$: Observable<boolean>;

    @Select(StepperState.othersTemplateList)
    othersTemplateList$: Observable<Template[]>;

    @Select(StepperState.finishTemplatesPaginator)
    finishTemplatesPaginator$: Observable<boolean>;

    templateListQuantity: number;
    othersTemplateListQuantity: number;

    constructor(
        public translate: TranslateService,
        private store: Store,
        private stepperHelper: StepperHelper,
        private modalService: NgbModal,
        private cdkStep: CdkStep,
        private cdkStepper: CdkStepper,
        private mcToast: McToastService
    ) {
        this.templateList$.subscribe({
            next: (data) => {
                this.templateListQuantity = data.length;
            },
        });

        this.othersTemplateList$.subscribe({
            next: (data) => {
                this.othersTemplateListQuantity = data.length;
            },
        });
    }

    ngOnInit() {
        this.hasDataLoaded();
        this.getTemplates();
        this.setPaginator();
        this.selectedTemplateListener();
        this.stepperHelper.setCurrentStepName(this.stepName);
        const data = { idTemplate: this.selectedTemplate };
        this.stepperHelper.rememberStatus(this.stepName, this.cdkStep, data);
        // this.step2ScrollPosition = JSON.parse(localStorage.getItem('step2ScrollPosition'));

        this.button$.subscribe((button: boolean) => {
            this.button = button;
        });
    }

    // ngAfterViewChecked() {
    // this.templateList.nativeElement.scrollTop = this.step2ScrollPosition;
    // }

    ngOnDestroy() {
        this.stepperHelper.updateStatusStep(this.stepName, this.cdkStep);
        this.ngDestroyed$.next();
    }

    selectedTemplateListener(): void {
        this.templateSelected$.subscribe((template: Template) => {
            this.selectedTemplate = template;
        });
    }

    getTemplates(): void {
        this.loading = true;
        this.verticalType$.pipe(takeUntil(this.ngDestroyed$)).subscribe((verticalId: number) => {
            if (this.previousVerticalId != verticalId) {
                this.previousVerticalId = verticalId;
                this.store.dispatch(new GetTemplates(verticalId)).subscribe(() => (this.loading = false));
            } else this.loading = false;
        });
    }

    selectTemplate(template: Template) {
        this.selectedTemplate = template;
        this.templateData = { idTemplate: template.id };
        // this.step2ScrollPosition = this.templateList.nativeElement.scrollTop;
        // localStorage.setItem('step2ScrollPosition', JSON.stringify(this.step2ScrollPosition));
        this.store.dispatch(new SetButtonStatus(false));
    }

    openTemplateModal(modal): void {
        this.modalService.open(modal, {
            windowClass: 'd-flex align-items-center justify-content-center template-wizard',
            backdrop: 'static',
            animation: true,
        });
    }

    showPreviewTemplate(zoomPath: string): void {
        this.zoomPath = zoomPath;
        this.openTemplateModal(this.templateModal);
    }

    hasDataLoaded(): void {
        const showBtn = localStorage.getItem('showMoreTemplatesBtn');
        showBtn === 'false' ? (this.moreTemplatesBtn = false) : (this.moreTemplatesBtn = true);
        this.stepperHelper.getDataStep(this.stepName)
            ? (this.templateData = this.stepperHelper.getDataStep(this.stepName))
            : (this.templateData = null);
    }

    sendData() {
        this.loading = true;
        this.store.dispatch(new SetTemplate(this.selectedTemplate)).subscribe({
            next: () => {
                this.stepperHelper.updateDataStep(this.stepName, this.templateData);
                this.stepperHelper.sendStepperStatus();
                this.loading = false;
                this.cdkStepper.next();
            },
            error: () => {
                this.loading = false;
                this.mcToast.danger(this.translate.instant('app.global.toast.operacion_erronea'));
            },
        });
    }
    showMoreTemplates(): void {
        if (this.paginator.currentPage === 0) {
            this.store.dispatch(new GetAllTemplates(1));
        } else {
            if (this.paginator.currentPage === this.paginator.lastPage - 1) {
                this.moreTemplatesBtn = false;
                localStorage.setItem('showMoreTemplatesBtn', 'false');
            }
            const pageNumber: number = (this.paginator.currentPage += 1);
            if (pageNumber <= this.paginator.lastPage && pageNumber != this.previousPage) {
                this.previousPage = pageNumber;
                this.store.dispatch(new GetAllTemplates(pageNumber));
            }
        }
    }

    setPaginator(): void {
        this.templatesPaginator$.subscribe((value) => {
            this.paginator = value;
        });
    }

    goBack(): void {
        this.cdkStepper.previous();
        this.store.dispatch(new ResetTemplatePaginator());
    }

    getClass(): string {
        let _class = '';
        if (this.templateListQuantity === 1 && this.othersTemplateListQuantity === 0) {
            _class = 'col-12';
        }

        if (this.templateListQuantity > 1 || this.othersTemplateListQuantity > 0) {
            _class = 'col-6 col-md-4';
        }
        return _class;
    }
}
