import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
    transform(url) {
        if (!url) {
            return '';
        }
        if (url) {
            if (url.includes('youtube.com') || url.includes('youtu.be')) {
                const id = this.getYouTubeVideoIdFromUrl(url);
                url = `https://www.youtube.com/embed/${id}?controls=0`;
            }
            if (url.includes('//vimeo.com/')) {
                url = url.replace('//vimeo.com/', '//player.vimeo.com/video/');
            }
        }
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    getYouTubeVideoIdFromUrl = (url: string) => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = url.match(regExp);
        return match && match[7].length === 11 ? match[7] : undefined;
    };
}
