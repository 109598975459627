import { ResetPassword } from '@app/_interfaces';

export class SetNav {
    public static readonly type = '[Profile] SetNav';
}
export class OAuthAssociate {
    public static readonly type = '[Profile] OAuthAssociate';
}

export class ExecuteProfileSubmit {
    public static readonly type = '[Profile] ExecuteProfileSubmit';
    constructor(public execute: boolean) {}
}

export class ChangePassword {
    public static readonly type = '[Profile] ChangePassword';
    constructor(public data: ResetPassword) {}
}

export class ChangeUserName {
    public static readonly type = '[Profile] ChangeUserName';
    constructor(public userName: string) {}
}
