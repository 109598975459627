import { TranslateService } from '@ngx-translate/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';
import {
    SetCurrentStep,
    SetOpenModalStepper,
    SetSaveInfoWizard,
    InitializeWizard,
    WizardSetContactData,
    UpdateGeneralData,
    SetSocialMedia,
    GetMarketSections,
    SetAssociatedVertical,
    SetMarketSections,
    GetStepsList,
    SetStepperStatus,
    WizardSetWspData,
    SetIsLoading,
    GetTemplates,
    SetTemplate,
    SetButtonStatus,
    GetAllTemplates,
    ApplyTemplate,
    ShowOthersTemplates,
    ResetTemplatePaginator,
    ResetOthersTemplates,
    SetTemplateInState,
} from './stepper.actions';
import { Injectable } from '@angular/core';
import { Step } from '@app/_models/steps/step.model';
import { StepsService } from '@app/_services/steps.service';
import { catchError, tap } from 'rxjs/operators';
import { ResponseHelper } from '@app/_shared/helpers/ResponseHelper';
import { of } from 'rxjs';
import { McToastService } from '@app/_services';
import { MarketInterface } from '@app/_interfaces/market.interface';
import { MarketService } from '@app/_services/market/market.service';
import { Template } from '@app/_models/templates/template.model';
import { Paginator } from '@app/_interfaces/paginator.interface';
import { utils } from '@app/_utils';

export interface StepperStateModel {
    items: string[];
    currentStep: number;
    openModalStepper: boolean;
    saveInfoWizard: boolean;
    initializeWizard: boolean;
    stepperList: Step[];
    stepperGeneralStatus: any;
    generalData: MarketInterface;
    marketSectionsRecommended: any;
    marketSectionsOther: any;
    associatedVertical: string;
    wizardLogoName: string;
    isLoading: boolean;
    templateList: Array<Template>;
    othersTemplatesList: Array<Template>;
    recommendedTemplatesList: Array<Template>;
    templateSelected: Template;
    button: boolean;
    templatesPaginator: Paginator;
    showOthersTemplates: boolean;
    finishTemplatesPaginator: boolean;
}

@State<StepperStateModel>({
    name: 'stepper',
    defaults: {
        templateList: [],
        templateSelected: {},
        items: [],
        currentStep: 0,
        openModalStepper: false,
        saveInfoWizard: false,
        initializeWizard: false,
        stepperList: [],
        stepperGeneralStatus: [],
        generalData: null,
        marketSectionsRecommended: [],
        marketSectionsOther: [],
        associatedVertical: '',
        wizardLogoName: '',
        isLoading: false,
        button: false,
        othersTemplatesList: [],
        recommendedTemplatesList: [],
        templatesPaginator: {
            totalItems: 0,
            pageSize: 0,
            itemsPerPage: 0,
            currentPage: 0,
            lastPage: 0,
        },
        showOthersTemplates: false,
        finishTemplatesPaginator: false,
    },
})
@Injectable()
export class StepperState {
    constructor(
        private stepsService: StepsService,
        private responseHelper: ResponseHelper,
        private mcToast: McToastService,
        private marketService: MarketService,
        private translate: TranslateService
    ) {}
    @Selector()
    public static getState(state: StepperStateModel) {
        return state;
    }
    @Selector()
    public static stepperList(state: StepperStateModel): Step[] {
        return state.stepperList;
    }
    @Selector()
    public static getStepperGeneralStatus(state: StepperStateModel): any[] {
        return state.stepperGeneralStatus;
    }
    @Selector()
    public static getCurrentStep(state: StepperStateModel) {
        return state.currentStep;
    }
    @Selector()
    public static saveInfoWizard(state: StepperStateModel) {
        return state.saveInfoWizard;
    }
    @Selector()
    public static wizardLogoName(state: StepperStateModel) {
        return state.wizardLogoName;
    }
    @Selector()
    public static initializeWizard(state: StepperStateModel) {
        return state.initializeWizard;
    }

    @Selector()
    public static getOpenModalStepper(state: StepperStateModel) {
        return state.openModalStepper;
    }

    @Selector()
    public static isLoading(state: StepperStateModel) {
        return state.isLoading;
    }

    @Selector()
    public static button(state: StepperStateModel) {
        return state.button;
    }

    @Selector()
    public static templateSelected(state: StepperStateModel) {
        return state.templateSelected;
    }

    @Selector()
    public static marketSectionsRecommended(state: StepperStateModel): any[] {
        return state.marketSectionsRecommended;
    }

    @Selector()
    public static marketSectionsOther(state: StepperStateModel): any[] {
        return state.marketSectionsOther;
    }

    @Selector()
    public static associatedVertical(state: StepperStateModel): string {
        return state.associatedVertical;
    }

    @Selector()
    public static templateList(state: StepperStateModel) {
        return state.templateList;
    }

    @Selector()
    public static othersTemplateList(state: StepperStateModel) {
        return state.othersTemplatesList;
    }

    @Selector()
    public static recommendedTemplateList(state: StepperStateModel) {
        return state.recommendedTemplatesList;
    }

    @Selector()
    public static getPaginator(state: StepperStateModel) {
        return state.templatesPaginator;
    }
    @Selector()
    public static showOthersTemplates(state: StepperStateModel) {
        return state.showOthersTemplates;
    }
    @Selector()
    public static finishTemplatesPaginator(state: StepperStateModel) {
        return state.finishTemplatesPaginator;
    }

    @Action(SetCurrentStep)
    public setCurrentStep({ patchState, getState }: StateContext<StepperStateModel>, { param }: SetCurrentStep) {
        patchState({ currentStep: param });
    }

    @Action(SetOpenModalStepper)
    public setOpenModalStepper(
        { patchState, getState }: StateContext<StepperStateModel>,
        { param }: SetOpenModalStepper
    ) {
        patchState({ openModalStepper: param });
    }

    @Action(SetSaveInfoWizard)
    public setSaveInfoWizard({ patchState, getState }: StateContext<StepperStateModel>, { param }: SetSaveInfoWizard) {
        patchState({ saveInfoWizard: param });
    }

    @Action(InitializeWizard)
    public initializeWizard({ patchState, getState }: StateContext<StepperStateModel>, { param }: InitializeWizard) {
        patchState({ initializeWizard: param });
    }

    @Action(GetStepsList)
    public getStepList({ patchState }: StateContext<StepperStateModel>, { wizardName }: GetStepsList) {
        return this.stepsService.getStepsList(wizardName).pipe(
            tap((apiResponse) => {
                if (apiResponse) {
                    const checkResponse = this.responseHelper.checkError(apiResponse);
                    if (checkResponse.success) {
                        const stepsStatus = apiResponse['data'].status;
                        const steps = apiResponse['data'].steps;
                        patchState({ stepperList: steps, stepperGeneralStatus: stepsStatus });
                    }
                }
            }),
            catchError((error) => of(error))
        );
    }

    @Action(SetStepperStatus)
    public setStepperStatus({ patchState }: StateContext<StepperStateModel>, { stepperStatus }) {
        return this.stepsService.setStepperStatus(stepperStatus).pipe(
            tap((apiResponse) => {
                if (apiResponse) {
                    const checkResponse = this.responseHelper.checkError(apiResponse);
                    if (checkResponse.success) {
                    }
                }
            }),
            catchError((error) => {
                this.mcToast.danger('No se envió el estado del wizard');
                throw error;
            })
        );
    }

    @Action(WizardSetContactData)
    public wizardSetContacData({ patchState, dispatch }: StateContext<StepperStateModel>, { contactData }) {
        return this.marketService.setContactData(contactData).pipe(
            tap((apiResponse) => {
                if (apiResponse) {
                    const checkResponse = this.responseHelper.checkError(apiResponse);
                    if (checkResponse.success) {
                    }
                }
            }),
            catchError((error) => of(error))
        );
    }
    @Action(WizardSetWspData)
    public wizardSetWspData({ patchState, dispatch }: StateContext<StepperStateModel>, { wspData }) {
        return this.marketService.setWhatsappData(wspData).pipe(
            tap((apiResponse) => {
                if (apiResponse) {
                    const checkResponse = this.responseHelper.checkError(apiResponse);
                    if (checkResponse.success) {
                    }
                }
            }),
            catchError((error) => of(error))
        );
    }

    @Action(UpdateGeneralData)
    public setEmailAndAlias({ patchState }: StateContext<StepperStateModel>, { data }) {
        return this.stepsService.updateGeneralData(data).pipe(
            tap((res) => {
                if (res) {
                    const checkResponse = this.responseHelper.checkError(res);
                    if (checkResponse.success) {
                        const logoName = res.data.logo_name;
                        patchState({ wizardLogoName: logoName });
                    }
                }
            }),
            catchError((error) => {
                this.mcToast.danger('No se pudieron cargar los datos del sitio');
                throw error;
            })
        );
    }

    @Action(SetSocialMedia)
    public setSocialMedia({ patchState }: StateContext<StepperStateModel>, { socialMediaList }: SetSocialMedia) {
        return this.marketService.setSocialMedia(socialMediaList).pipe(
            tap((apiResponse) => {
                if (apiResponse) {
                    const checkResponse = this.responseHelper.checkError(apiResponse);
                    if (checkResponse.success) {
                    }
                }
            }),
            catchError((error) => of(error))
        );
    }

    @Action(GetMarketSections)
    public getMarketSections({ patchState }: StateContext<StepperStateModel>) {
        return this.stepsService.getMarketSections().pipe(
            tap((apiResponse) => {
                if (apiResponse) {
                    const checkResponse = this.responseHelper.checkError(apiResponse);
                    if (checkResponse.success) {
                        const sectionsRecommended = apiResponse.data.recommended;
                        const sectionsOther = apiResponse.data.other;
                        patchState({
                            marketSectionsRecommended: sectionsRecommended,
                            marketSectionsOther: sectionsOther,
                        });
                    }
                }
            }),
            catchError((error) => of(error))
        );
    }

    @Action(SetMarketSections)
    public setMarketSections({ patchState }: StateContext<StepperStateModel>, { sectionsList }) {
        return this.stepsService.setMarketSections(sectionsList).pipe(
            tap((apiResponse) => {
                if (apiResponse) {
                    const checkResponse = this.responseHelper.checkError(apiResponse);
                    if (checkResponse.success) {
                    }
                }
            }),
            catchError((error) => {
                this.mcToast.danger('No se pudieron seleccionar las páginas');
                throw error;
            })
        );
    }

    @Action(SetAssociatedVertical)
    public setAssociatedVertical(
        { patchState, getState }: StateContext<StepperStateModel>,
        { param }: SetAssociatedVertical
    ) {
        patchState({ associatedVertical: param });
    }

    @Action(SetIsLoading)
    public setIsLoading({ patchState }: StateContext<StepperStateModel>, { param }: SetIsLoading) {
        patchState({ isLoading: param });
    }

    @Action(SetButtonStatus)
    public SetButtonStatus({ patchState }: StateContext<StepperStateModel>, { param }: SetButtonStatus) {
        patchState({ button: param });
    }

    @Action(GetTemplates)
    public getTemplates({ patchState, getState }: StateContext<StepperStateModel>, { verticalId }: GetTemplates) {
        return this.marketService.getTemplates(verticalId).pipe(
            tap((apiResponse) => {
                if (apiResponse) {
                    const checkResponse = this.responseHelper.checkError(apiResponse);
                    if (checkResponse.success) {
                        const { recommended, selected } = apiResponse.data;
                        const filteredTemplates = recommended.filter((template) => template.id !== selected.id);
                        filteredTemplates.unshift(selected);

                        patchState({
                            templateList: recommended,
                            templateSelected: selected,
                            recommendedTemplatesList: filteredTemplates,
                        });
                    }
                }
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    @Action(GetAllTemplates)
    public getAllTemplates({ patchState, getState }: StateContext<StepperStateModel>, { pageNumber }: GetAllTemplates) {
        return this.marketService.getAllTemplates(pageNumber).pipe(
            tap((apiResponse) => {
                if (apiResponse) {
                    const state = getState();
                    const selectedTemplate = state.templateSelected;
                    const checkResponse = this.responseHelper.checkError(apiResponse);
                    if (checkResponse.success) {
                        const stateTemplates = state.othersTemplatesList;
                        const { data, ...paginated } = apiResponse.data.others;
                        const othersTemplates = [...stateTemplates, ...data];
                        const recommendedTemplateList = apiResponse.data.recommended;
                        const filteredRecommendedTemplates = recommendedTemplateList.filter(
                            (template) => template.id !== selectedTemplate.id
                        );
                        filteredRecommendedTemplates.unshift(selectedTemplate);
                        const filteredOthersTemplates = othersTemplates.filter(
                            (template) => template.id !== selectedTemplate.id
                        );
                        patchState({
                            othersTemplatesList: filteredOthersTemplates,
                            templatesPaginator: utils.getPaginateParams(paginated),
                            recommendedTemplatesList: filteredRecommendedTemplates,
                        });
                    }
                }
            }),
            catchError((error) => {
                this.mcToast.danger('No se pudo realizar la operación, intente nuevamente más tarde');
                throw error;
            })
        );
    }

    @Action(SetTemplate)
    public setTemplate({ patchState, getState }: StateContext<StepperStateModel>, { template }: SetTemplate) {
        return this.marketService.setTemplate(template.id).pipe(
            tap(
                (apiResponse) => {
                    const state = getState();
                    const recommendedTemplatesList = state.recommendedTemplatesList;

                    if (apiResponse) {
                        recommendedTemplatesList.filter((template) => template.id !== template.id).unshift(template);
                        patchState({
                            templateSelected: template,
                            recommendedTemplatesList: recommendedTemplatesList,
                        });
                    }
                },
                (err) => err
            ),
            catchError((error) => {
                this.mcToast.danger('No se pudo realizar la operación, intente nuevamente más tarde');
                throw error;
            })
        );
    }

    @Action(ApplyTemplate)
    public applyTemplate({ patchState }: StateContext<StepperStateModel>, { options }: ApplyTemplate) {
        return this.marketService.applyTemplate(options).pipe(
            tap(
                (apiResponse) => {
                    if (apiResponse) {
                        this.mcToast.success(this.translate.instant('app.global.toast.operacion_exitosa'));
                    }
                },
                (err) => err
            ),
            catchError((error) => {
                this.mcToast.danger(this.translate.instant('app.global.toast.no_se_pudo_realizar'));
                throw error;
            })
        );
    }
    @Action(ShowOthersTemplates)
    public showOthersTemplates({ patchState }: StateContext<StepperStateModel>, { param }: ShowOthersTemplates) {
        patchState({ showOthersTemplates: param });
    }

    @Action(SetTemplateInState)
    public SetTemplateInState({ patchState }: StateContext<StepperStateModel>, { template }: SetTemplateInState) {
        patchState({
            templateSelected: template,
        });
    }

    @Action(ResetTemplatePaginator)
    public resetTemplatePaginator({ patchState }: StateContext<StepperStateModel>) {
        const paginator = {
            totalItems: 0,
            pageSize: 0,
            itemsPerPage: 0,
            currentPage: 0,
            lastPage: 0,
        };
        patchState({ templatesPaginator: paginator });
    }

    @Action(ResetOthersTemplates)
    public resetOthersTemplates({ patchState }: StateContext<StepperStateModel>) {
        patchState({ othersTemplatesList: [] });
    }
}
