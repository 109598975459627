import { SalesFilters } from '@app/_models/e-commerce/sales/sales.model';

export class SetStatisticsNav {
    public static readonly type = '[Statistics] Set Statistics Nav';

    constructor() {}
}

export class AddSiteToMatomo {
    static readonly type = '[Statistics] AddSiteToMatomo';

    constructor() {}
}

export class SetDate {
    public static readonly type = '[Statistics] Set Date';

    constructor(public date: string) {}
}

export class SetRangeDate {
    public static readonly type = '[Statistics] Set Range Date';

    constructor(public date: any) {}
}

export class CallForVisitsSummary {
    public static readonly type = '[Statistics] Call For Visit Summary';

    constructor(public when: string, public currency?: string) {}
}

export class CallForVisitsByCountry {
    public static readonly type = '[Statistics] Call For Visits By Country';

    constructor(public when: string) {}
}

export class CallForVisitsByPage {
    public static readonly type = '[Statistics] Call For Visits By Page';

    constructor(public when: string) {}
}

export class CallForVisitsByDevices {
    public static readonly type = '[Statistics] Call For Visits By Devices';

    constructor(public when: string) {}
}

export class CallForVisitsByOrigin {
    public static readonly type = '[Statistics] Call For Visits By Origin';

    constructor(public when: string) {}
}

export class CallForVisitsByBrowser {
    public static readonly type = '[Statistics] Call For Visits By Browser';

    constructor(public when: string) {}
}

export class CallForVisitsSummaryChart {
    public static readonly type = '[Statistics] Call For Visit Summary Chart';

    constructor(public when: string) {}
}

export class ResetCharts {
    public static readonly type = '[Statistics] ResetCharts';
}

export class CallForSales {
    public static readonly type = '[Statistics] Call For Sales';
    constructor(public when: string, public currency: string) {}
}

export class CallForOrders {
    public static readonly type = '[Statistics] Call For Orders';
    constructor(public filters: SalesFilters) {}
}

export class SetLoading {
    public static readonly type = '[Statistics] SetLoading';

    constructor(public param: boolean) {}
}
