<div class="version-bar d-flex align-items-center border-bottom py-2 px-3" [@slideInOut]>
    <div class="d-flex flex-fill align-items-center justify-content-center">
        <ng-container>
            <mc-icon prefix="far" name="redo" size="lg" class="mr-3"></mc-icon>
            <h6 class="mb-0">
                {{ 'app.global.site_info_banner.version_banner.hay_nueva_version' | translate }}
                <a href="#" (click)="reload()" preventDefault>
                    {{ 'app.global.site_info_banner.version_banner.actualizar' | translate }}
                </a>
                {{ 'app.global.site_info_banner.version_banner.version_mas_reciente' | translate }}
            </h6>
        </ng-container>
    </div>
    <a href="#" preventDefault class="px-2 text-dark" (click)="close()">
        <mc-icon prefix="fal" name="times" size="lg"></mc-icon>
    </a>
</div>
