import { CountryCode } from '@app/_models/countries/countryCode.model';

/* Image Flags URL : https://www.countryflags.com/ */
export const countryCodes: CountryCode[] = [
    {
        id: 'alemania',
        name: 'DE',
        alpha2Code: 'DE',
        alpha3Code: 'DEU',
        numericCode: '49',
        image_url: 'alemania.png',
    },
    {
        id: 'argentina',
        name: 'AR',
        alpha2Code: 'AR',
        alpha3Code: 'ARG',
        numericCode: '54',
        // image_url: 'argentina.jpg',
        image_url: 'spain.png',
    },
    {
        id: 'bolivia',
        name: 'BO',
        alpha2Code: 'BO',
        alpha3Code: 'BOL',
        numericCode: '591',
        image_url: 'bolivia.jpg',
    },
    {
        id: 'brasil',
        name: 'BR',
        alpha2Code: 'BR',
        alpha3Code: 'BRA',
        numericCode: '55',
        image_url: 'brasil.png',
    },
    {
        id: 'chile',
        name: 'CL',
        alpha2Code: 'CL',
        alpha3Code: 'CHL',
        numericCode: '56',
        image_url: 'chile.png',
    },
    {
        id: 'colombia',
        name: 'CO',
        alpha2Code: 'CO',
        alpha3Code: 'COL',
        numericCode: '57',
        image_url: 'colombia.png',
    },
    {
        id: 'costa rica',
        name: 'CR',
        alpha2Code: 'CR',
        alpha3Code: 'CRI',
        numericCode: '506',
        image_url: 'costa-rica.png',
    },
    {
        id: 'ecuador',
        name: 'EC',
        alpha2Code: 'EC',
        alpha3Code: 'ECU',
        numericCode: '593',
        image_url: 'ecuador.png',
    },
    {
        id: 'emiratos árabes',
        name: 'EA',
        alpha2Code: 'EA',
        alpha3Code: 'EAR',
        numericCode: '971',
        image_url: 'arab_emirates.png',
    },
    {
        id: 'españa',
        name: 'ES',
        alpha2Code: 'ES',
        alpha3Code: 'ESP',
        numericCode: '34',
        image_url: 'spain.png',
    },
    {
        id: 'estados Unidos',
        name: 'US',
        alpha2Code: 'US',
        alpha3Code: 'USA',
        numericCode: '1',
        image_url: 'usa.png',
    },
    {
        id: 'italia',
        name: 'IT',
        alpha2Code: 'IT',
        alpha3Code: 'ITA',
        numericCode: '39',
        image_url: 'italia.png',
    },
    {
        id: 'méxico',
        name: 'MX',
        alpha2Code: 'MX',
        alpha3Code: 'MEX',
        numericCode: '52',
        image_url: 'mexico.png',
    },
    {
        id: 'nicaragua',
        name: 'NI',
        alpha2Code: 'NI',
        alpha3Code: 'NIC',
        numericCode: '505',
        image_url: 'nicaragua.png',
    },
    {
        id: 'noruega',
        name: 'NO',
        alpha2Code: 'NO',
        alpha3Code: 'NOR',
        numericCode: '47',
        image_url: 'noruega.png',
    },
    {
        id: 'paraguay',
        name: 'PY',
        alpha2Code: 'PY',
        alpha3Code: 'PRY',
        numericCode: '595',
        image_url: 'paraguay.png',
    },
    {
        id: 'perú',
        name: 'PE',
        alpha2Code: 'PE',
        alpha3Code: 'PER',
        numericCode: '51',
        image_url: 'peru.png',
    },
    {
        id: 'uruguay',
        name: 'UY',
        alpha2Code: 'UY',
        alpha3Code: 'URY',
        numericCode: '598',
        image_url: 'uruguay.png',
    },
    {
        id: 'venezuela',
        name: 'VE',
        alpha2Code: 'VE',
        alpha3Code: 'VEN',
        numericCode: '58',
        image_url: 'venezuela.png',
    },
];
