import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SetWizardStatus } from '@app/components/ss-stepper/store/stepper.actions';
import { SetLoadingFormSubmitAction } from '@app/pages/web-site/design/store/design.actions';
import { InitialInfo, Session } from '@app/_interfaces';
import { MarketConfiguration } from '@app/_interfaces/configuration/marketdata.interface';
import { Language } from '@app/_models/languages/language';
import { CustomTheme, Theme, ThemesError } from '@app/_models/themes/theme.model';
import { WizardItem } from '@app/_models/wizard/wizard-item.model';
import { McToastService } from '@app/_services';
import { MarketService } from '@app/_services/market/market.service';
import { Languages } from '@app/_shared/data/languages';
import { sidebar } from '@app/_shared/data/sidebar-menu';
import { LanguageHelper } from '@app/_shared/helpers/LanguageHelper';
import { ResponseHelper } from '@app/_shared/helpers/ResponseHelper';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { SetSidebarMenu } from '../sidebar/store/sidebar.actions';
import {
    GetGeneralInfoApp,
    GetInfoDashboard,
    GetMainMarket,
    GetPagesLanguages,
    GetThemes,
    GetVerticalList,
    Logout,
    SetDemo,
    SetGlobalLoading,
    SetHideSidebar,
    SetInternalServerError,
    SetIsLogged,
    SetIsMobile,
    SetIsOnline,
    SetLangSelected,
    SetLastVisit,
    SetMarketLanguage,
    SetPhotoProfileSideBarTemp,
    SetProfilePhotoUrl,
    SetSession,
    SetTheme,
    SetSiteConstruction,
    SetVertical,
    GetCustomThemes,
    AddCustomTheme,
    GetFonts,
    SetUserName,
    UpdateCustomTheme,
    DeleteCustomTheme,
    SetCustomTheme,
    ShowAppVersionBanner,
    SetVerticalList,
} from './app.actions';

export interface AppStateModel {
    loading: boolean;
    loadingFormSubmit: boolean;
    loadingThemes: boolean;
    session: Session;
    isLogged: boolean;
    lastVisit: string;
    isMobile: boolean;
    isOnline: boolean;
    hideSidebar: boolean;
    marketLanguage: Language;
    langs: Array<Language>;
    adminPrefix: string;
    verticalList: Array<WizardItem>;
    verticalType: string;
    themes: Array<Theme>;
    themeSelected: Theme | CustomTheme;
    infoDashboard: any;
    wizardStatus: boolean | null;
    siteAliases: string;
    marketSubdomain: string;
    pagesLanguages: any;
    defaultPagesLang: any;
    pageLanguagesIds: Array<number>;
    profilePhotoUrl: string;
    isTrial: boolean;
    profilePhotoTemp: string;
    assetsUrls: string;
    tempAssetsUrl: string;
    assetsDomain: string;
    config: Object;
    internalServerError: boolean;
    makeMePremiumLink: string;
    customThemeList: Array<CustomTheme>;
    fonts: Array<string>;
    userName: string;
    themesError: ThemesError;
    showAppVersionBanner: boolean;
    servicesList: Array<number>;
}

@State<AppStateModel>({
    name: 'App',
    defaults: {
        loading: true,
        loadingFormSubmit: false,
        loadingThemes: false,
        session: null,
        isLogged: false,
        adminPrefix: 'admin',
        lastVisit: '',
        isMobile: false,
        isOnline: true,
        hideSidebar: false,
        marketLanguage: null,
        langs: Languages,
        verticalList: [],
        verticalType: '',
        themes: [],
        infoDashboard: [],
        wizardStatus: null,
        marketSubdomain: '',
        siteAliases: '',
        pagesLanguages: [],
        defaultPagesLang: {},
        pageLanguagesIds: [],
        themeSelected: null,
        profilePhotoUrl: '',
        isTrial: false,
        profilePhotoTemp: '',
        assetsUrls: '',
        assetsDomain: '',
        tempAssetsUrl: '',
        config: {},
        internalServerError: null,
        makeMePremiumLink: '',
        customThemeList: [],
        fonts: [],
        userName: null,
        themesError: null,
        showAppVersionBanner: false,
        servicesList: [],
    },
})
@Injectable()
export class AppState {
    constructor(
        private marketService: MarketService,
        private responseHelper: ResponseHelper,
        private mcToast: McToastService,
        private languageHelper: LanguageHelper,
        private store: Store,
        private translate: TranslateService,
        private dialog: MatDialog
    ) {}

    @Selector()
    public static loading(state: AppStateModel) {
        return state.loading;
    }

    @Selector()
    public static showAppVersionBanner(state: AppStateModel) {
        return state.showAppVersionBanner;
    }

    @Selector()
    public static userName(state: AppStateModel) {
        return state.userName;
    }

    @Selector()
    public static loadingThemes(state: AppStateModel) {
        return state.loadingThemes;
    }

    @Selector()
    public static getLoadingFormSubmit(state: AppStateModel) {
        return state.loadingFormSubmit;
    }

    @Selector()
    public static wizardStatus(state: AppStateModel) {
        return state.wizardStatus;
    }

    @Selector()
    public static getConfig(state: AppStateModel) {
        return state.config as MarketConfiguration;
    }

    @Selector()
    public static isTrial(state: AppStateModel) {
        return state.isTrial;
    }

    @Selector()
    public static siteAliases(state: AppStateModel) {
        return state.siteAliases;
    }

    @Selector()
    public static marketSubdomain(state: AppStateModel) {
        return state.marketSubdomain;
    }

    @Selector()
    public static themes(state: AppStateModel) {
        return state.themes;
    }

    @Selector()
    public static themeSelected(state: AppStateModel) {
        return state.themeSelected;
    }

    @Selector()
    public static customThemeList(state: AppStateModel) {
        return state.customThemeList;
    }

    @Selector()
    public static themesError(state: AppStateModel) {
        return state.themesError;
    }

    @Selector()
    public static infoDashboard(state: AppStateModel) {
        return state.infoDashboard;
    }

    @Selector()
    public static getProfilePhotoTemp(state: AppStateModel) {
        return state.profilePhotoTemp;
    }

    @Selector()
    public static getMarketLanguage(state: AppStateModel) {
        return state.marketLanguage;
    }

    @Selector()
    public static getMarketLanguageValue(state: AppStateModel) {
        return state.marketLanguage.value;
    }

    @Selector()
    public static getLanguages(state: AppStateModel) {
        return state.langs;
    }

    @Selector()
    public static pageLanguagesIds(state: AppStateModel) {
        return state.pageLanguagesIds;
    }

    @Selector()
    public static session(state: AppStateModel) {
        return state.session;
    }

    @Selector()
    public static isLogged(state: AppStateModel) {
        return state.isLogged;
    }

    @Selector()
    public static lastVisit(state: AppStateModel) {
        return state.lastVisit;
    }

    @Selector()
    public static isMobile(state: AppStateModel) {
        return state.isMobile;
    }

    @Selector()
    public static isOnline(state: AppStateModel) {
        return state.isOnline;
    }

    @Selector()
    public static hideSidebar(state: AppStateModel) {
        return state.hideSidebar;
    }

    @Selector()
    public static getVerticalList(state: AppStateModel) {
        return state.verticalList;
    }

    @Selector()
    public static getVerticalType(state: AppStateModel) {
        return state.verticalType;
    }

    @Selector()
    public static adminPrefix(state: AppStateModel) {
        return state.adminPrefix;
    }

    @Selector()
    public static pagesLanguages(state: AppStateModel) {
        return state.pagesLanguages;
    }

    @Selector()
    public static defaultPagesLang(state: AppStateModel): number {
        return state.defaultPagesLang;
    }

    @Selector()
    public static profilePhotoUrl(state: AppStateModel): string {
        return state.profilePhotoUrl;
    }

    @Selector()
    public static assetsUrls(state: AppStateModel): string {
        return state.assetsUrls;
    }

    @Selector()
    public static assetsDomain(state: AppStateModel): string {
        return state.assetsDomain;
    }

    @Selector()
    public static tempAssetsUrl(state: AppStateModel): string {
        return state.tempAssetsUrl;
    }

    @Selector()
    public static getInternalServerError(state: AppStateModel) {
        return state.internalServerError;
    }

    @Selector()
    public static getMakeMePremiumLink(state: AppStateModel) {
        return state.makeMePremiumLink;
    }

    @Selector()
    public static fonts(state: AppStateModel) {
        return state.fonts;
    }

    @Selector()
    public static getServicesList(state: AppStateModel) {
        return state.servicesList;
    }

    @Action(SetGlobalLoading)
    public setGlobalLoading({ patchState, getState }: StateContext<AppStateModel>, { value }: SetGlobalLoading) {
        patchState({ loading: value });
    }

    @Action(SetSession)
    public setSession({ patchState, getState }: StateContext<AppStateModel>, { session }: SetSession) {
        const state = getState();
        const hasSession = session && !!session?.UserName;

        return patchState({
            isLogged: hasSession,
            session: hasSession ? session : null,
        });
    }

    @Action(SetLastVisit)
    public setLastVisit({ patchState, getState }: StateContext<AppStateModel>, { lastVisit }: SetLastVisit) {
        const state = getState();

        lastVisit =
            lastVisit.indexOf('lp-salida') === -1 && lastVisit.indexOf('ingresar') === -1 ? lastVisit : state.lastVisit;

        patchState({ lastVisit });
    }

    @Action(Logout)
    public logout({ dispatch }: StateContext<AppStateModel>) {
        dispatch(new SetSession(null));
    }

    @Action(SetIsMobile)
    public setIsMobile({ patchState, getState }: StateContext<AppStateModel>, { isMobile }: SetIsMobile) {
        const state = getState();
        if (state.isMobile !== isMobile) {
            patchState({ isMobile });
        }
    }

    @Action(SetIsOnline)
    public setOnline({ patchState, getState }: StateContext<AppStateModel>, { isOnline }: SetIsOnline) {
        const state = getState();
        if (state.isOnline !== isOnline) {
            patchState({ isOnline });
        }
    }

    @Action(SetProfilePhotoUrl)
    public setProfilePhotoUrl({ patchState, getState }: StateContext<AppStateModel>, { url }: SetProfilePhotoUrl) {
        patchState({ profilePhotoUrl: url });
    }

    @Action(SetHideSidebar)
    public setHideSidebar({ patchState, getState }: StateContext<AppStateModel>, { hideSidebar }: SetHideSidebar) {
        patchState({ hideSidebar });
    }

    @Action(SetMarketLanguage)
    public SetMarketLanguage(
        { patchState, getState }: StateContext<AppStateModel>,
        { marketLanguage }: SetMarketLanguage
    ) {
        patchState({ loadingFormSubmit: true });
        return this.marketService.setPanelLanguage(marketLanguage.value).pipe(
            tap((response: any) => {
                if (response) {
                    const checkResponse = this.responseHelper.checkError(response);
                    if (checkResponse.success) {
                        patchState({ marketLanguage: marketLanguage, loadingFormSubmit: false });
                        this.mcToast.success(this.translate.instant('app.global.toast.operacion_exitosa'));
                    }
                }
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    @Action(GetMainMarket)
    public getInfoMainMarket({ patchState }: StateContext<AppStateModel>) {
        return this.marketService.getInfoMainMarket().pipe(
            tap((response: any) => {
                if (response) {
                    const checkResponse = this.responseHelper.checkError(response);
                    if (checkResponse.success) {
                        const { vertical } = response.data;
                        vertical ? patchState({ verticalType: vertical }) : patchState({ verticalType: null });
                    }
                }
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    @Action(GetVerticalList)
    public getVerticalList({ patchState }: StateContext<AppStateModel>) {
        return this.marketService.getVerticalList().pipe(
            tap((response: any) => {
                if (response) {
                    const checkResponse = this.responseHelper.checkError(response);
                    if (checkResponse.success) {
                        patchState({ verticalList: response.data });
                    }
                }
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    @Action(SetVertical)
    public setVertical({ patchState }: StateContext<AppStateModel>, { vertical }: SetVertical) {
        return this.marketService.setVertical(vertical).pipe(
            tap((response: any) => {
                if (response) {
                    const checkResponse = this.responseHelper.checkError(response);
                    if (checkResponse.success) {
                    }
                }
            }),
            catchError((error) => {
                this.mcToast.danger('Ups, no se pudo cargar el tipo de sitio! ');
                throw error;
            })
        );
    }

    @Action(GetInfoDashboard)
    public getInfoDashboard({ patchState }: StateContext<AppStateModel>) {
        return this.marketService.getInfoDashboard().pipe(
            tap((response: any) => {
                if (response) {
                    const checkResponse = this.responseHelper.checkError(response);
                    if (checkResponse.success) {
                        patchState({ infoDashboard: response.data });
                    }
                }
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    @Action(GetGeneralInfoApp)
    public getGeneralInfoApp({ patchState, dispatch }: StateContext<AppStateModel>) {
        patchState({ loading: true });
        return this.marketService.getGeneralInfoApp().pipe(
            tap((response: InitialInfo) => {
                if (response) {
                    const checkResponse = this.responseHelper.checkError(response);
                    if (checkResponse.success) {
                        const data = response.data;
                        sessionStorage.setItem('marketId', data.mainMarket.id.toString());
                        const urls = data.urls;
                        const marketSubdomain = urls.urlBase;
                        const infoDashboard = data.dashboard;
                        const marketLoacale = this.languageHelper.getLanguageByValue(data.config.localizacion);
                        const mainMarket = data.mainMarket;
                        const wizardStatus = mainMarket.estadowizard;
                        const alias = mainMarket.alias;
                        const vertical = mainMarket.vertical;
                        const servicesIds: number[] = data.services.map(({ id }) => id);
                        const donwebUrl = 'https://donweb.com/es-ar/';
                        const config = {
                            ...data.config,
                            idMarket: data.mainMarket.id,
                            is_demo: mainMarket.is_demo,
                            diasdepruebarestantes: mainMarket.diasdepruebarestantes,
                            borrado: mainMarket.borrado,
                            habilitado: mainMarket.habilitado,
                            idhosting: mainMarket.hostingId,
                            isoPais: mainMarket.isoPais,
                        };
                        vertical
                            ? patchState({ verticalType: vertical.toString() })
                            : patchState({ verticalType: null });
                        patchState({
                            servicesList: servicesIds,
                            wizardStatus: wizardStatus,
                            siteAliases: alias,
                            infoDashboard: infoDashboard,
                            marketLanguage: marketLoacale,
                            isTrial: false,
                            marketSubdomain: marketSubdomain,
                            assetsUrls: urls.urlAssetsContent,
                            tempAssetsUrl: urls.urlAssetsTmp,
                            assetsDomain: urls.urlAssets,
                            config,
                            makeMePremiumLink: `${donwebUrl}diy-hazte-premium?idHosting=${config.idhosting}&idMarket=${config.idMarket}&newDIY=true`,
                            loading: false,
                            internalServerError: false,
                        });

                        sidebar.forEach((menu) => {
                            if (menu.availableOnlyInFeature.length) {
                                menu.show = _.intersection(servicesIds, menu.availableOnlyInFeature).length;
                            }

                            menu.children.forEach((child) => {
                                if (child.availableOnlyInFeature.length) {
                                    child.show = _.intersection(servicesIds, child.availableOnlyInFeature).length;
                                }
                            });
                        });
                        dispatch(new SetSidebarMenu(sidebar));
                        dispatch(new GetPagesLanguages());
                    }
                }
            }),
            catchError((error) => {
                dispatch(new SetGlobalLoading(false));
                throw error;
            })
        );
    }

    @Action(SetWizardStatus)
    public setWizardStatus({ patchState }: StateContext<AppStateModel>, { param }: SetWizardStatus) {
        patchState({ wizardStatus: param });
        return this.marketService.setWizardStatus(param).pipe(
            tap((response: any) => {
                if (response) {
                    const checkResponse = this.responseHelper.checkError(response);
                    if (checkResponse.success) {
                    }
                }
            }),
            catchError((error) => {
                this.mcToast.danger('Ups, no se pudo cargar guardar la información del sitio.');
                throw error;
            })
        );
    }

    @Action(GetPagesLanguages)
    public getPagesLanguages({ getState, patchState, dispatch }: StateContext<AppStateModel>) {
        return this.marketService.getPagesLanguages().pipe(
            tap((res: any) => {
                if (res) {
                    const checkResponse = this.responseHelper.checkError(res);
                    if (checkResponse.success) {
                        let defaultLang;
                        const pagesLanguages = res.data.langs;
                        const pagesLang = JSON.parse(localStorage.getItem('pagesLang'));
                        const pagesLanguagesIds = [];
                        pagesLang
                            ? (defaultLang = pagesLang)
                            : (defaultLang = pagesLanguages.find((lang) => lang.default == true));
                        pagesLanguages.forEach((element) => {
                            pagesLanguagesIds.push(element.id);
                        });
                        patchState({
                            pagesLanguages: pagesLanguages,
                            defaultPagesLang: defaultLang,
                            pageLanguagesIds: pagesLanguagesIds,
                        });
                    }
                }
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    @Action(SetDemo)
    public setDemo({ patchState, getState }: StateContext<AppStateModel>, { setDemo }: SetDemo) {
        return patchState({
            isTrial: setDemo,
        });
    }

    @Action(SetLangSelected)
    public setLangSelected({ getState, patchState }: StateContext<AppStateModel>, { lang }: SetLangSelected) {
        patchState({ defaultPagesLang: lang });
    }

    @Action(SetPhotoProfileSideBarTemp)
    public setPhotoProfileSideBarTemp(
        { patchState, getState }: StateContext<AppStateModel>,
        { image }: SetPhotoProfileSideBarTemp
    ) {
        return patchState({
            profilePhotoTemp: image,
        });
    }

    @Action(SetInternalServerError)
    public setInternalServerError(
        { patchState, getState }: StateContext<AppStateModel>,
        payload: SetInternalServerError
    ) {
        patchState({ internalServerError: true });
    }

    @Action(SetIsLogged)
    public setIsLogged({ patchState, getState }: StateContext<AppStateModel>, { isLogged }: SetIsLogged) {
        patchState({ isLogged: isLogged });
    }

    @Action(SetSiteConstruction)
    public setSiteConstruction(
        { patchState, getState }: StateContext<AppStateModel>,
        { siteConstruction }: SetSiteConstruction
    ) {
        const state = getState();
        const data = { ...state.config, activo: siteConstruction };
        patchState({ config: data });
    }

    @Action(SetTheme)
    public setTheme({ patchState, getState }: StateContext<AppStateModel>, { theme }: SetTheme) {
        this.store.dispatch(new SetLoadingFormSubmitAction(true));
        return this.marketService.setTheme(theme).pipe(
            tap(
                (response: any) => {
                    if (response) {
                        const checkResponse = this.responseHelper.checkError(response);
                        if (checkResponse.success) {
                            const state = getState();
                            const themes = [...state.themes];
                            const themeSelected: Theme = themes.find((item: Theme) => item.id == theme.id);
                            const filteredThemes = themes.filter((item: Theme) => item.id !== theme.id);
                            filteredThemes.unshift(themeSelected);
                            patchState({ themeSelected: themeSelected, themes: filteredThemes });
                            this.store.dispatch(new SetLoadingFormSubmitAction(false));
                        }
                    }
                },
                (error) => of(error)
            )
        );
    }

    @Action(GetThemes)
    public getThemes({ patchState }: StateContext<AppStateModel>) {
        patchState({ loadingThemes: true });
        return this.marketService.getThemes().pipe(
            tap((response: any) => {
                if (response) {
                    const checkResponse = this.responseHelper.checkError(response);
                    if (checkResponse.success) {
                        const { selected_theme, themes } = response.data;
                        if (selected_theme !== 0) {
                            const selectedTheme = themes.find((theme) => theme.id === selected_theme);
                            const filteredThemes: Theme[] = themes.filter((item: Theme) => item !== selectedTheme);
                            filteredThemes.unshift(selectedTheme);
                            patchState({
                                themeSelected: selectedTheme,
                                themes: filteredThemes,
                                loadingThemes: false,
                            });
                        } else {
                            patchState({
                                loadingThemes: false,
                                themes: themes,
                            });
                        }
                    }
                }
            }),
            catchError((error) => {
                this.mcToast.danger(this.translate.instant('app.global.toast.no_se_pudo_realizar'));
                throw error;
            })
        );
    }

    @Action(GetCustomThemes)
    public getCustomThemes({ patchState }: StateContext<AppStateModel>) {
        return this.marketService.getCustomThemes().pipe(
            tap((response: any) => {
                if (response) {
                    const { selected_theme, custom_themes } = response.data;
                    if (selected_theme !== 0) {
                        const selectedTheme = custom_themes.find((theme) => theme.id === selected_theme);
                        const filteredThemes: CustomTheme[] = custom_themes.filter(
                            (item: CustomTheme) => item !== selectedTheme
                        );
                        filteredThemes.unshift(selectedTheme);
                        patchState({
                            themeSelected: selectedTheme,
                            customThemeList: filteredThemes,
                        });
                    } else {
                        patchState({
                            customThemeList: custom_themes,
                        });
                    }
                }
            }),
            catchError((error) => {
                this.mcToast.danger(this.translate.instant('app.global.toast.no_se_pudo_realizar'));
                throw error;
            })
        );
    }

    @Action(AddCustomTheme)
    public addCustomTheme({ patchState, getState }: StateContext<AppStateModel>, { customTheme }: AddCustomTheme) {
        return this.marketService.setCustomTheme(customTheme).pipe(
            tap((response: any) => {
                if (response) {
                    const state = getState();
                    const customThemes = [...state.customThemeList, response.data];
                    patchState({
                        customThemeList: customThemes,
                        themesError: null,
                    });
                    this.dialog.closeAll();
                    this.mcToast.success(this.translate.instant('app.diseno.themes.creaste_un_tema'));
                }
            }),
            catchError((error) => {
                if (error.status === 422 && error.error) {
                    patchState({
                        themesError: error.error,
                    });
                } else {
                    this.mcToast.danger(this.translate.instant('app.diseno.themes.no_guardamos_cambios'), 'large');
                }
                throw error;
            })
        );
    }

    @Action(SetCustomTheme)
    public setCustomTheme({ patchState, getState }: StateContext<AppStateModel>, { customTheme }: SetCustomTheme) {
        return this.marketService.setTheme(customTheme).pipe(
            tap(
                (response: any) => {
                    if (response) {
                        const checkResponse = this.responseHelper.checkError(response);
                        if (checkResponse.success) {
                            const state = getState();
                            const customThemes = [...state.customThemeList];
                            const themeSelected: CustomTheme = customThemes.find(
                                (item: CustomTheme) => item.id == customTheme.id
                            );
                            const filteredCustomThemes = customThemes.filter(
                                (item: CustomTheme) => item.id !== customTheme.id
                            );
                            filteredCustomThemes.unshift(themeSelected);

                            patchState({ themeSelected: themeSelected, customThemeList: filteredCustomThemes });
                        }
                    }
                },
                (error) => of(error)
            )
        );
    }

    @Action(UpdateCustomTheme)
    public updateCustomTheme(
        { patchState, getState }: StateContext<AppStateModel>,
        { customTheme }: UpdateCustomTheme
    ) {
        return this.marketService.updateCustomTheme(customTheme).pipe(
            tap((response: any) => {
                if (response) {
                    const state = getState();
                    const customThemes = state.customThemeList;
                    const index = customThemes.findIndex((theme: CustomTheme) => theme.id === customTheme.id);
                    customThemes[index] = customTheme;
                    patchState({
                        customThemeList: customThemes,
                    });
                    this.dialog.closeAll();
                    this.mcToast.success(this.translate.instant('app.diseno.themes.editaste_un_tema'));
                }
            }),
            catchError((error) => {
                if (error.error) {
                    patchState({
                        themesError: error.error,
                    });
                }
                this.mcToast.danger(this.translate.instant('app.diseno.themes.no_guardamos_cambios'), 'large');
                throw error;
            })
        );
    }

    @Action(DeleteCustomTheme)
    public deleteCustomTheme(
        { patchState, getState }: StateContext<AppStateModel>,
        { customTheme }: DeleteCustomTheme
    ) {
        return this.marketService.deleteCustomTheme(customTheme).pipe(
            tap((response: any) => {
                if (response) {
                    const checkResponse = this.responseHelper.checkError(response);
                    if (checkResponse.success) {
                        const state = getState();
                        const customThemes = state.customThemeList.filter(
                            (theme: CustomTheme) => theme.id !== customTheme.id
                        );
                        patchState({
                            customThemeList: customThemes,
                        });
                        this.mcToast.success(this.translate.instant('app.diseno.themes.eliminaste_un_tema'));
                    }
                }
            }),
            catchError((error) => {
                this.mcToast.danger(this.translate.instant('app.diseno.themes.no_guardamos_cambios'), 'large');
                throw error;
            })
        );
    }

    @Action(GetFonts)
    public getFonts({ patchState }: StateContext<AppStateModel>) {
        return this.marketService.getFonts().pipe(
            tap((response: any) => {
                if (response) {
                    const fonts = response.items;
                    const fontsFamily = fonts.map((font) => {
                        return font.family;
                    });
                    patchState({
                        fonts: fontsFamily,
                    });
                }
            }),
            catchError((error) => {
                throw error;
            })
        );
    }

    @Action(SetUserName)
    public setUserName({ patchState, getState }: StateContext<AppStateModel>, { userName }: SetUserName) {
        return patchState({
            userName: userName,
        });
    }

    @Action(ShowAppVersionBanner)
    public showAppVersionBanner({ patchState, getState }: StateContext<AppStateModel>, { show }: ShowAppVersionBanner) {
        return patchState({
            showAppVersionBanner: show,
        });
    }

    @Action(SetVerticalList)
    public setVerticalList({ patchState }: StateContext<AppStateModel>, { verticalList }: SetVerticalList) {
        return patchState({ verticalList });
    }
}
