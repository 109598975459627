import { LanguageHelper } from './../../../_shared/helpers/LanguageHelper';
import { TranslateService } from '@ngx-translate/core';
import { StopRequestInProgress } from './../../../core/store/app.actions';
import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment.local';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
    esta_pagina_se_perdio: string;
    buscamos_por_todas_partes: string;
    y_no_la_encontramos: string;
    contactar: string;
    volver: string;
    translates$: string;
    assetsUrl: string = environment.assets_url;
    mfsPath: string = environment.mfs_path;
    constructor(private translate: TranslateService, private languageHelper: LanguageHelper) {}

    ngOnInit(): void {
        this.setTranslations();
    }

    showLiveChat() {
        (<any>window).LiveChatWidget.call('maximize');
    }

    setTranslations() {
        this.translate.setDefaultLang(this.languageHelper.getLanguage());
        this.translate.use(this.languageHelper.getLanguage());
        this.translate.get('app.errores').subscribe((res) => {
            this.translates$ = res;
            this.esta_pagina_se_perdio = res.esta_pagina_se_perdio;
            this.buscamos_por_todas_partes = res.buscamos_por_todas_partes;
            this.y_no_la_encontramos = res.si_sigues_sin_encontrar;
            this.contactar = res.contactar;
            this.volver = res.volver_al_inicio;
        });
    }
}
