import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from '../api.service';
import { CommomHeaders } from '@app/_shared/httpHeaders/CommonHeaders';
import { Domain } from '@app/_models/pages/domain.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class DomainsService {
    constructor(private api: ApiService, private headers: CommomHeaders, private http: HttpClient) {}

    getAllDomains(): Observable<any> {
        return this.api.getFz(`/api/v1/back/domains/listing`, this.headers.getAuthHeaders());
    }

    createDomain(domain: string): Observable<any> {
        return this.api.postFz(`/api/v1/back/domains/register`, { domain }, this.headers.getAuthHeaders());
    }

    addDomain(domain: string): Observable<any> {
        return this.api.postFz(
            `/api/v1/back/domains/parking`,
            { domain, service: 'TS' },
            this.headers.getAuthHeaders()
        );
    }

    getOwnDomains(page?, size?): Observable<any> {
        if (page) {
            return this.api.getFz(`/api/v1/back/domains?page=${page}&per_page=${size}`, this.headers.getAuthHeaders());
        } else {
            return this.api.getFz(`/api/v1/back/domains`, this.headers.getAuthHeaders());
        }
    }

    selectedParkDomain(domain: string): Observable<any> {
        return this.api.patch(`/api/v1/back/domains/default`, { domain }, this.headers.getAuthHeaders());
    }

    changeService(domain: Domain, service: string) {
        return this.api.postFz('/api/v1/back/domains/change-service', { domain: domain.description, service });
    }

    deleteParkedDomain(domain: Domain) {
        return this.api.postFz('/api/v1/back/domains/unparking', {
            domain: domain.description,
            service: domain.service ? domain.service.toUpperCase() : 'TS',
        });
    }

    checkAvailability(domain: string): Observable<any> {
        return this.api.post('api/v1/back/domains/consult-available', { domain }, this.headers.getAuthHeaders());
    }

    checkStateDomain(marketName: string, domain: string) {
        return this.api.get(`https://sitiosimple.com/${marketName}/fronttienda/pingdominio/dominio/${domain}/`);
    }

    getStatus(domain: string) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'image/gif');
        return this.http.get(`https://valerymayorista.website`, { headers });
    }
}
