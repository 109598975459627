import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logout, SetGlobalLoading, SetHideSidebar, SetIsLogged, SetUserName } from '@app/core/store/app.actions';
import { CookieData, CookieSessionData, TokensResponse, UserData } from '@app/_models/users/user';
import { ApiService } from '@app/_services/api.service';
import { environment } from '@env/environment.local';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserHelper {
    constructor(private store: Store, private apiService: ApiService, private activeRoute: ActivatedRoute) {}

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json, text/javascript, */*; q=0.01',
        }),
        withCredentials: true,
    };

    removeUserData(): boolean {
        this.getDataScope().removeItem('userData');
        this.removePagesLang();

        return true;
    }

    storageUserData(data: UserData) {
        this.store.dispatch(new SetUserName(data.userName));
        const rememberMe: boolean = data?.rememberMe;
        const storage = rememberMe ? localStorage : sessionStorage;
        storage.setItem(
            'userData',
            JSON.stringify({
                token: data?.token,
                rememberMe: rememberMe,
                userName: data?.userName,
            })
        );
    }

    getUserData(): UserData {
        const userDataInLocalStorage: UserData | null = JSON.parse(localStorage.getItem('userData'));
        const userDataInSessionStorage: UserData | null = JSON.parse(sessionStorage.getItem('userData'));
        return userDataInLocalStorage != null ? userDataInLocalStorage : userDataInSessionStorage;
    }

    getTokenFromParams(): string | null {
        const url_string = window.location.href;
        const url = new URL(url_string);
        return url.searchParams.get('token');
    }

    getDataScope(): any {
        return this.getRememberMe() ? localStorage : sessionStorage;
    }

    removePersistFlag(): void {
        localStorage.removeItem('persistSession');
    }

    removeMarketId(): void {
        sessionStorage.removeItem('marketId');
    }

    removePagesLang(): void {
        localStorage.removeItem('pagesLang');
    }

    logout(toNewSite?: boolean): void {
        this.store.dispatch([
            new SetGlobalLoading(true),
            new SetHideSidebar(true),
            new SetIsLogged(false),
            new Logout(),
        ]);
        this.removeUserData();
        this.removePersistFlag();
        this.removeMarketId();
        const hostName = localStorage.getItem('hostName');
        const destroyedSession = true;
        window.location.href = !toNewSite
            ? `${environment.authPath}/login?destroyedSession=${destroyedSession}&host=${hostName}`
            : `https://sitiosimple.donweb.com/es-ar/identificacion`;
    }

    refreshToken(refreshToken: string): Observable<any> {
        const userData: UserData = this.getUserData();
        const tokens: TokensResponse = {
            token: userData.token,
            refreshToken: refreshToken,
        };
        return this.apiService.postFz(`/api/refreshtoken`, tokens);
    }
    createJWTCookie(data) {
        return this.apiService.postFz(`/api/cookie`, data, this.httpOptions).subscribe();
    }

    createJWTSessionCookie(data) {
        return this.apiService.postFz(`/api/sessioncookie`, data, this.httpOptions).subscribe();
    }

    getJWTCookie() {
        return this.apiService.getFz(`/api/cookie`, this.httpOptions);
    }

    getJWTSessionCookie() {
        return this.apiService.getFz(`/api/cookiesession`, this.httpOptions);
    }

    getJWTSessionCookieTest() {
        this.apiService.getFz(`/api/cookiesession`, this.httpOptions).subscribe();
    }

    setJWTCookie(cookieData: CookieData) {
        this.apiService
            .getFz(`/api/setcookie?value=${cookieData.value}&rememberMe=${cookieData.rememberMe}`, this.httpOptions)
            .subscribe();
    }

    setJWTSessionCookie(cookieData: CookieSessionData) {
        this.apiService
            .getFz(`/api/setauthcookie?value=${cookieData.token}&rememberMe=${cookieData.rememberMe}`, this.httpOptions)
            .subscribe();
    }

    deleteJWTCookie() {
        return this.apiService.get(`/api/deletecookie`, this.httpOptions);
    }

    checkToken() {
        return this.apiService.get(`/api/v1/back/checktoken`, this.httpOptions);
    }

    getUserName = (): string => this.getUserData()?.userName || '';

    setUserName = (userName: string) => {
        const userData: UserData = this.getUserData();
        userData.userName = userName;
        this.storageUserData(userData);
        this.store.dispatch(new SetUserName(userName));
    };

    getRememberMe = (): boolean => this.getUserData()?.rememberMe || false;
}
