<div class="mb-0" [formGroup]="form">
    <div [ngStyle]="style">
        <ng-container *ngIf="type === 'radio'">
            <div class="form-check" [class.form-check-inline]="inline" *ngFor="let option of options">
                <label class="form-check-label">
                    <input
                        class="form-check-input"
                        type="radio"
                        [formControlName]="name"
                        [name]="name"
                        [value]="option"
                        (input)="onChangeValue($event.target.value)"
                    />
                    {{ option.label }}
                </label>
            </div>
        </ng-container>

        <ng-container *ngIf="type === 'checkbox'">
            <div class="form-check check-container d-flex">
                <label class="d-flex form-check-label align-items-center">
                    <small *ngIf="checkboxLabel" class="mr-3">{{ checkboxLabel }}</small>
                    <div class="switch" [ngbTooltip]="tooltipText" [placement]="tooltipPlacement">
                        <input
                            class="primary"
                            type="checkbox"
                            [formControlName]="name"
                            [id]="id"
                            (input)="onChangeValue($event.target.value)"
                        />
                        <span class="slider round"></span>
                    </div>
                    <ng-content select="[checkbox-content]"></ng-content>
                </label>
            </div>
        </ng-container>
    </div>
    <small class="form-text text-muted" *ngIf="formText" [innerHTML]="formTextContent"></small>
</div>
