import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

declare var window: any;

@Injectable({
    providedIn: 'root',
})
export class McRouter {
    adminUrl: RegExp = /^\/(\w){2}-(\w){2,3}\//;
    loginRegExp: RegExp = /^\/(\w){2}-(\w){2,3}\/ingresar/;
    lpLogoutRegExp: RegExp = /^\/(\w){2}-(\w){2,3}\/lp-salida/;

    constructor(private route: ActivatedRoute, private router: Router) {}

    public navigate(
        url: string,
        options?: { externalLink: boolean; target?: '_blank' | '_self' }
    ): Promise<any> | void {
        const target = (options && options.target) || '_self';
        const forceExternalLink = (options && options.externalLink) || false;

        if (this.isRouterLink(url) && !forceExternalLink) {
            return this.router.navigateByUrl(url);
        } else {
            window.open(url, target);
        }
    }

    private isRouterLink(url: string): boolean {
        return this.adminUrl.test(url) && !this.lpLogoutRegExp.test(url);
    }

    saveCurrentUrlAndGoToLogin(adminPrefix: string) {
        const currentUrl = this.router.url.split('?')[0];
        const isLoginUrl = this.loginRegExp.test(currentUrl);
        if (!isLoginUrl && currentUrl == '/') {
            this.router
                .navigate([`/${adminPrefix}/ingresar`])
                .then(() => {})
                .catch(() => {});
        }
    }
}
