import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { GetGeneralInfoApp } from '@app/core/store/app.actions';
import { AppState } from '@app/core/store/app.state';
import { Store } from '@ngxs/store';
import { lastValueFrom, Observable } from 'rxjs';

@Injectable()
export class SalesStatsGuard implements CanActivate {
    constructor(private router: Router, private store: Store) {}

    private getData(): Observable<any> {
        return this.store.dispatch(new GetGeneralInfoApp());
    }

    async canActivate(): Promise<boolean> {
        const services = this.store.selectSnapshot(AppState.getServicesList);
        if (services?.length) {
            return this.verifyServices(services);
        } else {
            const query = await lastValueFrom(this.getData());
            const services = query.Sidebar.initialInfo.data.services.map(({ id }) => id);
            return this.verifyServices(services);
        }
    }

    verifyServices(services: number[]): boolean {
        if (services.includes(2)) {
            return true;
        } else {
            this.router.navigate([`/admin/statistics/visits`]);
            return false;
        }
    }
}
