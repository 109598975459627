<p class="text-label mb-1" *ngIf="showRecommendationMessage">
    {{ 'app.file_upload.el_archivo_debe_pesar' | translate }}
</p>
<p class="text-label mb-2" *ngIf="isFavicon">Es el icono que identifica a tu sitio en la pestaña del navegador.</p>

<div
    [ngClass]="
        errors['maxSize'] || errors['validExtension'] || errors['serverError']
            ? 'image-container d-flex justify-content-center align-items-center w-100 border-danger'
            : 'image-container d-flex justify-content-center align-items-center w-100'
    "
>
    <div
        class="d-flex justify-content-center align-items-center w-100 h-100"
        (click)="openFileUploadWindow(fileUpload)"
    >
        <div
            *ngIf="!urlLogo && !logoLoaded; else elseBlock"
            id="upload-icon"
            class="d-flex flex-column justify-content-center align-items-center"
        >
            <i class="fal fa-upload mb-1 color-texto"></i>
            <p class="m-0 mt-1 color-texto text-center">{{ 'app.file_upload.selecciona' | translate }}</p>
        </div>
        <ng-template #elseBlock>
            <img
                [src]="urlLogo ? urlLogo : logoLoaded"
                id="without-logo"
                [className]="applyFaviconClass ? 'favico' : ''"
            />
        </ng-template>
        <input
            type="file"
            class="file-input"
            accept="image/png, image/bmp, image/jpeg"
            (change)="onFileSelected($event)"
            #fileUpload
        />
    </div>
    <div [ngClass]="!urlLogo && !logoLoaded ? 'd-none' : 'buttons-container'" class="flex-column m-auto">
        <button
            *ngIf="showReplaceBtn"
            class="btn add-logo-btn mb-1 color-white bg-color-secundario w-100"
            (click)="replaceLogo(fileUpload)"
        >
            {{ 'app.file_upload.reemplazar' | translate }}
        </button>
        <button
            *ngIf="showDeleteBtn"
            class="btn delete-logo-btn mt-1 color-secundario bg-color-white w-100"
            (click)="deleteLogo()"
        >
            {{ 'app.file_upload.eliminar' | translate }}
        </button>
    </div>
</div>
<div class="messages-container mt-1 mb-3">
    <p class="upload-message text-danger" *ngIf="errors['validExtension']">
        {{ 'app.file_upload.error_formato' | translate }}
    </p>
    <p class="upload-message text-danger position-relative" *ngIf="errors['maxSize'] && !errors['validExtension']">
        {{ 'app.file_upload.error_tamaño' | translate : { errorElementName: errorElementName } }}
    </p>
    <p class="upload-message text-danger position-relative" *ngIf="errors['serverError']">
        {{ 'app.file_upload.error_dimensiones' | translate }}
    </p>
</div>
