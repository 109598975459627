import { Component, Input } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { flyInOut } from '../../_animations';

@Component({
    selector: 'mc-toast',
    templateUrl: './mc-toast.component.html',
    animations: [flyInOut],
})
export class McToastComponent extends Toast {
    messageConverted = this.message.split('\n');

    @Input() type: 'success' | 'danger' | 'warning' | 'info';
    @Input() messageLength: 'short' | 'medium' | 'large';

    constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
        super(toastrService, toastPackage);
    }

    getIconName() {
        let icon;
        switch (this.type) {
            case 'success':
                icon = 'check-circle';
                break;

            case 'danger':
                icon = 'times-circle';
                break;

            case 'warning':
                icon = 'triangle-exclamation';
                break;

            case 'info':
                icon = 'circle-info';
                break;

            default:
                icon = '';
                break;
        }

        return icon;
    }
}
