import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

const componentName = 'MatPaginatorIntlService';

@Injectable()
export class MatPaginationIntlService extends MatPaginatorIntl {
    constructor(private translateService: TranslateService) {
        super();

        this.translateService.onLangChange.subscribe((_event: Event) => {
            this.translateLabels();
        });

        this.translateLabels();
    }

    getRangeLabel = (page: number, pageSize: number, length: number): string => {
        const of = this.translateService ? this.translateService.instant('app.paginator.OF') : 'of';
        if (length === 0 || pageSize === 0) {
            return '0 ' + of + ' ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;

        const endIndex = Math.min(startIndex + pageSize, length);
        return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
    };

    injectTranslateService(translate: TranslateService): void {
        this.translateService = translate;

        this.translateService.onLangChange.subscribe(() => {
            this.translateLabels();
        });

        this.translateLabels();
    }

    translateLabels(): void {
        this.firstPageLabel = this.translateService.instant('app.paginator.FIRST_PAGE');
        this.itemsPerPageLabel = this.translateService.instant('app.paginator.ITEMS_PER_PAGE');
        this.lastPageLabel = this.translateService.instant('app.paginator.LAST_PAGE');
        this.nextPageLabel = this.translateService.instant('app.paginator.NEXT_PAGE');
        this.previousPageLabel = this.translateService.instant('app.paginator.PREVIOUS_PAGE');
        this.changes.next();
    }
}
