import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { CommomHeaders } from '@app/_shared/httpHeaders/CommonHeaders';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    constructor(private http: HttpClient, private api: ApiService, private headers: CommomHeaders) {}

    sendResetData(data: any) {
        return this.api.delete(`/api/v1/back/wizard/reset`, data, this.headers.getAuthHeaders());
    }
    getBasicInfo(): Observable<any> {
        return this.api.getFz(`/api/v1/back/config`, this.headers.getAuthHeaders());
    }
    getPrivacyPolicy(): Observable<any> {
        return this.api.getFz(`/api/v1/back/config/privacy-policy`, this.headers.getAuthHeaders());
    }
    updateBasicInfo(data): Observable<any> {
        return this.api.postFz(`/api/v1/back/config/main`, data, this.headers.getHeaderImage());
    }
    updateContactInfo(data): Observable<any> {
        return this.api.put(`/api/v1/back/config/contact-info`, data, this.headers.getHeaderImage());
    }
    updatePrivacyInfo(data): Observable<any> {
        return this.api.put(`/api/v1/back/config/privacy-policy`, data, this.headers.getAuthHeaders());
    }
    setWhatsappData(data): Observable<any> {
        return this.api.put(`/api/v1/back/config/whatsapp-button`, data, this.headers.getAuthHeaders());
    }

    /////////////////Page Languages section//////////////////
    getPagesLanguages(): Observable<any> {
        return this.api.getFz(`/api/v1/back/languages`, this.headers.getAuthHeaders());
    }

    getAvailableLanguages(): Observable<any> {
        return this.api.getFz(`/api/v1/back/languages/availables`, this.headers.getAuthHeaders());
    }

    addPageLanguage(id) {
        return this.api.postFz(`/api/v1/back/languages`, id, this.headers.getAuthHeaders());
    }

    deletePageLanguage(id) {
        return this.api.delete(`/api/v1/back/languages/${id}`, this.headers.getAuthHeaders());
    }

    setDefaultLanguage(id) {
        return this.api.patch(`/api/v1/back/languages/${id}/set-default`, this.headers.getAuthHeaders());
    }

    setLanguageVisibility(id, visible: boolean) {
        return this.api.patch(
            `/api/v1/back/languages/${id}/set-visibility`,
            { visible: visible },
            this.headers.getAuthHeaders()
        );
    }
}
