<div class="card d-md-none mb-3">
    <div class="card-body py-3 d-flex align-items-center justify-content-between pr-0">
        <div class="d-flex align-items-center" *ngIf="info" [innerHTML]="info" [ngStyle]="{ 'max-width': '60%' }"></div>
        <ng-container *ngFor="let navItem of nav">
            <div *ngIf="navItem.show" [routerLink]="['./', navItem.link]">
                <div class="d-none color-secundario" [routerLinkActive]="'d-block'">
                    <mc-icon
                        prefix="far"
                        name="{{ navItem.icon }}"
                        size="lg"
                        *ngIf="navItem.icon"
                        [fixedWidth]="true"
                        class="mr-2"
                    >
                    </mc-icon>
                    <span *ngIf="navItem.link === 'perfil'">Contraseña</span>
                    <span *ngIf="navItem.link !== 'perfil'">{{ navItem.label }}</span>
                </div>
            </div>
        </ng-container>
        <a href="#" class="ml-auto px-3" (click)="toggleOpen()" preventDefault>
            <mc-icon prefix="fas" name="ellipsis-v" size="lg"></mc-icon>
        </a>
    </div>
</div>
<div class="mc-overlay d-md-none" *ngIf="open" (click)="toggleOpen()" [ngStyle]="{ 'z-index': '1000' }"></div>
<div class="contextual-menu" [class.contextual-menu--collapsed]="!open">
    <ul class="list-unstyled" mc-sticky>
        <ng-container *ngFor="let navItem of nav">
            <li *ngIf="navItem.show" [routerLink]="['./', navItem.link]">
                <a [routerLink]="['./', navItem.link]" [routerLinkActive]="'disabled'">
                    <mc-icon
                        prefix="far"
                        name="{{ navItem.icon }}"
                        size="lg"
                        *ngIf="navItem.icon"
                        [fixedWidth]="true"
                        class="mr-2"
                    >
                    </mc-icon>
                    <span>{{ navItem.label }}</span>
                </a>
                <div class="badge badge-primary ml-2" *ngIf="navItem.new">Nuevo</div>
            </li>
        </ng-container>
    </ul>
</div>
