import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-ss-button',
    templateUrl: './ss-button.component.html',
    styleUrls: ['./ss-button.component.scss'],
})
export class SsButtonComponent {
    @Input() loading: boolean = false;
    @Input() text: string;
    @Input() id: string;
    @Input() class: string = 'btn btn-continue';
    @Input() disabled: boolean = false;
    @Input() loadingColor: string = 'text-white  mr-2';

    constructor() {}
}
