import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-can-exit-modal',
    templateUrl: './can-exit-modal.component.html',
    styleUrls: ['./can-exit-modal.component.scss'],
})
export class CanExitModalComponent {
    constructor(public dialogRef: MatDialogRef<CanExitModalComponent>, public dialog: MatDialog) {}

    exit() {
        this.dialogRef.close(true);
    }

    save() {
        this.dialogRef.close(false);
    }
}
