import { Component, Input, OnInit } from '@angular/core';
import { SidebarState } from '@app/core/sidebar/store/sidebar.state';
import { AppState } from '@app/core/store/app.state';
import { SidebarMenuItem } from '@app/_interfaces';
import { McToastService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-trial-expired',
    templateUrl: './trial-expired.component.html',
    styleUrls: ['./trial-expired.component.scss'],
})
export class TrialExpiredComponent implements OnInit {
    sideBarWidth: number;
    expiredAt: string = '';
    alertHeight: number;

    title = new Map<string, string>([
        ['expired', this.translate.instant('app.global.site_info_banner.expired.title')],
        ['disabled', this.translate.instant('app.global.site_info_banner.disabled.title')],
        ['deleted', this.translate.instant('app.global.site_info_banner.deleted.title')],
    ]);
    subtitle = new Map<string, string>([
        ['disabled', this.translate.instant('app.global.site_info_banner.disabled.subtitle')],
        ['deleted', this.translate.instant('app.global.site_info_banner.deleted.subtitle')],
    ]);
    content = new Map<string, string>([
        ['expired', this.translate.instant('app.global.site_info_banner.expired.content')],
        ['disabled', this.translate.instant('app.global.site_info_banner.disabled.content')],
        ['deleted', this.translate.instant('app.global.site_info_banner.deleted.content')],
    ]);
    ctaText = new Map<string, string>([
        ['expired', this.translate.instant('app.global.site_info_banner.expired.cta_text')],
        ['disabled', this.translate.instant('app.global.site_info_banner.disabled.cta_text')],
        ['deleted', this.translate.instant('app.global.site_info_banner.deleted.cta_text')],
    ]);

    @Input() remainingDemoDays: number;
    @Input() bannerType: 'expired' | 'disabled' | 'deleted';

    @Select(AppState.getConfig)
    config$: Observable<{}>;
    @Select(SidebarState.menu)
    menuItems$: Observable<SidebarMenuItem[]>;
    @Select(SidebarState.collapsed)
    isCollapsed$: Observable<boolean>;

    constructor(public mcToast: McToastService, private translate: TranslateService, private store: Store) {}

    ngOnInit(): void {
        this.menuItems$.subscribe((items) => {
            if (items.length) {
                let sidebar = document.querySelector('.mc-sidebar__profile');
                this.sideBarWidth = sidebar?.clientWidth;
            }
        });
        this.isCollapsed$.subscribe(() => {
            setTimeout(() => {
                let sidebar = document.querySelector('.mc-sidebar__profile');
                if (sidebar) this.sideBarWidth = sidebar?.clientWidth;
            }, 50);
        });
        this.config$.subscribe(() => {
            this.setAlertHeight();
            this.setExpiredAt();
        });
    }

    setExpiredAt() {
        const iso = this.store.selectSnapshot(AppState.getMarketLanguage).iso.replace('_', '-');
        const remainingDaysInMiliseconds = this.remainingDemoDays * 24 * 60 * 60 * 1000;
        const todayInMiliseconds = new Date().getTime();
        const expiredDate = new Date(todayInMiliseconds + remainingDaysInMiliseconds).toLocaleString(iso, {
            day: 'numeric',
            month: 'long',
        });
        this.subtitle.set(
            'expired',
            `${this.translate.instant('app.global.site_info_banner.expired.subtitle')} ${expiredDate}`
        );
    }

    setAlertHeight() {
        setTimeout(() => {
            this.alertHeight = document.querySelector('.trial-container').clientHeight;
        }, 100);
    }
}
