import { Directive, ElementRef, Input, HostListener, Renderer2 } from '@angular/core';

declare var $: any;

@Directive({
    selector: '[mc-sticky]',
})
export class StickyDirective {
    private sticked: boolean = true;
    private selectedOffset: number = 0;
    private windowOffsetTop: number = 0;

    @Input() addClass: string = 'fixed';
    @Input() offSet: number = 0;
    @Input() fullWidth: boolean;

    constructor(private el: ElementRef, private render: Renderer2) {
        this.selectedOffset = this.el.nativeElement.offsetTop;
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        this.setSticky();
    }

    @HostListener('window:resize', ['$event']) onResize(event: Event) {
        if (this.sticked) {
            this.setWidth();
        }
    }

    setSticky() {
        this.windowOffsetTop = window.pageYOffset || 0;

        if (this.windowOffsetTop > this.offSet) {
            this.addSticky();
        } else {
            this.removeSticky();
        }
    }

    private addSticky() {
        this.sticked = true;
        this.render.addClass(this.el.nativeElement, this.addClass);

        if (this.fullWidth) {
            this.setWidth();
        }
    }

    private removeSticky() {
        this.sticked = false;
        this.render.removeClass(this.el.nativeElement, this.addClass);

        if (this.fullWidth) {
            this.unsetWidth();
        }
    }

    private setWidth() {
        const parentWidth = $(this.el.nativeElement).parent().width();
        const borderWidth =
            parseInt($(this.el.nativeElement).css('border-left-width')) +
            parseInt($(this.el.nativeElement).css('border-right-width'));
        $(this.el.nativeElement).width(parentWidth - borderWidth);
    }

    private unsetWidth() {
        $(this.el.nativeElement).width('');
    }
}
