<div class="col-12 p-3 p-lg-0 container-form-modal-pages">
    <div>
        <p class="design-modal-title">{{ 'app.global.modals.exit-modal-title' | translate }}</p>
    </div>
    <div class="mt-2">
        <p class="description">{{ 'app.global.modals.exit-modal-subtitle' | translate }}</p>
    </div>
    <div class="d-flex justify-content-between justify-content-sm-end mt-3 mt-lg-4">
        <button class="btn btn-skip mr-sm-4" (click)="exit()">
            {{ 'app.global.modals.exit-modal-btn-close' | translate }}
        </button>
        <button class="btn btn-continue" (click)="save()">
            {{ 'app.global.modals.exit-modal-btn-review' | translate }}
        </button>
    </div>
</div>
