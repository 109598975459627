import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { NavItemInterface } from '@app/_interfaces';
import {
    CreateContactList,
    GetEnvialoSimpleConfig,
    GetEnvialoSimpleMailList,
    ManualSync,
    SetEnvialoSimpleConfig,
    SetIntegrationsNav,
    SetLoadingFormSubmitIntegrationAction,
    ToogleExecuteSubmit,
} from '@app/pages/tools/integrations/store/integration.actions';
import { TranslateService } from '@ngx-translate/core';
import { ConectionData, MailListItem } from '@app/_models/integrations/envialo-simple.model';
import { McToastService } from '@app/_services';
import { EnvialoSimpleService } from '@app/_services/integrations/envialo_simple.service';
import { catchError, tap } from 'rxjs';

interface IntegrationStateModel {
    nav: NavItemInterface[];
    executeAction: boolean;
    loadingFormSubmit: boolean;
    envialoSimpleConfig: ConectionData;
    validEnvialoSimpleApiKey: boolean;
    envialoSimpleMailList: MailListItem[];
    manualSyncFormSubmit: boolean;
}

@State<IntegrationStateModel>({
    name: 'integration',
    defaults: {
        nav: [],
        executeAction: false,
        loadingFormSubmit: false,
        envialoSimpleConfig: null,
        envialoSimpleMailList: null,
        validEnvialoSimpleApiKey: false,
        manualSyncFormSubmit: false,
    },
})
@Injectable()
export class IntegrationState {
    constructor(
        private envialoSimpleService: EnvialoSimpleService,
        private toast: McToastService,
        private translate: TranslateService,
        private store: Store
    ) {}

    @Selector()
    public static getState(state: IntegrationStateModel) {
        return state;
    }
    @Selector()
    public static getLoadingFormSubmit(state: IntegrationStateModel) {
        return state.loadingFormSubmit;
    }
    @Selector()
    public static nav(state: IntegrationStateModel) {
        return state.nav;
    }

    @Selector()
    public static executeAction(state: IntegrationStateModel) {
        return state.executeAction;
    }

    @Selector()
    public static envialoSimpleConfig(state: IntegrationStateModel) {
        return state.envialoSimpleConfig;
    }

    @Selector()
    public static envialoSimpleMailList(state: IntegrationStateModel) {
        return state.envialoSimpleMailList;
    }

    @Selector()
    public static validEnvialoSimpleApiKey(state: IntegrationStateModel) {
        return state.validEnvialoSimpleApiKey;
    }

    @Selector()
    public static manualSyncFormSubmit(state: IntegrationStateModel) {
        return state.manualSyncFormSubmit;
    }

    @Action(SetIntegrationsNav)
    public setNav({ patchState }: StateContext<IntegrationStateModel>) {
        return patchState({
            nav: [
                {
                    label: this.translate.instant('app.nav.custom_code'),
                    link: 'custom-code',
                    icon: 'code',
                    show: true,
                },
            ],
        });
    }

    @Action(ToogleExecuteSubmit)
    public settingsExecuteSubmit(
        { patchState, getState }: StateContext<IntegrationStateModel>,
        { execute }: ToogleExecuteSubmit
    ) {
        patchState({
            executeAction: execute,
        });
    }
    @Action(SetLoadingFormSubmitIntegrationAction)
    public setLoadingFormSubmitIntegration(
        { patchState }: StateContext<IntegrationStateModel>,
        { data }: SetLoadingFormSubmitIntegrationAction
    ) {
        patchState({
            loadingFormSubmit: data,
        });
    }

    @Action(GetEnvialoSimpleConfig)
    public getEnvialoSimpleConfig({ patchState }: StateContext<IntegrationStateModel>) {
        return this.envialoSimpleService.showConection().pipe(
            tap((response) => {
                if (response.status === 'ok' && response.apiKey.length > 0) {
                    patchState({ validEnvialoSimpleApiKey: true });
                    this.store.dispatch(new GetEnvialoSimpleMailList());
                }

                if (response.status === 'error') {
                    patchState({ envialoSimpleMailList: null, validEnvialoSimpleApiKey: false });
                }

                patchState({ envialoSimpleConfig: response });
            }),
            catchError((error) => {
                this.toast.danger(this.translate.instant('app.integrations.envialo_simple.toast.error'));
                throw error;
            })
        );
    }

    @Action(GetEnvialoSimpleMailList)
    public getEnvialoSimpleMailList({ patchState }: StateContext<IntegrationStateModel>) {
        return this.envialoSimpleService.showList().pipe(
            tap((response) => {
                patchState({ envialoSimpleMailList: response });
            }),
            catchError((error) => {
                this.toast.danger(this.translate.instant('app.integrations.envialo_simple.toast.mail_list.error'));
                throw error;
            })
        );
    }

    @Action(CreateContactList)
    public createContactList({ patchState }: StateContext<IntegrationStateModel>, { payload }: CreateContactList) {
        patchState({ loadingFormSubmit: true });
        return this.envialoSimpleService.createList(payload).pipe(
            tap((response) => {
                if (response.data.status === 'ok' && response.data.code === 'msg_contactListCreated') {
                    this.toast.success(
                        this.translate.instant('app.integrations.envialo_simple.dialogs.create_list.toast.success')
                    );
                }

                if (response.data.status === 'error') {
                    switch (response.data.code.name) {
                        case 'is_used':
                            this.toast.warning(
                                this.translate.instant(
                                    'app.integrations.envialo_simple.dialogs.create_list.toast.is_used'
                                )
                            );
                            break;

                        default:
                            this.toast.danger(
                                this.translate.instant(
                                    'app.integrations.envialo_simple.dialogs.create_list.toast.error'
                                )
                            );
                            break;
                    }
                }

                patchState({ loadingFormSubmit: false });
            }),
            catchError((error) => {
                patchState({ loadingFormSubmit: false });
                this.toast.danger(
                    this.translate.instant('app.integrations.envialo_simple.dialogs.create_list.toast.error')
                );
                throw error;
            })
        );
    }

    @Action(SetEnvialoSimpleConfig)
    public setEnvialoSimpleConfig(
        { patchState, getState }: StateContext<IntegrationStateModel>,
        { payload }: SetEnvialoSimpleConfig
    ) {
        patchState({ loadingFormSubmit: true });
        return this.envialoSimpleService.updateConection(payload).pipe(
            tap((response) => {
                if (
                    (response?.data?.msg === 'Unauthorized' || response?.data?.msg === 'invalid_key') &&
                    response?.data?.status === 'error'
                ) {
                    this.toast.danger(
                        this.translate.instant('app.integrations.envialo_simple.form.toast.invalid_api_key')
                    );
                    patchState({ validEnvialoSimpleApiKey: false });
                }

                if (response?.data[0]?.status === 'ok') {
                    patchState({ validEnvialoSimpleApiKey: true });
                    this.toast.success(this.translate.instant('app.integrations.envialo_simple.form.toast.success'));
                    const mails = getState().envialoSimpleMailList;
                    if (!mails?.length) {
                        this.store.dispatch(new GetEnvialoSimpleMailList());
                    }
                }

                patchState({ loadingFormSubmit: false });
            }),
            catchError((error) => {
                patchState({ loadingFormSubmit: false });
                this.toast.danger(this.translate.instant('app.integrations.envialo_simple.form.toast.error'));
                throw error;
            })
        );
    }

    @Action(ManualSync)
    public manualSync({ patchState }: StateContext<IntegrationStateModel>, { payload }: ManualSync) {
        patchState({ manualSyncFormSubmit: true });
        return this.envialoSimpleService.manualSync(payload).pipe(
            tap((response) => {
                if (response.data.status === 'ok') {
                    this.toast.success(
                        this.translate.instant('app.integrations.envialo_simple.dialogs.manual_sync.toast.success')
                    );
                }

                patchState({ manualSyncFormSubmit: false });
            }),
            catchError((error) => {
                patchState({ manualSyncFormSubmit: false });
                this.toast.danger(
                    this.translate.instant('app.integrations.envialo_simple.dialogs.manual_sync.toast.error')
                );
                throw error;
            })
        );
    }
}
