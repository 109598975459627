import { Select, Store } from '@ngxs/store';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppState } from '../core/store/app.state';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { McRouter } from '@app/_services';
import { SetGlobalLoading, SetHideSidebar } from '@app/core/store/app.actions';
import { UserHelper } from '@app/_shared/helpers/UserHelper';
import { environment } from '@env/environment.local';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
    constructor(private mcRouter: McRouter, private store: Store, private userHelper: UserHelper) {}

    @Select(AppState.isLogged)
    isLogged$: Observable<boolean>;

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.checkLogin().pipe(
            switchMap(() => of(true)),
            catchError(() => of(false))
        );
    }

    canLoad(route: Route): Observable<boolean> {
        return this.checkLogin().pipe(
            switchMap(() => of(true)),
            catchError(() => of(false))
        );
    }

    protected checkLogin(): Observable<boolean> {
        const adminPrefix = this.store.selectSnapshot(AppState.adminPrefix);
        return this.isLogged$.pipe(
            tap((isLogged) => {
                const token = this.userHelper.getUserData()?.token;
                if ((isLogged && token === '') || token === undefined) {
                    this.redirectToLogin();
                }
                if (!isLogged) {
                    this.redirectToLogin();
                }
            }),
            take(1)
        );
    }

    redirectToLogin() {
        this.store.dispatch([new SetGlobalLoading(true), new SetHideSidebar(true)]);
        const hostName = localStorage.getItem('hostName');
        var section = '';
        if (localStorage.getItem('App.lastVisit')) {
            var sUrl = localStorage.getItem('App.lastVisit').split('"')[1];
            section = '&section=' + sUrl;
        }
        window.location.href = `${environment.authPath}/login?destroyedSession=true&host=${hostName}${section}`;
    }

    // refreshAuthorizationToken(){
    //     this.userHelper.getJWTSessionCookie().pipe(
    //         switchMap((res) => {
    //             if (res) {
    //                 //extract user data and set local or sessionStorage.
    //                 return this.userHelper.checkToken().pipe(
    //                     switchMap((checkTokenRes) => {
    //                         return this.userHelper.setJWTSessionCookie(dataExample);
    //                     }),
    //                     catchError((error) => {
    //                         this.userHelper.logout();
    //                         return of(error);
    //                     })
    //                 );
    //             }
    //         }),
    //         catchError((error) => {
    //             this.userHelper.logout();
    //             return of(error);
    //         })
    //     );
    // }
}
