export class GetWelcomeData {
    public static readonly type = '[Dashboard] GetWelcomeData';
}

export class SetWelcomeData {
    public static readonly type = '[Dashboard] SetWelcomeData';
    constructor(public data?: any) {}
}

export class SetDashboardStatistics {
    public static readonly type = '[Dashboard] SetDashboardStatistics';
    constructor() {}
}

export class SetDashboardStatisticsConTienda {
    public static readonly type = '[Dashboard] SetDashboardStatisticsConTienda';
    constructor() {}
}

export class GetNotifications {
    public static readonly type = '[Dashboard] GetNotifications';
}

export class DeleteNotification {
    public static readonly type = '[Dashboard] DeleteNotification';
    constructor(public id: number) {}
}

export class ClearAllNotifications {
    public static readonly type = '[Dashboard] ClearAllNotifications';
}

export class AddNotification {
    public static readonly type = '[Dashboard] AddNotification';
    constructor(public notification?: any) {}
}

export class SaveToken {
    public static readonly type = '[Dashboard] SaveToken';
    constructor(public token: string) {}
}

export class GetThumbnail {
    public static readonly type = '[Dashboard] GetThumbnail';
}
