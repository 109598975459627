<div
    class="overlay-loading d-flex flex-column align-items-center justify-content-center"
    *ngIf="overlay"
    [class.overlay-loading--fixed]="fixed"
>
    <template [ngTemplateOutlet]="loadingRef"></template>
    <h6 *ngIf="label" class="mb-0 mt-3 text-center" [innerHTML]="label"></h6>
</div>

<ng-container *ngIf="!overlay">
    <template [ngTemplateOutlet]="loadingRef"></template>
    <span class="ml-2" *ngIf="label">{{ label }}</span>
</ng-container>

<ng-template #loadingRef>
    <span
        class="spinner-{{ type }}"
        [ngClass]="color"
        [class.spinner-border-sm]="type === 'border' && size === 'sm'"
        [class.spinner-grow-sm]="type === 'grow' && size === 'sm'"
    ></span>
</ng-template>
