import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { CommomHeaders } from '@app/_shared/httpHeaders/CommonHeaders';
import { StatisticsConTiendaResponse, SummaryConTienda } from '@app/_models/statistics/statistics.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    constructor(private http: HttpClient, private apiService: ApiService, private headers: CommomHeaders) {}

    getWelcomeData() {
        return this.apiService.getFz(`/api/v1/back/dashboard`, this.headers.getAuthHeaders());
        //return WelcomeData;
    }
    getStatistics() {
        return this.apiService.getFz(`/api/v1/back/analytics/get-dashboard`, this.headers.getAuthHeaders());
    }

    getThumbnail() {
        return this.apiService.getFz<Blob>(`/api/v1/back/images/getSiteThumbnail`, {
            observe: 'response',
            headers: this.headers.getHeaderImageV2(),
            responseType: 'blob' as 'json',
        });
    }

    getStatisticsConTienda(): Observable<SummaryConTienda> {
        return this.apiService
            .getFz<StatisticsConTiendaResponse>(
                `/api/v1/back/analytics/get-metrics/sales/month/today`,
                this.headers.getAuthHeaders()
            )
            .pipe(map((response) => response?.data?.sales[0]?.summary));
    }
}
