import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'mc-form-group-input',
    templateUrl: './mc-form-group-input.component.html',
    styleUrls: ['./mc-form-group-input.component.css'],
})
export class McFormGroupInputComponent implements OnInit, OnChanges {
    @Input() form: UntypedFormGroup;
    @Input() type: 'text' | 'number' | 'email' | 'password' = 'text';
    @Input() size: 'sm' | 'lg';
    @Input() label: string;
    @Input() name: string;
    @Input() placeholder = '';
    @Input() formText = '';
    @Input() patternValidationMessage = '';
    @Input() submitted: boolean;
    @Input() focus: boolean;
    @Input() autocomplete: 'on' | 'off' = 'on';
    @Input() formGroupClass: '';
    @Input() formControlClass: '';
    @Input() icon: string;
    @Input() iconPrefix: string;
    @Input() disabled: boolean = false;
    @Input() negativeNumber: boolean = false;
    @Input() hasNegativeValue: boolean = false;
    @Input() tooltipText: string;
    @Input() inputDescription: string;
    @Input() tooltipCustomClass: string;
    @Input() numericCodeNumber: string;
    @Input() currencyString: string;
    @Input() sublabel: string;
    @Input() trigger: string = 'click';
    @Input() priceError: boolean = false;
    @Input() stockError: boolean = false;
    @Input() variantPriceError: boolean = false;
    /*** Cupones ***/
    @Input() descuentoPorcentaje: boolean;
    @Input() importeCompra: boolean = false;
    @Input() cantidadUsos: boolean = false;
    @Input() labelClass: string = '';
    @Output() emmitFocusOut: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() labelAction: EventEmitter<boolean> = new EventEmitter<boolean>();
    /*** Dimensiones logo ***/
    @Input() dimensionLogo: boolean;

    pending: boolean;
    pendingSubscription: Subscription;
    value: string;
    hasChange: boolean;
    isPassword: boolean;
    @Input() negativeValue: boolean = false;

    paddingNumericCode: string = '.75rem';

    numberMaxErrorMsg: string = 'Menor o igual a ';

    constructor(private sanitizer: DomSanitizer, private translateService: TranslateService) {
        this.iconPrefix = 'fas';
    }

    ngOnInit() {
        this.isPassword = this.type === 'password';

        this.pendingSubscription = this.control.statusChanges.subscribe((status) => {
            this.pending = status === 'PENDING' || false;
        });
        this.setPaddingNumericCode(this.numericCodeNumber);
        this.setPaddingNumericCode(this.currencyString);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.numericCodeNumber) this.setPaddingNumericCode(this.numericCodeNumber);
        if (changes.currencyString) this.setPaddingNumericCode(this.currencyString);
        if (changes.priceError) this.numberMaxErrorMsg = 'El valor máximo permitido para el precio es: ';
        if (changes.stockError) this.numberMaxErrorMsg = 'El valor máximo permitido para el stock es: ';
        if (changes.variantPriceError) {
            this.numberMaxErrorMsg = 'El valor máximo permitido para el precio es: ';
            this.control.addValidators(Validators.max(99999999999.99));
            this.control.updateValueAndValidity();
        }

        if (changes.descuentoPorcentaje) {
            this.control.clearValidators();
            this.control.updateValueAndValidity();
            if (this.descuentoPorcentaje) {
                this.numberMaxErrorMsg = 'El rango permitido va de 0% a ';
                this.control.setValidators([Validators.max(100.0), Validators.min(0), Validators.required]);
                this.control.updateValueAndValidity();
            } else {
                this.numberMaxErrorMsg = 'El valor máximo permitido es: ';
                this.control.setValidators([Validators.max(99999999999), Validators.required]);
                this.control.updateValueAndValidity();
            }
        }

        if (changes.importeCompra) {
            this.numberMaxErrorMsg = 'El rango permitido va de 0 a ';
            this.control.addValidators(Validators.max(99999999999));
            this.control.addValidators(Validators.min(0));
            this.control.updateValueAndValidity();
        }

        if (changes.cantidadUsos) {
            this.numberMaxErrorMsg = 'El rango permitido va de 0 a ';
            this.control.addValidators(Validators.max(99999999999));
            this.control.addValidators(Validators.min(0));
            this.control.updateValueAndValidity();
        }

        if (changes.dimensionLogo) {
            this.numberMaxErrorMsg = this.translateService.instant(
                'app.diseno.header_customization.custom_logo.maxErrorMsg'
            );
        }
    }

    isPending(): Observable<boolean> {
        return this.control.statusChanges.pipe(map((status) => status === 'PENDING'));
    }

    get control(): AbstractControl {
        return this.form.get(this.name);
    }

    get formTextContent() {
        return this.sanitizer.bypassSecurityTrustHtml(this.formText);
    }

    toggleTypePassword() {
        this.type = this.type === 'password' ? 'text' : 'password';
    }

    onChangeValue(value: string) {
        this.value = value;
        if (parseFloat(value) < 0) {
            this.hasNegativeValue = true;
        } else {
            this.hasNegativeValue = false;
        }
        this.hasChange = true;
    }

    setPaddingNumericCode(prefix) {
        const elem = prefix;
        if (elem) {
            if (elem.length === 1) this.paddingNumericCode = '2rem';
            if (elem.length === 2) this.paddingNumericCode = '2.5rem';
            if (elem.length === 3) this.paddingNumericCode = '3rem';
        }
    }

    focusOut(event) {
        this.emmitFocusOut.emit(true);
    }

    lavelEvent(): void {
        this.labelAction.emit(true);
    }

    onWheelEvent(event: WheelEvent): void {
        event.preventDefault();
    }
}
