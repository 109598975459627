export class GetContactData {
    public static readonly type = '[ContactData] GetContactData';
    constructor() {}
}
export class SetContactData {
    public static readonly type = '[ContactData] SetContactData';
    constructor(public contactData: any) {}
}
export class SetWhatsappButton {
    public static readonly type = '[ContactData] SetWhatsappButton';
    constructor(public whatsappButton: any) {}
}

export class SetCountryCodePhoneNumber {
    public static readonly type = '[marketActions] SetCountryCode';
    constructor(public param: any) {}
}
export class SetCountryCodeWhatSapp {
    public static readonly type = '[marketActions] SetCountryCodeWp';
    constructor(public param: any) {}
}
export class SetCountryCodeWhatSappBusiness {
    public static readonly type = '[marketActions] SetCountryCodeWhatSappBusiness';
    constructor(public param: any) {}
}
export class SetLoading {
    public static readonly type = '[marketActions] SetLoading';
    constructor(public param: boolean) {}
}
