import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AppRoutesModule } from './app.routes';
import { AppComponent } from './app.component';
import { McCommonModule } from '../components/common.module';
import { InterceptorsModule } from '../_interceptors/interceptors.module';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from '@env/environment.local';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { AppState } from './store/app.state';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { CookieService } from 'ngx-cookie-service';
import { NgxPrintModule } from 'ngx-print';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarState } from '@app/core/sidebar/store/sidebar.state';
import { AsyncPipe, registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
import localeEsEs from '@angular/common/locales/es';
import localeEsMx from '@angular/common/locales/es-MX';
import localeEsPe from '@angular/common/locales/es-PE';
import localeEsUy from '@angular/common/locales/es-UY';
import localeEsCl from '@angular/common/locales/es-CL';
import localeEsBr from '@angular/common/locales/es-BR';
import localeEsVe from '@angular/common/locales/es-VE';
import localeEsCo from '@angular/common/locales/es-CO';
import localeEsBo from '@angular/common/locales/es-BO';
import localeEsUs from '@angular/common/locales/es-US';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CdkStepperModule } from '@angular/cdk/stepper';

import { StepperState } from '@app/components/ss-stepper/store/stepper.state';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PopupSectionPaginasInputComponent } from '@app/components/popup-section-paginas-input/popup-section-paginas-input.component';
import { PopupSectionPaginasConfirmComponent } from '@app/components/popup-section-paginas-confirm/popup-section-paginas-confirm.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

// Firebase imports
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { MessagingService } from '@app/_services/push-notifications/messaging-service';
import { DashboardState } from '@app/pages/dashboard/store/dashboard.state';
import { MatBadgeModule } from '@angular/material/badge';

registerLocaleData(localeEsAr, 'es-ar');
registerLocaleData(localeEsEs, 'es-es');
registerLocaleData(localeEsCl, 'es-cl');
registerLocaleData(localeEsMx, 'es-mx');
registerLocaleData(localeEsUy, 'es-uy');
registerLocaleData(localeEsPe, 'es-pe');
registerLocaleData(localeEsBr, 'es-br');
registerLocaleData(localeEsBo, 'es-bo');
registerLocaleData(localeEsVe, 'es-ve');
registerLocaleData(localeEsCo, 'es-co');
registerLocaleData(localeEsUs, 'es-us');
registerLocaleData(localeEsUs, 'es-int');

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}
@NgModule({
    declarations: [
        AppComponent,
        SidebarComponent,
        PopupSectionPaginasInputComponent,
        PopupSectionPaginasConfirmComponent,
    ],
    imports: [
        MatFormFieldModule,
        MatDialogModule,
        MatButtonModule,
        TranslateModule.forRoot({
            defaultLanguage: 'es',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        AppRoutesModule,
        InterceptorsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        McCommonModule,
        NgbModule,
        ToastrModule.forRoot(),
        NgxsModule.forRoot([AppState, SidebarState, StepperState, DashboardState], {
            developmentMode: !environment.production,
        }),
        NgxsStoragePluginModule.forRoot({
            key: ['App.isLogged', 'App.session', 'App.lastVisit', 'App.adminPrefix', 'Sidebar.collapseForced'],
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: !environment.production }),
        NgxPrintModule,
        CdkStepperModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately',
        }),
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
    ],

    bootstrap: [AppComponent],
    providers: [CookieService, MessagingService, AsyncPipe],
})
export class AppModule {}
