export class SettingsAction {
    public static readonly type = '[Settings] Add item';
    constructor(public payload: string) {}
}
export class SetSettingsNav {
    public static readonly type = '[Settings] SetSettingsNav';
}

export class SettingsExecuteSubmit {
    public static readonly type = '[Design] SettingsExecuteSubmit';
    constructor(public execute: boolean) {}
}
