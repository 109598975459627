import { Injectable } from '@angular/core';
import { MarketService } from '../../_services/market/market.service';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from '@app/core/store/app.state';
import { CommomHeaders } from '../httpHeaders/CommonHeaders';
import { ApiService } from '@app/_services/api.service';
import { MarketData } from './../../_interfaces/configuration/marketdata.interface';
import { BasicInfoState } from '@app/pages/web-site/basic-info/store/basic-info.state';

@Injectable({
    providedIn: 'root',
})
export class ImageHelper {
    @Select(AppState.siteAliases)
    siteAliases$: Observable<string>;

    @Select(AppState.assetsUrls)
    assetsUrls$: Observable<string>;

    @Select(AppState.getConfig)
    marketData$: Observable<MarketData>;

    @Select(BasicInfoState.faviconName)
    faviconName$: Observable<string>;

    aliasValue: string;
    marketId: string;

    constructor(
        private marketService: MarketService,
        private store: Store,
        private headers: CommomHeaders,
        private apiService: ApiService
    ) {
        this.getMarketId();
    }

    getUrlImage(imgName?: string): string {
        let imgUrl: string = '';
        this.assetsUrls$.subscribe((url) => {
            if (url) {
                imgUrl = `${url}/img/${imgName}`;
            }
        });
        return imgUrl;
    }

    getFavico(): string {
        let imgUrl: string = '';
        this.assetsUrls$.subscribe((url) => {
            if (url) {
                this.faviconName$.subscribe((faviconName) => {
                    imgUrl = `${url}/favicon/${faviconName}-228.png`;
                });
            }
        });
        return imgUrl;
    }

    getMarketId() {
        this.marketData$.subscribe((data: MarketData) => {
            this.marketId = sanitizeId(data.idMarket);
        });

        function sanitizeId(n) {
            return (n < 10 ? '0' : '') + n;
        }
    }
}
