import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WizardStatus } from '@app/_models/wizard/wizard-item.model';
import { StepperHelper } from '@app/_shared/helpers/StepperHelper';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GetStepsList } from '../../store/stepper.actions';
import { StepperState } from '../../store/stepper.state';
import { environment } from '@env/environment.local';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'welcome-wizard',
    templateUrl: './welcome-wizard.component.html',
    styleUrls: ['./welcome-wizard.component.scss'],
})
export class WelcomeWizardComponent implements OnInit {
    @Output() newEvent = new EventEmitter<any>();
    @Input() textButton: string;
    @Input() title: string;
    assetsUrl: string = environment.assets_url;
    mfsPath: string = environment.mfs_path;

    @Select(StepperState.getStepperGeneralStatus)
    stepperGeneralStatusResponse$: Observable<WizardStatus>;

    constructor(public translate: TranslateService, private store: Store, private stepperHelper: StepperHelper) {}

    ngOnInit(): void {
        this.rememberStatus();
        this.store.dispatch(new GetStepsList('wizard-inicial'));
    }
    emitEvent() {
        this.newEvent.emit();
    }

    rememberStatus() {
        this.stepperGeneralStatusResponse$.subscribe((status) => {
            this.stepperHelper.setGeneralStepperData(status.data ?? []);
            this.stepperHelper.setCurrentStepName(status.latestStepName ?? 'select-vertical');
            this.stepperHelper.setCurrentStepIndex(status.latestStepIndex ?? 0);
        });
    }
}
