<div class="mc-toast__wrapper p-2 p-sm-3">
    <div class="mc-toast__message">
        <div class="mc-toast__message__icon">
            <mc-icon prefix="fal" [name]="getIconName()"></mc-icon>
        </div>
        <div class="ml-auto">
            <div *ngIf="messageLength === 'large'">
                <div *ngFor="let message of messageConverted">
                    <span class="card-text" [innerHTML]="message"></span>
                </div>
            </div>
            <span *ngIf="messageLength !== 'large'" class="card-text" [innerHTML]="message"></span>
        </div>
    </div>
</div>
<div class="mc-toast__ripple"></div>
