import { Injectable } from '@angular/core';
import { ResetPassword } from '@app/_interfaces';
import { CommomHeaders } from '@app/_shared/httpHeaders/CommonHeaders';
import { ApiService } from '../api.service';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    constructor(private api: ApiService, private headers: CommomHeaders) {}

    changePassword(data: ResetPassword) {
        return this.api.postFz(`/api/v1/back/change-password`, data, this.headers.getAuthHeaders());
    }

    changeUserName(userName: string) {
        return this.api.postFz(
            `/api/v1/back/change-username`,
            { newusername: userName },
            this.headers.getAuthHeaders()
        );
    }
}
