<div class="d-flex flex-column align-items-center justify-content-center page-error">
    <div class="img-container">
        <img src="{{ assetsUrl }}{{ mfsPath }}/img/front/dashboard/Ilus_error_404.svg" />
    </div>
    <div class="txt-container">
        <h2>{{ esta_pagina_se_perdio }}</h2>
        <p>
            {{ buscamos_por_todas_partes }}<br />
            {{ y_no_la_encontramos }}
        </p>
    </div>
    <div class="div-btn">
        <a class="btn btn-skip" (click)="showLiveChat()">{{ contactar }}</a>
        <a class="btn btn-continue" href="/">{{ volver }}</a>
    </div>
</div>
