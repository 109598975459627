import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { StepperHelper } from '@app/_shared/helpers/StepperHelper';
import { FileErrors } from '@app/_interfaces/errors';

@Component({
    selector: 'app-file-upload-wizard',
    templateUrl: './file-upload-wizard.component.html',
    styleUrls: ['./file-upload-wizard.component.scss'],
})
export class FileUploadWizardComponent implements OnInit, OnChanges {
    file: File;
    urlLogo: any;
    logoSucess: boolean = false;
    loading: boolean;
    logo: File;
    applyFaviconClass: boolean = false;
    errors: FileErrors = {};

    @Input() showMessages: boolean = true;
    @Input() serverError: boolean;
    @Input() showRecommendationMessage: boolean = true;
    @Input() errorElementName: string;
    @Input() logoLoaded: string;
    @Input() deleteFileLogo: boolean;
    @Input() stepName: string;
    @Input() showDeleteBtn: boolean = true;
    @Input() showReplaceBtn: boolean = true;
    @Input() isFavicon: boolean = false;
    @Input() imageLimitInMB: number = 2;
    @Output() sendLogoToParent = new EventEmitter();
    @Output() deleteLogoEmitter = new EventEmitter();

    constructor(private stepperHelper: StepperHelper) {}

    ngOnChanges(changes) {
        if (changes.deleteFileLogo?.currentValue) {
            this.clearErrors();
            this.urlLogo = null;
        }

        if (changes.serverError?.currentValue) {
            const { currentValue } = changes.serverError;
            this.errors.serverError = currentValue;
        }
    }
    async ngOnInit(): Promise<void> {
        const data = this.stepperHelper.getFormData(this.stepName);
        if (data && data.logo !== '') {
            this.urlLogo = data.logo;
            this.sendLogoToParent.emit(await this.dataUrlToFile(data.logo, 'logo'));
        }
    }

    onFileSelected(event) {
        this.errors.serverError = false;
        const file = event.target.files[0] as File;
        const result = this.checkFileSize(file);
        const includeExtension = this.checkExtensionFile(file);
        this.file = result && includeExtension ? file : null;
        this.loading = result && includeExtension ? true : false;
        if (result && includeExtension && event.target.files && file) {
            let reader = new FileReader();
            reader.onload = (event: ProgressEvent) => {
                if (this.isFavicon) this.applyFaviconClass = false;
                this.urlLogo = (<FileReader>event.target).result;
            };
            reader.onerror = (event: ProgressEvent) => (this.loading = false);
            reader.readAsDataURL(file);
            this.sendLogo(file);
        }
    }

    sendLogo(logo: File) {
        this.sendLogoToParent.emit(logo);
        this.loading = false;
    }

    async dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
        const res: Response = await fetch(dataUrl);
        const blob: Blob = await res.blob();
        return new File([blob], fileName, { type: 'image/png' });
    }

    checkFileSize(file: File) {
        if (this.imageLimitInMB === null) return true;
        const fileSizeInMegas = Number((file?.size / 1024 / 1024).toFixed(2));
        this.errors = { ...this.errors, maxSize: fileSizeInMegas >= this.imageLimitInMB };
        return !(fileSizeInMegas >= this.imageLimitInMB);
    }

    checkExtensionFile(file): boolean {
        const filesExtensions = ['image/png', 'image/bmp', 'image/jpeg'];
        this.errors = { ...this.errors, validExtension: !filesExtensions.includes(file.type) };
        return filesExtensions.includes(file.type);
    }

    clearErrors() {
        this.errors = {};
    }
    replaceLogo(element): void {
        element.click();
    }

    deleteLogo(): void {
        this.clearErrors();
        this.logoLoaded = '';
        this.urlLogo = '';
        this.deleteLogoEmitter.emit('delete logo');
    }

    openFileUploadWindow(element) {
        element.click();
    }
}
