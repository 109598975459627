import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetPhotoProfileSideBarTemp } from '@app/core/store/app.actions';
import { StepperHelper } from '@app/_shared/helpers/StepperHelper';
import { FileErrors } from '@app/_interfaces/errors';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnChanges {
    file: File;
    urlLogo: any;
    logoSucess: boolean = false;
    loading: boolean;
    logo: File;
    applyFaviconClass: boolean = false;
    errors: FileErrors = {};

    @Input() showMessages: boolean = true;
    @Input() logoLoaded: string;
    @Input() deleteFileLogo: boolean;
    @Input() stepName: string;
    @Input() isFavicon: boolean = false;
    @Output() sendLogoToParent = new EventEmitter();

    constructor(private store: Store, private stepperHelper: StepperHelper) {}

    ngOnChanges(changes) {
        if (changes.deleteFileLogo?.currentValue) {
            this.clearErrors();
            this.urlLogo = null;
        }
        if (this.isFavicon) {
            this.applyFaviconClass = true;
        }
    }
    async ngOnInit(): Promise<void> {
        const data = this.stepperHelper.getFormData(this.stepName);
        if (data && data.logo !== '') {
            this.urlLogo = data.logo;
            this.sendLogoToParent.emit(await this.dataUrlToFile(data.logo, 'logo'));
        }
    }

    onFileSelected(event) {
        const file = event.target.files[0] as File;
        const result = this.checkFileSize(file);
        this.file = result ? file : null;
        this.loading = result ? true : false;
        if (result && event.target.files && file) {
            let reader = new FileReader();
            reader.onload = (event: ProgressEvent) => {
                if (this.isFavicon) this.applyFaviconClass = false;
                this.urlLogo = (<FileReader>event.target).result;
                this.store.dispatch(new SetPhotoProfileSideBarTemp(this.urlLogo));
            };
            reader.onerror = (event: ProgressEvent) => (this.loading = false);
            reader.readAsDataURL(file);
            this.sendLogo(file);
        }
    }

    sendLogo(logo: File) {
        this.sendLogoToParent.emit(logo);
        this.loading = false;
    }

    async dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
        const res: Response = await fetch(dataUrl);
        const blob: Blob = await res.blob();
        return new File([blob], fileName, { type: 'image/png' });
    }

    checkFileSize(file: File) {
        const fileSizeInMegas = Number((file?.size / 1024 / 1024).toFixed(2));
        this.errors = { ...this.errors, maxSize: fileSizeInMegas >= 2 };
        return !(fileSizeInMegas >= 2);
    }

    clearErrors() {
        this.errors = {};
    }
}
