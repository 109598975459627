import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class CommomHeaders {
    constructor() {}

    getAuthHeaders(): any {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/javascript, */*; q=0.01',
                'Accept-Encoding': 'gzip, compress, br',
            }),
            withCredentials: true,
        };
        return this.getHeaderImage();
    }

    getStdHeaders(): any {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json, text/javascript, */*; q=0.01',
                'Accept-Encoding': 'gzip, compress, br',
            }),
            withCredentials: true,
        };
        return httpOptions;
    }

    getHeaderImage() {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return headers;
    }

    getHeaderImageV2() {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'image/jpeg');
        headers.append('Accept', 'image/jpeg');
        return headers;
    }
}
