import { Injectable } from '@angular/core';
import { AppState } from '@app/core/store/app.state';
import { NavItemInterface } from '@app/_interfaces';
import { McToastService } from '@app/_services';
import { ProfileService } from '@app/_services/profile/profile.service';
import { TranslateService } from '@ngx-translate/core';
import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ChangePassword, ChangeUserName, ExecuteProfileSubmit, SetNav } from './profile.actions';

export interface ProfileStateModel {
    loading: boolean;
    loadingFormSubmit: boolean;
    nav: NavItemInterface[];
    executeProfileSubmit: boolean;
    userNameError: string;
}
@State<ProfileStateModel>({
    name: 'Profile',
    defaults: {
        loading: false,
        loadingFormSubmit: false,
        nav: [],
        executeProfileSubmit: false,
        userNameError: null,
    },
})
@Injectable()
export class ProfileState {
    @Select(AppState.getMarketLanguageValue)
    initialLanguage: Observable<string>;

    constructor(
        private translate: TranslateService,
        private profile: ProfileService,
        private mcToast: McToastService
    ) {}

    @Selector()
    public static loading(state: ProfileStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static userNameError(state: ProfileStateModel): string {
        return state.userNameError;
    }

    @Selector()
    public static nav(state: ProfileStateModel): NavItemInterface[] {
        return state.nav;
    }

    @Selector()
    public static executeProfileSubmit(state: ProfileStateModel): boolean {
        return state.executeProfileSubmit;
    }

    @Selector()
    public static loadingFormSubmit(state: ProfileStateModel): boolean {
        return state.loadingFormSubmit;
    }

    @Action(SetNav)
    public setNav({ patchState }: StateContext<ProfileStateModel>) {
        return patchState({
            nav: [
                {
                    label: this.translate.instant('app.nav.profile'),
                    link: 'perfil',
                    icon: 'user',
                    show: true,
                },
                {
                    label: this.translate.instant('app.nav.panel_languages'),
                    link: 'panel-languages',
                    icon: 'language',
                    show: true,
                },
                {
                    label: this.translate.instant('app.nav.api_tokens'),
                    link: 'api-tokens',
                    icon: 'brackets-curly',
                    show: true,
                },
            ],
        });
    }

    @Action(ExecuteProfileSubmit)
    public executeProfileSubmit(
        { patchState, getState }: StateContext<ProfileStateModel>,
        { execute }: ExecuteProfileSubmit
    ) {
        patchState({
            executeProfileSubmit: execute,
        });
    }

    @Action(ChangePassword)
    public changePassword({ patchState }: StateContext<ProfileStateModel>, { data }: ChangePassword) {
        patchState({ loadingFormSubmit: true });
        return this.profile.changePassword(data).pipe(
            tap(
                () => {
                    this.mcToast.success(this.translate.instant('app.profile.cambiaste-contraseña'));
                    patchState({ loadingFormSubmit: false });
                },
                (err) => {
                    patchState({ loadingFormSubmit: false });
                    return err;
                }
            )
        );
    }

    @Action(ChangeUserName)
    public changeUserName({ patchState }: StateContext<ProfileStateModel>, { userName }: ChangeUserName) {
        patchState({ userNameError: null });
        return this.profile.changeUserName(userName).pipe(
            tap(
                () => {
                    this.mcToast.success(`${this.translate.instant('app.profile.cambiaste-tu-nombre-de-usuario')}`);
                    patchState({ userNameError: null });
                },
                (err) => {
                    this.mcToast.danger(`${this.translate.instant('app.profile.no-cambiamos-tu-nombre-de-usuario')}`);

                    if (err.error.data.errors) {
                        const error = err.error.data.errors?.newusername[0];
                        patchState({ userNameError: error });
                    }
                    return err;
                }
            )
        );
    }
}
