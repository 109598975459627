import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import { StepperHelper } from '@app/_shared/helpers/StepperHelper';
import { AppState } from '@app/core/store/app.state';
import { Observable } from 'rxjs';
import { UpdateGeneralData } from '../../store/stepper.actions';
import { StepperState } from '../../store/stepper.state';
import { ImageHelper } from '@app/_shared/helpers/ImageHelper';
import { debounceTime, tap } from 'rxjs/operators';
import { environment } from '@env/environment.local';
import {
    getSiteHeaderInfoAction,
    setSiteHeaderInfoAction,
} from '@app/pages/web-site/design/header-customization/store/header-customization.actions';
import { HeaderCustomization } from '@app/_models/pages/design/headerCustomization.model';
import { HeaderCustomizationState } from '@app/pages/web-site/design/header-customization/store/header-customization.state';

@Component({
    selector: 'info-market-form',
    templateUrl: './info-market-form.component.html',
    styleUrls: ['./info-market-form.component.css'],
})
export class InfoMarketFormComponent implements OnInit, OnDestroy {
    @ViewChild('marketIframe') marketIframe: ElementRef;
    @Input() stepName: string;

    InfoMarketForm: UntypedFormGroup;
    submitted: boolean;
    infoMarketData: any;
    urlLogo: string;
    logo: File;
    deleteFileLogo: boolean;
    iframeUrl: string = `${this.store.selectSnapshot(AppState.marketSubdomain)}?stats=off&render=true`;

    @Select(StepperState.wizardLogoName)
    wizardLogoName$: Observable<any>;

    @Select(AppState.siteAliases)
    siteAliases$: Observable<string>;

    @Select(AppState.getProfilePhotoTemp)
    profilePhotoTemp$: Observable<string>;

    constructor(
        private fb: UntypedFormBuilder,
        private store: Store,
        private cdkStepper: CdkStepper,
        private cdkStep: CdkStep,
        private stepperHelper: StepperHelper,
        private imageHelper: ImageHelper
    ) {}

    ngOnInit(): void {
        this.store.dispatch(new getSiteHeaderInfoAction());
        this.stepperHelper.setCurrentStepName(this.stepName);
        this.stepperHelper.rememberStatus(this.stepName, this.cdkStep);
        this.hasDataLoaded();
        this.buildForm();
        if (this.infoMarketData) {
            if (this.infoMarketData.imagenlogo) {
                this.urlLogo = this.infoMarketData.imagenlogo;
            }
        }
        this.marketTitleListenner();
    }

    ngOnDestroy(): void {
        this.stepperHelper.updateStatusStep(this.stepName, this.cdkStep);
    }

    onFormSubmit(): void {
        const formValue = this.InfoMarketForm.value;
        const dataStep = { alias: formValue.alias, imagenlogo: this.urlLogo };
        this.stepperHelper.updateDataStep(this.stepName, dataStep);
        this.stepperHelper.sendStepperStatus();
        this.cdkStepper.next();
    }

    buildForm(): void {
        if (this.infoMarketData) this.urlLogo = this.infoMarketData.imagenlogo;
        this.InfoMarketForm = this.fb.group({
            alias: [
                this.infoMarketData ? this.infoMarketData.alias : '',
                [Validators.required, Validators.maxLength(50), Validators.minLength(2)],
            ],
        });
    }

    hasDataLoaded(): void {
        this.stepperHelper.getDataStep(this.stepName)
            ? (this.infoMarketData = this.stepperHelper.getDataStep(this.stepName))
            : (this.infoMarketData = null);
    }

    getLogo(logo: File): void {
        const uploadedLogo = new FormData();
        uploadedLogo.append('imagenlogo', logo);
        uploadedLogo.append('tienelogo', `${1}`);
        uploadedLogo.append(`faviconlogo`, `${1}`);
        uploadedLogo.append(`tienefavicon`, `${1}`);
        this.store.dispatch(new UpdateGeneralData(uploadedLogo)).subscribe({
            next: () => {
                this.deleteFileLogo = false;
                this.loadLogo();
                this.reloadIframe();
                this.stepperHelper.sendStepperStatus();
            },
        });
    }

    loadLogo(): void {
        const formValue = this.InfoMarketForm.value;
        this.wizardLogoName$.subscribe((value) => {
            this.urlLogo = this.imageHelper.getUrlImage(value);
            const dataStep = { alias: formValue.alias, imagenlogo: this.imageHelper.getUrlImage(value) };
            this.stepperHelper.updateDataStep(this.stepName, dataStep);
        });
    }
    deleteLogo(): void {
        const formValue = this.InfoMarketForm.value;
        const deleteLogo = new FormData();
        deleteLogo.append('tienelogo', JSON.stringify(0));
        deleteLogo.append(`faviconlogo`, `${0}`);
        deleteLogo.append(`tienefavicon`, `${0}`);
        this.store.dispatch(new UpdateGeneralData(deleteLogo)).subscribe({
            next: () => {
                this.deleteFileLogo = true;
                this.reloadIframe();
                this.urlLogo = '';
                const dataStep = { alias: formValue.alias, imagenlogo: '' };
                this.stepperHelper.updateDataStep(this.stepName, dataStep);
                this.stepperHelper.sendStepperStatus();
            },
        });
    }

    saveMarketName(): void {
        const formValue = this.InfoMarketForm.value;
        const marketTitle = new FormData();
        marketTitle.set('titulo', formValue.alias);
        const dataStep = { alias: formValue.alias, imagenlogo: this.urlLogo };
        this.stepperHelper.updateDataStep(this.stepName, dataStep);
        if (this.InfoMarketForm.valid) {
            const headerCustomization = this.store.selectSnapshot(HeaderCustomizationState).siteHeaderInfo;
            const data: HeaderCustomization = {
                ...headerCustomization,
                selectedLayout: formValue.alias.length > 20 ? '2' : '1',
            };
            this.store
                .dispatch([new UpdateGeneralData(marketTitle), new setSiteHeaderInfoAction(data)])
                .subscribe(() => {
                    this.reloadIframe();
                    this.stepperHelper.sendStepperStatus();
                });
        }
    }

    marketTitleListenner(): void {
        this.InfoMarketForm.valueChanges
            .pipe(
                tap(() => ''),
                debounceTime(700)
            )
            .subscribe(() => {
                this.saveMarketName();
            });
    }

    reloadIframe(): void {
        this.iframeUrl = environment.brandPath;
        setTimeout(() => {
            this.iframeUrl = `${this.store.selectSnapshot(AppState.marketSubdomain)}?stats=off&render=true`;
        });
    }
}
