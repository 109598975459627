<!-- <section class="example-container" >
  <header>
    <h2>Step {{ selectedIndex + 1 }}/{{ steps.length }}</h2>
  </header> -->
<!-- <div 
  *ngFor="let step of _steps; let i = index"
  [@stepTransition]="_getAnimationDirection(i)" 
  (@stepTransition.done)="_animationDone.next($event)"
  > -->
<div [ngTemplateOutlet]="selected ? selected.content : null"></div>
<!-- </div> -->

<!-- </section> -->
