import { Domain } from '@app/_models/pages/domain.model';

export class DomainsAction {
    public static readonly type = '[Domains] Add item';

    constructor(public payload: string) {}
}

export class GetOwnDomains {
    public static readonly type = '[Domains] GetOwnDomains';

    constructor(public page?: number, public size?: number) {}
}

export class GetAvailableDomains {
    public static readonly type = '[Domains] GetAvailableDomains';

    constructor() {}
}

export class SetDomain {
    public static readonly type = '[Domains] Add domain';

    constructor(public payload: string) {}
}

export class SetSelectedParkDomain {
    public static readonly type = '[Domains] Set selected park domain';

    constructor(public payload: string, public showToast: boolean) {}
}

export class AddCustomDomain {
    public static readonly type = '[Domains] Add custom domain';

    constructor(public payload: string) {}
}

export class DeleteParkedDomain {
    public static readonly type = '[Domains] Delete Parked Domain';

    constructor(public payload: Domain) {}
}

export class SetPointToDomain {
    public static readonly type = '[Domains] Set pointTo Domain';

    constructor(public domain: any, public payload: string) {}
}

export class CheckIfDomainExists {
    public static readonly type = '[Domains] Check if domain exists';

    constructor(public domain: string) {}
}

export class SetSuggestDomainsEmpty {
    public static readonly type = '[Domains] Set suggest domains empty';

    constructor() {}
}

export class CreateDomain {
    public static readonly type = '[Domains] Create domain';

    constructor(public payload: string) {}
}
