<div *ngIf="paginator" class="paginator">
    <mat-paginator
        [length]="paginator.totalItems"
        [pageSize]="paginator.pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="pageChange.emit($event)"
        *ngIf="paginator.totalItems > 5"
    >
    </mat-paginator>
</div>
