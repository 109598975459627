import { style, animate, transition, trigger, state, group } from '@angular/animations';

export const slideInOut: any = trigger('slideInOut', [
    state('in', style({ height: '*', opacity: 0 })),
    transition(':leave', [
        style({ height: '*', opacity: 1, overflow: 'hidden' }),
        group([animate(300, style({ height: 0 })), animate('200ms ease-in-out', style({ opacity: '0' }))]),
        style({ overflow: 'unset' }),
    ]),
    transition(':enter', [
        style({ height: '0', opacity: 0, overflow: 'hidden' }),
        group([animate(300, style({ height: '*' })), animate('400ms ease-in-out', style({ opacity: '1' }))]),
        style({ overflow: 'unset' }),
    ]),
]);
