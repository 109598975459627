import { SidebarMenuItem } from '@app/_interfaces';
import { environment } from '@env/environment.local';

export const sidebar: SidebarMenuItem[] = [
    {
        id: 'home',
        label: 'Home',
        icon: 'home',
        url: 'dashboard',
        availableOnlyInFeature: [],
        show: true,
        children: [],
    },
    {
        id: 'website',
        label: 'Sitio Web',
        icon: 'browser',
        url: 'website',
        availableOnlyInFeature: [],
        show: true,
        children: [
            {
                id: 'pages',
                label: 'Páginas',
                url: 'website/pages',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [],
                show: true,
            },
            {
                id: 'basic-info',
                label: 'Información Básica',
                url: 'website/basic-info',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [],
                show: true,
            },
            {
                id: 'contact-data',
                label: 'Información de contacto',
                url: 'website/contact-data',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [],
                show: true,
            },
            {
                id: 'queries',
                label: 'Consultas',
                url: 'website/queries',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [], // 2 >>> tienda
                show: true,
            },
            {
                id: 'design',
                label: 'Diseño',
                url: 'website/design',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [],
                show: true,
            },
            {
                id: 'settings',
                label: 'Ajustes',
                url: 'website/settings',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [],
                show: true,
            },
        ],
    },
    {
        id: 'domains',
        label: 'Dominios',
        icon: 'globe',
        url: 'domains',
        availableOnlyInFeature: [],
        show: true,
        children: [],
    },
    {
        id: 'e-mails',
        label: 'Correos electrónicos',
        icon: 'envelope',
        url: 'e-mails',
        availableOnlyInFeature: [],
        show: true,
        children: [],
    },
    {
        id: 'tools',
        label: 'Herramientas',
        icon: 'tools',
        url: 'tools',
        availableOnlyInFeature: [],
        show: true,
        children: [
            {
                id: 'integrations',
                label: 'Integraciones & apps',
                url: 'tools/integrations',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [],
                show: true,
            },
            {
                id: 'seo-tools',
                label: 'Herramientas de SEO',
                url: 'tools/seo-tools',
                image: '',
                availableOnlyInFeature: [],
                show: true,
            },
        ],
    },
    {
        id: 'statistics',
        label: 'Estadísticas',
        icon: 'chart-line',
        url: 'statistics',
        availableOnlyInFeature: [],
        show: true,
        children: [],
    },
    {
        id: 'ecommerce',
        label: 'Tienda',
        icon: 'store',
        url: 'e-commerce',
        availableOnlyInFeature: [],
        show: true,
        children: [
            {
                id: 'sales',
                label: 'Ventas',
                url: 'e-commerce/sales',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [2], // 2 >>> tienda
                show: true,
            },
            {
                id: 'products',
                label: 'Productos',
                url: 'e-commerce/products',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [],
                show: true,
            },
            {
                id: 'categories',
                label: 'Categorías',
                url: 'e-commerce/categories',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [],
                show: true,
            },
            {
                id: 'tags',
                label: 'Etiquetas',
                url: 'e-commerce/tags',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [],
                show: true,
            },
            {
                id: 'shipping-methods',
                label: 'Métodos de envío',
                url: 'e-commerce/shipping-methods',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [2], // 2 >>> tienda
                show: true,
            },
            {
                id: 'payment-methods',
                label: 'Métodos de pago',
                url: 'e-commerce/payment-methods',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [2], // 2 >>> tienda
                show: true,
            },
            {
                id: 'customer-management',
                label: 'Gestión de Clientes',
                url: 'e-commerce/customer-management',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [2], // 2 >>> tienda
                show: true,
            },
            {
                id: 'discount-coupons',
                label: 'Cupones de descuento',
                url: 'e-commerce/discount-coupons',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [2], // 2 >>> tienda
                show: true,
            },
            {
                id: 'sales-channels',
                label: 'Integraciones',
                url: 'e-commerce/sales-channels',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [],
                show: true,
            },
            {
                id: 'configurations',
                label: 'Configuraciones',
                url: 'e-commerce/configurations',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [],
                show: true,
            },
        ],
    },
    {
        id: 'blog',
        label: 'BLOG',
        icon: 'blog',
        url: 'blog',
        availableOnlyInFeature: [],
        show: environment.enableBlog,
        children: [
            {
                id: 'blog-entries',
                label: 'Entradas',
                url: 'blog/entries',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [],
                show: true,
            },
            {
                id: 'blog-categories',
                label: 'Categorías',
                url: 'blog/categories',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [],
                show: true,
            },
            {
                id: 'blog-configuration',
                label: 'Configuración',
                url: 'blog/configuration',
                image: '/assets/img/servicios/icons/taxi-505.png',
                availableOnlyInFeature: [],
                show: true,
            },
        ],
    },
];
