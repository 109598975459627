import { C } from '@angular/cdk/keycodes';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UpdatePageAction } from '@app/pages/web-site/pages/store/site-pages.actions';
import { McToastService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';

@Component({
    selector: 'app-popup-section-paginas-input',
    templateUrl: './popup-section-paginas-input.component.html',
    styleUrls: ['./popup-section-paginas-input.component.scss'],
})
export class PopupSectionPaginasInputComponent implements OnInit {
    renamePageForm: UntypedFormGroup;
    loading: boolean = false;
    url_slugify: string;

    @Input() page: any;

    constructor(
        public dialogRef: MatDialogRef<PopupSectionPaginasInputComponent>,
        @Inject(MAT_DIALOG_DATA) public parametros: any,
        private fb: UntypedFormBuilder,
        private store: Store,
        private translate: TranslateService,
        private mcToast: McToastService
    ) {}

    ngOnInit(): void {
        this.buildForm();
    }

    buildForm() {
        if (this.parametros.page) {
            this.renamePageForm = this.fb.group({
                rename_page: [this.parametros.page.title, [Validators.required, Validators.minLength(3)]],
            });
        } else {
            this.renamePageForm = this.fb.group({
                rename_page: [this.parametros.subpage.title, [Validators.required, Validators.minLength(3)]],
            });
        }
    }

    onFormSubmit(valid) {
        let data;
        this.loading = true;
        if (this.parametros.page) {
            data = {
                id: this.parametros.page.idpage,
                title: this.renamePageForm.value.rename_page,
                language: this.parametros.page.idLanguage,
                titleSeo: this.renamePageForm.value.rename_page,
                prettyLink:
                    this.parametros.page.type != 'link'
                        ? this.createUrlSlug(this.renamePageForm.value.rename_page)
                        : this.parametros.prettyLink,
            };
        } else {
            data = {
                id: this.parametros.subpage.idpage,
                title: this.renamePageForm.value.rename_page,
                language: this.parametros.subpage.idLanguage,
                titleSeo: this.renamePageForm.value.rename_page,
                prettyLink:
                    this.parametros.subpage.type != 'link'
                        ? this.createUrlSlug(this.renamePageForm.value.rename_page)
                        : this.parametros.prettyLink,
            };
        }

        this.store.dispatch(new UpdatePageAction(data)).subscribe({
            next: () => {
                this.loading = false;
                this.dialogRef.close();
                this.mcToast.success(this.parametros.successMsg);
            },
        });
    }

    createUrlSlug(pageTitle) {
        return (this.url_slugify = pageTitle
            .toLowerCase()
            .replace(/[├└]/g, '')
            .trim()
            .replace(/ |_/g, '-')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''));
    }
}
