import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[focusOnInput]',
})
export class FocusOnInputDirective implements OnChanges, AfterViewInit {
    @Input() focusOnInput: any;
    value: boolean;
    constructor(private el: ElementRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.value = changes.focusOnInput.currentValue;
        if (this.value) {
            this.setFocus();
        }
    }

    setFocus() {
        const el = this.el.nativeElement;
        el.focus();
    }

    ngAfterViewInit(): void {
        if (this.value) {
            setTimeout(() => {
                this.setFocus();
            }, 1000);
        }
    }
}
