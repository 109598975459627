<div class="col-12 p-3 p-lg-0 container-form-modal-pages">
    <div class="">
        <p class="design-modal-title">{{ parametros.title }}</p>
    </div>
    <div class="mt-2" *ngIf="parametros.actionDescription">
        <p class="description">{{ parametros.actionDescription }}</p>
    </div>
    <div class="d-flex justify-content-between justify-content-sm-end mt-3 mt-lg-4">
        <button class="btn btn-skip mr-sm-4" mat-dialog-close>{{ 'app.domains.cancelar' | translate }}</button>
        <button class="btn btn-continue" type="submit" (click)="cerrarPopup(true)">{{ parametros.btnText }}</button>
    </div>
</div>
