import { Injectable } from '@angular/core';
import { AppState } from '@app/core/store/app.state';
import { McToastService } from '@app/_services';
import { ConfigurationService } from '@app/_services/configuration/configuration.service';
import { ResponseHelper } from '@app/_shared/helpers/ResponseHelper';
import { utils } from '@app/_utils';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
    GetContactData,
    SetContactData,
    SetCountryCodePhoneNumber,
    SetCountryCodeWhatSapp,
    SetCountryCodeWhatSappBusiness,
    SetLoading,
    SetWhatsappButton,
} from './contact-data.actions';

export interface ContactDataStateModel {
    contactEmail: string;
    phone: string;
    whatsapp: string;
    socialMedia: any[];
    buyOnWhatsapp: boolean;
    whatsappBusiness: string;
    showEmail: boolean;
    showFloatWhatsappButton: boolean;
    alignLeftWhatsappButton: boolean;
    shareUrlProductWhatsapp: boolean;
    defaultTextWhatsapp: string;
    address: string;
    loadingFormSubmit: boolean;
    countryCodePhoneNumber: any;
    countryCodeWp: any;
    countryCodeWpBusiness: any;
    loading: boolean;
    countryFromBO: string;
}

@State<ContactDataStateModel>({
    name: 'contactData',
    defaults: {
        contactEmail: '',
        phone: '',
        whatsapp: '',
        socialMedia: [],
        buyOnWhatsapp: false,
        loadingFormSubmit: false,
        whatsappBusiness: '',
        showEmail: false,
        showFloatWhatsappButton: false,
        alignLeftWhatsappButton: false,
        shareUrlProductWhatsapp: false,
        defaultTextWhatsapp: '',
        address: '',
        countryCodePhoneNumber: '',
        countryCodeWp: '',
        countryCodeWpBusiness: '',
        loading: false,
        countryFromBO: null,
    },
})
@Injectable()
export class ContactDataState {
    constructor(
        private configurationService: ConfigurationService,
        private mcToast: McToastService,
        private responseHelper: ResponseHelper,
        private translate: TranslateService,
        private store: Store
    ) {}

    @Selector()
    public static getContactData(state: ContactDataStateModel) {
        return state;
    }

    @Selector()
    public static getLoadingFormSubmit(state: ContactDataStateModel) {
        return state.loadingFormSubmit;
    }

    @Selector()
    public static countryCodePhoneNumber(state: ContactDataStateModel) {
        return state.countryCodePhoneNumber;
    }
    @Selector()
    public static countryCodeWp(state: ContactDataStateModel) {
        return state.countryCodeWp;
    }
    @Selector()
    public static countryCodeWpBusiness(state: ContactDataStateModel) {
        return state.countryCodeWpBusiness;
    }

    @Selector()
    public static buyOnWhatsapp(state: ContactDataStateModel) {
        return state.buyOnWhatsapp;
    }

    @Selector()
    public static getLoading(state: ContactDataStateModel) {
        return state.loading;
    }

    @Action(GetContactData)
    public getContactData({ patchState }: StateContext<ContactDataStateModel>) {
        patchState({
            loading: true,
        });
        return this.configurationService.getBasicInfo().pipe(
            tap((response: any) => {
                if (response) {
                    const checkResponse = this.responseHelper.checkError(response);
                    if (checkResponse.success) {
                        const {
                            emailcontacto,
                            telefono,
                            whatsapp,
                            redesSociales,
                            buyonwhatsapp,
                            whatsappbusiness,
                            mostraremail,
                            botonWhatsapp,
                            direccion,
                        } = response.data;

                        let countryCodeWp, countryCodePhoneNumber, countryCodeWpBusiness, address;

                        const alpha2CodeBO = this.store.selectSnapshot(AppState.getConfig)?.isoPais;
                        if (alpha2CodeBO) {
                            const countryFromBO = utils.getCountryFlag(alpha2CodeBO);
                            countryCodeWp = countryFromBO;
                            countryCodePhoneNumber = countryFromBO;
                            countryCodeWpBusiness = countryFromBO;
                            address = countryFromBO?.id;
                        }
                        if (whatsapp) countryCodeWp = utils.getCountryFlag(whatsapp);
                        if (telefono) countryCodePhoneNumber = utils.getCountryFlag(telefono);
                        if (whatsappbusiness) countryCodeWpBusiness = utils.getCountryFlag(whatsappbusiness);
                        patchState({
                            contactEmail: emailcontacto,
                            phone: telefono,
                            whatsapp: whatsapp,
                            socialMedia: redesSociales,
                            buyOnWhatsapp: buyonwhatsapp,
                            whatsappBusiness: whatsappbusiness,
                            showEmail: mostraremail,
                            address: direccion,
                            countryCodeWp: countryCodeWp,
                            countryCodeWpBusiness: countryCodeWpBusiness,
                            countryCodePhoneNumber: countryCodePhoneNumber,
                            countryFromBO: address,
                        });
                        if (botonWhatsapp) {
                            botonWhatsapp[0].mostrar_boton_whatsapp_flotante == '1'
                                ? patchState({ showFloatWhatsappButton: true })
                                : patchState({ showFloatWhatsappButton: false });
                            botonWhatsapp[1].alinear_izquierda_boton_whatsapp == '1'
                                ? patchState({ alignLeftWhatsappButton: true })
                                : patchState({ alignLeftWhatsappButton: false });
                            botonWhatsapp[2].whatsapp_compartir_url_producto == '1'
                                ? patchState({ shareUrlProductWhatsapp: true })
                                : patchState({ shareUrlProductWhatsapp: false });
                            patchState({
                                defaultTextWhatsapp: botonWhatsapp[3].whatsapp_texto_predeterminado,
                                loading: false,
                            });
                        }
                    }
                }
            }),
            catchError((error) => {
                patchState({ loading: false, loadingFormSubmit: false });
                throw error;
            })
        );
    }

    @Action(SetContactData)
    public setContactData({ patchState }: StateContext<ContactDataStateModel>, { contactData }: SetContactData) {
        patchState({ loadingFormSubmit: true });
        return this.configurationService.updateContactInfo(contactData).pipe(
            tap((response: any) => {
                if (response) {
                    const checkResponse = this.responseHelper.checkError(response);
                    if (checkResponse.success) {
                        const {
                            emailcontacto,
                            telefono,
                            whatsapp,
                            redesSociales,
                            buyonwhatsapp,
                            whatsappbusiness,
                            mostraremail,
                            direccion,
                        } = response.data;
                        patchState({
                            contactEmail: emailcontacto,
                            phone: telefono,
                            whatsapp: whatsapp,
                            socialMedia: redesSociales,
                            buyOnWhatsapp: buyonwhatsapp,
                            whatsappBusiness: whatsappbusiness,
                            showEmail: mostraremail,
                            address: direccion,
                            loading: false,
                            loadingFormSubmit: false,
                        });
                    }
                    this.mcToast.success(this.translate.instant('app.global.toast.operacion_exitosa'));
                }
            }),
            catchError((error) => {
                this.mcToast.danger(this.translate.instant('app.global.toast.operacion_erronea_confirmacion'), 'large');
                patchState({ loadingFormSubmit: false });
                throw error;
            })
        );
    }

    @Action(SetWhatsappButton)
    public setWhatsappButton(
        { patchState }: StateContext<ContactDataStateModel>,
        { whatsappButton }: SetWhatsappButton
    ) {
        return this.configurationService.setWhatsappData(whatsappButton).pipe(
            tap((response: any) => {
                if (response) {
                    const checkResponse = this.responseHelper.checkError(response);
                    if (checkResponse.success) {
                        const whatSapConfig = response.data.botonWhatsapp;
                        whatSapConfig[0].mostrar_boton_whatsapp_flotante == '1'
                            ? patchState({ showFloatWhatsappButton: true })
                            : patchState({ showFloatWhatsappButton: false });
                        whatSapConfig[1].alinear_izquierda_boton_whatsapp == '1'
                            ? patchState({ alignLeftWhatsappButton: true })
                            : patchState({ alignLeftWhatsappButton: false });
                        whatSapConfig[2].whatsapp_compartir_url_producto == '1'
                            ? patchState({ shareUrlProductWhatsapp: true })
                            : patchState({ shareUrlProductWhatsapp: false });
                        patchState({ defaultTextWhatsapp: whatSapConfig[3].whatsapp_texto_predeterminado });
                    }
                }
            }),
            catchError((error) => of(error))
        );
    }

    @Action(SetCountryCodePhoneNumber)
    public setCountryCodePhoneNumber(
        { patchState, getState }: StateContext<ContactDataStateModel>,
        { param }: SetCountryCodePhoneNumber
    ) {
        if (param) {
            patchState({ countryCodePhoneNumber: param });
        }
    }

    @Action(SetCountryCodeWhatSapp)
    public setCountryCodeWhatSapp(
        { patchState, getState }: StateContext<ContactDataStateModel>,
        { param }: SetCountryCodeWhatSapp
    ) {
        if (param) {
            patchState({ countryCodeWp: param });
        }
    }

    @Action(SetCountryCodeWhatSappBusiness)
    public setCountryCodeWhatSappBusiness(
        { patchState, getState }: StateContext<ContactDataStateModel>,
        { param }: SetCountryCodeWhatSappBusiness
    ) {
        patchState({ countryCodeWpBusiness: param });
    }

    @Action(SetLoading)
    public setLoading({ patchState }: StateContext<ContactDataStateModel>, { param }: SetCountryCodeWhatSappBusiness) {
        patchState({ loading: param });
    }
}
