import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, switchMap } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import firebase from 'firebase/compat/app';
import { McToastService } from '../mc-toast.service';
import { ApiService } from '../api.service';
import { CommomHeaders } from '@app/_shared/httpHeaders/CommonHeaders';
import { Store } from '@ngxs/store';
import { AddNotification, SaveToken } from '@app/pages/dashboard/store/dashboard.actions';
import { EmitterService } from '@app/_shared/emitterService/emitter-service';
import { NOTIFICATION_TYPE } from '@app/_interfaces/notification.interface';
import { environment } from '@env/environment.local';

@Injectable()
export class MessagingService {
    currentMessage = new BehaviorSubject<firebase.messaging.MessagePayload>(null);
    fcmToken = new BehaviorSubject<string>(null);
    private _registration: any;

    constructor(
        private angularFireMessaging: AngularFireMessaging,
        private toast: McToastService,
        private api: ApiService,
        private headers: CommomHeaders,
        private store: Store,
        private emitterService: EmitterService
    ) {
        this.emitterService.newBackgroundNotification.subscribe((payload) => {
            this.currentMessage.next(payload);
            this.toast.info(`${payload.notification.title}\n${payload.notification.body}`, 'large');
            this.store.dispatch(new AddNotification(payload));
        });

        if ('serviceWorker' in navigator) {
            const serviceWorker = navigator.serviceWorker;
            serviceWorker.register('firebase-messaging-sw.js').then((registration) => {
                this._registration = registration;
            });

            serviceWorker.addEventListener('message', function (event) {
                if (!document.hasFocus()) {
                    // console.log('⛔️ window does NOT have focus >>> serviceWorker.addEventListener');
                    if (event?.data?.isFirebaseMessaging) {
                        if (environment.showLogs) {
                            const now = new Date();
                            console.log(
                                `New Background Message from firebase-messaging-sw.js: (event.data) ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()} ${JSON.stringify(
                                    event.data,
                                    null,
                                    2
                                )}`
                            );
                        }

                        const notificationSiteId = event?.data?.data?.site_id;
                        const site_id = JSON.parse(sessionStorage.getItem('marketId'));

                        if (parseInt(site_id) !== parseInt(notificationSiteId)) return;

                        emitterService.newBackgroundNotification.emit(event.data);
                        const notificationTitle = event?.data?.notification?.title;
                        let notificationUrl;
                        switch (event?.data?.data?.type) {
                            case NOTIFICATION_TYPE.NEW_CLIENT:
                                notificationUrl = `${self.location.origin}/admin/e-commerce/customer-management/details/client/${event?.data?.data?.entity_id}`;
                                break;

                            case NOTIFICATION_TYPE.NEW_SALE:
                            case NOTIFICATION_TYPE.PURCHASE_CANCELATION_REQUEST:
                                notificationUrl = `${self.location.origin}/admin/e-commerce/sales/edit/${event?.data?.data?.entity_id}`;
                                break;

                            default:
                                notificationUrl = `${self.location.origin}/admin/dashboard`;
                        }
                        const notificationOptions = {
                            body: event?.data?.notification?.body,
                            icon: event?.data?.notification?.icon,
                            click_action: notificationUrl,
                        };

                        let notification = new Notification(notificationTitle, notificationOptions);
                        notification.onclick = function (event) {
                            event.preventDefault(); // prevent the browser from focusing the Notification's tab
                            window.open(notificationOptions.click_action, '_blank');
                            notification.close();
                        };
                    }
                } else {
                    // console.log('✅ window has focus >>> serviceWorker.addEventListener');
                }
            });
        }
    }

    /*** This function is used to request notification permission from web page ***/
    // NotificationPermission
    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe({
            next: (token) => {
                // console.log('TOKEN >>> ', token);
                this.setFcmToken(token);
                this.store.dispatch(new SaveToken(token));
                this.receiveMessaging();
            },
            error: (err) => {
                console.log('Unable to get permission to notify... ', err);
            },
        });
    }

    /*** This function will receive notification from firebase ***/
    receiveMessaging() {
        this.tokenChanges();
        this.angularFireMessaging.messages.subscribe({
            next: (payload: firebase.messaging.MessagePayload) => {
                this._registration?.getNotifications().then((notifications) => {
                    notifications.forEach((notification) => notification.close());
                });
                if (document.hasFocus()) {
                    if (environment.showLogs) {
                        console.log('New message received >>> ', payload);
                    }
                    if (!this.validateSiteId(payload.data.site_id)) return;
                    // console.log('✅ window has focus >>> receiveMessaging');
                    this.currentMessage.next(payload);
                    this.toast.info(`${payload.notification.title}\n${payload.notification.body}`, 'large');
                    this.store.dispatch(new AddNotification(payload));
                }
            },
        });
    }

    getToken() {
        this.angularFireMessaging.getToken.subscribe({
            next: (token) => {
                // console.log('getToken >>> TOKEN >>> ', token);
                this.fcmToken.next(token);
            },
        });
    }

    requestToken() {
        this.angularFireMessaging.requestToken
            .pipe(
                map((token) => this.angularFireMessaging.getToken),
                switchMap((token) => {
                    return token;
                })
            )
            .subscribe({
                next: (token) => {
                    const currentFcmToken = localStorage.getItem('fcmToken');
                    if (currentFcmToken !== token) {
                        this.setFcmToken(token);
                        this.store.dispatch(new SaveToken(token));
                        this.fcmToken.next(token);
                    }
                },
                error: (error) => {
                    console.error(error);
                },
            });
    }

    tokenChanges() {
        this.angularFireMessaging.tokenChanges.subscribe({
            next: (token) => {
                const currentFcmToken = localStorage.getItem('fcmToken');
                if (currentFcmToken !== token) {
                    this.setFcmToken(token);
                    this.store.dispatch(new SaveToken(token));
                    this.fcmToken.next(token);
                }
            },
            error: (error) => {
                console.error(error);
            },
        });
    }

    setFcmToken(token: string): void {
        localStorage.setItem('fcmToken', token);
        this.fcmToken.next(token);
    }

    getNotifications(): Observable<any> {
        return this.api.getFz(`/api/v1/back/notifications`, this.headers.getAuthHeaders());
    }

    deleteNotification(id: number) {
        return this.api.delete(`/api/v1/back/notifications`, { id }, this.headers.getAuthHeaders());
    }

    clearAllNotifications(): Observable<any> {
        return this.api.delete(`/api/v1/back/notifications/all`, {}, this.headers.getAuthHeaders());
    }

    saveToken(token: string) {
        return this.api.postFz(`/api/v1/back/notifications/token`, { token }, this.headers.getAuthHeaders());
    }

    private validateSiteId(notificationSiteId): boolean {
        const site_id = JSON.parse(sessionStorage.getItem('marketId'));
        return parseInt(site_id) === parseInt(notificationSiteId);
    }
}
