<div *ngIf="loading" class="d-flex justify-content-center align-items-center h-100">
    <mc-loading></mc-loading>
</div>

<img
    [ngStyle]="{ display: loading ? 'none' : 'block' }"
    [ngClass]="imgClass"
    [src]="src"
    [alt]="alt"
    (load)="setLoading()"
    (error)="setLoading($event)"
/>
