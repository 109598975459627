import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../_guards';
import { InternalServerErrorComponent } from '@app/pages/error-page/internal-server-error/internal-server-error.component';
import { NotFoundComponent } from '@app/pages/error-page/not-found/not-found.component';
import { MenuItemGuard } from '@app/_guards/menuItem.guard';
import { PathMatch } from '@app/_models/app/routes.model';
import { BlogGuard } from '@app/_guards/blog.guard';

const routes: Routes = [
    {
        path: ':isoLangCtry',
        children: [
            /** Ingresar */
            {
                path: 'ingresar',
                loadChildren: () => import('../pages/ingresar/ingresar.module').then((m) => m.IngresarModule),
            },

            /** Dashboard */
            {
                path: 'dashboard',
                loadChildren: () => import('../pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
                canActivate: [AuthGuard],
            },

            /** WebSite */
            {
                path: 'website',
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'pages',
                        loadChildren: () =>
                            import('../pages/web-site/pages/site-pages.module').then((m) => m.SitePagesModule),
                        data: {
                            breadcrumbs: true,
                            text: 'Pages',
                        },
                    },
                    {
                        path: 'basic-info',
                        loadChildren: () =>
                            import('../pages/web-site/basic-info/basic-info.module').then((m) => m.BasicInfoModule),
                        data: {
                            breadcrumbs: true,
                            text: 'Basic info',
                        },
                    },
                    {
                        path: 'design',
                        loadChildren: () =>
                            import('../pages/web-site/design/design.module').then((m) => m.DesignModule),
                        data: {
                            breadcrumbs: true,
                            text: 'Design',
                        },
                    },
                    {
                        path: 'contact-data',
                        loadChildren: () =>
                            import('../pages/web-site/contact-data/contact-data.module').then(
                                (m) => m.ContactDataModule
                            ),
                        data: {
                            breadcrumbs: true,
                            text: 'Contact data',
                        },
                    },
                    {
                        path: 'settings',
                        loadChildren: () =>
                            import('../pages/web-site/settings/settings.module').then((m) => m.SettingsModule),
                        data: {
                            breadcrumbs: true,
                            text: 'Settings',
                        },
                    },
                    {
                        path: 'queries',
                        loadChildren: () =>
                            import('../pages/web-site/queries/queries.module').then((m) => m.QueriesModule),
                        data: {
                            breadcrumbs: true,
                            text: 'Pages',
                        },
                    },
                ],
            },

            /** Herramientas */
            {
                path: 'tools',
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'integrations',
                        loadChildren: () =>
                            import('../pages/tools/integrations/integrations.module').then((m) => m.IntegrationsModule),
                        data: {
                            breadcrumbs: true,
                            text: 'Integrations',
                        },
                    },
                    {
                        path: 'seo-tools',
                        loadChildren: () =>
                            import('../pages/tools/seo-tools/seo-tools.module').then((m) => m.SeoToolsModule),
                        data: {
                            breadcrumbs: true,
                            text: 'SeoTools',
                        },
                    },
                ],
            },

            /** Estadísticas */
            {
                path: 'statistics',
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import('../pages/statistics/website/statistics.module').then((m) => m.StatisticsModule),
                        data: {
                            breadcrumbs: true,
                            text: 'Website statistics',
                        },
                    },
                ],
            },

            /** Domains */
            {
                path: 'domains',
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        loadChildren: () => import('../pages/domains/domains.module').then((m) => m.DomainsModule),
                        data: {
                            breadcrumbs: true,
                            text: 'Correos',
                        },
                    },
                ],
            },
            {
                path: 'e-mails',
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        loadChildren: () => import('../pages/emails/emails.module').then((m) => m.EmailsModule),
                        data: {
                            breadcrumbs: true,
                            text: 'Emails',
                        },
                    },
                ],
            },

            /** Perfil **/
            {
                path: 'usuario',
                canActivate: [AuthGuard],
                loadChildren: () => import('../pages/usuario/usuario.module').then((m) => m.UsuarioModule),
                data: {
                    breadcrumbs: false,
                },
            },

            /** TEST **/
            {
                path: 'test',
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        loadChildren: () => import('../pages/test/test.module').then((m) => m.TestModule),
                        data: {
                            breadcrumbs: true,
                            text: 'Sección test',
                        },
                    },
                ],
            },
            // E-commerce
            {
                path: 'e-commerce',
                canActivate: [AuthGuard],
                // canActivateChild: [MenuItemGuard],
                children: [
                    {
                        path: 'sales',
                        loadChildren: () =>
                            import('../pages/e-commerce/catalog/sales/sales.module').then((m) => m.SalesModule),
                        data: {
                            breadcrumbs: true,
                            text: 'Pages',
                        },
                    },
                    {
                        path: 'products',
                        loadChildren: () =>
                            import('../pages/e-commerce/catalog/products/products.module').then(
                                (m) => m.ProductsModule
                            ),
                        data: {
                            breadcrumbs: true,
                            text: 'Pages',
                        },
                    },
                    {
                        path: 'categories',
                        loadChildren: () =>
                            import('../pages/e-commerce/catalog/categories/categories.module').then(
                                (m) => m.CategoriesModule
                            ),
                        data: {
                            breadcrumbs: true,
                            text: 'Pages',
                        },
                    },
                    {
                        path: 'tags',
                        loadChildren: () =>
                            import('../pages/e-commerce/catalog/tags/tags.module').then((m) => m.TagsModule),
                        data: {
                            breadcrumbs: true,
                            text: 'Pages',
                        },
                    },
                    {
                        path: 'sales-channels',
                        loadChildren: () =>
                            import('../pages/e-commerce/catalog/sales-channels/sales-channels.module').then(
                                (m) => m.SalesChannelsModule
                            ),
                        data: {
                            breadcrumbs: true,
                            text: 'Sales Channels',
                        },
                    },
                    {
                        path: 'shipping-methods',
                        loadChildren: () =>
                            import('../pages/e-commerce/catalog/shipping-methods/shipping-methods.module').then(
                                (m) => m.ShippingMethodsModule
                            ),
                        data: {
                            breadcrumbs: true,
                            text: 'Shipping Methods',
                        },
                    },
                    {
                        path: 'configurations',
                        loadChildren: () =>
                            import('../pages/e-commerce/settings/ecommerce-settings.module').then(
                                (m) => m.EcommerceSettingsModule
                            ),
                        data: {
                            breadcrumbs: true,
                            text: 'Pages',
                        },
                    },
                    {
                        path: 'payment-methods',
                        loadChildren: () =>
                            import('../pages/e-commerce/catalog/payment-methods/payment-methods.module').then(
                                (m) => m.PaymentMethodsModule
                            ),
                        data: {
                            breadcrumbs: true,
                            text: 'Pages',
                        },
                    },
                    {
                        path: 'customer-management',
                        loadChildren: () =>
                            import('../pages/e-commerce/catalog/customer-management/customer-management.module').then(
                                (m) => m.CustomerManagementModule
                            ),
                        data: {
                            breadcrumbs: true,
                            text: 'Pages',
                        },
                    },
                    {
                        path: 'discount-coupons',
                        loadChildren: () =>
                            import('../pages/e-commerce/catalog/discount-coupons/discount-coupons.module').then(
                                (m) => m.DiscountCouponsModule
                            ),
                        data: {
                            breadcrumbs: true,
                            text: 'Pages',
                        },
                    },
                ],
            },
            /** BLOG **/
            {
                path: 'blog',
                canActivate: [BlogGuard],
                canLoad: [AuthGuard],
                children: [
                    {
                        path: 'entries',
                        loadChildren: () => import('../pages/blog/entries/blog.module').then((m) => m.BlogModule),
                        data: {
                            breadcrumbs: true,
                            text: 'Pages',
                        },
                    },
                    {
                        path: 'categories',
                        loadChildren: () =>
                            import('../pages/blog/categories/blogcategories.module').then((m) => m.BlogModule),
                        data: {
                            breadcrumbs: true,
                            text: 'Pages',
                        },
                    },
                    {
                        path: 'configuration',
                        loadChildren: () =>
                            import('../pages/blog/config/blog-configuration.module').then(
                                (m) => m.BlogConfigurationModule
                            ),
                        data: {
                            breadcrumbs: true,
                            text: 'Pages',
                        },
                    },
                ],
            },
            {
                path: '500',
                component: InternalServerErrorComponent,
            },
            {
                path: '404',
                component: NotFoundComponent,
            },
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full' as PathMatch,
            },
            {
                path: '**',
                redirectTo: '404',
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes /*, TODO: Angular > 6.1 {scrollPositionRestoration: 'enabled'}*/, {
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutesModule {}
