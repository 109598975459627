import { Directionality } from '@angular/cdk/bidi';
import { CdkStepper } from '@angular/cdk/stepper';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { Step } from '@app/_models/steps/step.model';
import { StepperHelper } from '@app/_shared/helpers/StepperHelper';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SetCurrentStep } from './store/stepper.actions';
import { StepperState } from './store/stepper.state';
@Component({
    selector: 'ss-stepper',
    templateUrl: './ss-stepper.component.html',
    styleUrls: ['./ss-stepper.component.css'],
    providers: [{ provide: CdkStepper, useExisting: SsStepperComponent }],
})
export class SsStepperComponent extends CdkStepper implements OnInit {
    stepForStorage: Step;
    stepperStatusLoaded: any;
    @Select(StepperState.getCurrentStep)
    currentStep$: Observable<number>;

    @Select(StepperState.getStepperGeneralStatus)
    stepperGeneralStatusResponse$: Observable<any>;

    constructor(
        dir: Directionality,
        changeDetectorRef: ChangeDetectorRef,
        _elementRef: ElementRef<HTMLElement>,
        private stepperHelper: StepperHelper,
        private store: Store
    ) {
        super(dir, changeDetectorRef, _elementRef);
    }

    ngOnInit() {
        this.rememberIndex();
    }

    selectStepByIndex(index: number): void {
        this.selectedIndex = index;
    }

    _stateChanged() {
        setTimeout(() => {
            this.stepperHelper.setCurrentStepIndex(this.selectedIndex);
            this.store.dispatch(new SetCurrentStep(this.selectedIndex));
        });
    }
    rememberIndex(): void {
        this.stepperHelper.getCurrentStepIndex()
            ? (this.selectedIndex = this.stepperHelper.getCurrentStepIndex())
            : (this.selectedIndex = 0);
    }
}
