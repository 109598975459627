import { environment } from '@env/environment.local';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CountryCode } from '@app/_models/countries/countryCode.model';

@Component({
    selector: 'mc-select-cod-country',
    templateUrl: './mc-select-cod-country.component.html',
    styleUrls: ['./mc-select-cod-country.component.css'],
})
export class McSelectCodCountryComponent {
    @Input() idSelect: string;
    @Input() countries: CountryCode[];
    @Input() defaultCountry: CountryCode;
    @Input() nameKey: string = 'name';
    @Output() countrySelected: EventEmitter<CountryCode> = new EventEmitter<CountryCode>();
    assetsUrl: string = environment.assets_url;
    mfsPath: string = environment.mfs_path;
    valueCode: string;
    srcImg: string;
    countryName: string;

    constructor() {}

    //Method change button stuff on click
    selectOption = (elem) => {
        this.valueCode = elem.numericCode;
        this.srcImg = elem.image_url;
        this.countryName = elem[this.nameKey];
        this.toggleClass();
        this.countrySelected.emit(elem);
    };

    toggleClass() {
        document.getElementById(this.idSelect)?.classList.toggle('d-none');
    }

    hideSelect() {
        document.getElementById(this.idSelect)?.classList.add('d-none');
    }
}
