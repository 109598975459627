import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'ss-form-group-input',
    templateUrl: './ss-form-group-input.component.html',
    styleUrls: ['./ss-form-group-input.component.css'],
})
export class SsFormGroupInputComponent implements OnInit, OnChanges {
    @Input() form: UntypedFormGroup;
    @Input() name: string;
    @Input() type: 'text' | 'number' | 'email' | 'password' = 'text';
    @Input() placeholder = '';
    @Input() label: string;
    @Input() labelClass: string = '';
    @Input() size: 'sm' | 'lg';
    @Input() submitted: boolean;
    @Input() formText = '';
    @Input() patternValidationMessage = '';
    @Input() focus: boolean;
    @Input() autocomplete: 'on' | 'off' = 'on';
    @Input() formGroupClass: '';
    @Input() formControlClass: '';
    @Input() icon: string;
    @Input() iconPrefix: string;
    @Input() disabled: boolean = false;
    @Input() negativeNumber: boolean = false;
    @Input() hasNegativeValue: boolean = false;
    @Input() tooltipText: string;
    @Input() inputDescription: string;
    @Input() tooltipCustomClass: string;
    @Input() numericCodeNumber: string;
    @Input() currencyString: string;
    @Input() sublabel: string;
    @Input() trigger: string = 'click';
    @Input() negativeValue: boolean = false;
    @Output() emmitFocusOut: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() labelAction: EventEmitter<boolean> = new EventEmitter<boolean>();

    pending: boolean;
    pendingSubscription: Subscription;
    value: string;
    hasChange: boolean;
    isPassword: boolean;
    paddingNumericCode: string = '.75rem';
    numberMaxErrorMsg: string = 'Menor o igual a ';

    constructor(private sanitizer: DomSanitizer) {
        this.iconPrefix = 'fas';
    }

    get control(): AbstractControl {
        return this.form.get(this.name);
    }

    get formTextContent(): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(this.formText);
    }

    ngOnInit(): void {
        this.isPassword = this.type === 'password';
        this.pendingSubscription = this.control.statusChanges.subscribe((status) => {
            this.pending = status === 'PENDING' || false;
        });
        this.setPaddingNumericCode(this.numericCodeNumber);
        this.setPaddingNumericCode(this.currencyString);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.numericCodeNumber) this.setPaddingNumericCode(this.numericCodeNumber);
        if (changes.currencyString) this.setPaddingNumericCode(this.currencyString);
    }

    isPending(): Observable<boolean> {
        return this.control.statusChanges.pipe(map((status) => status === 'PENDING'));
    }

    isRequiredControl(): boolean {
        const fieldValidators = this.form.controls[`${this.name}`].validator;
        const isRequired = fieldValidators && fieldValidators.hasOwnProperty('required');
        return isRequired;
    }

    toggleTypePassword(): void {
        this.type = this.type === 'password' ? 'text' : 'password';
    }

    onChangeValue(value: string): void {
        this.value = value;
        if (parseFloat(value) < 0) {
            this.hasNegativeValue = true;
        } else {
            this.hasNegativeValue = false;
        }
        this.hasChange = true;
    }

    setPaddingNumericCode(prefix): void {
        const elem = prefix;
        if (elem) {
            if (elem.length === 1) this.paddingNumericCode = '2rem';
            if (elem.length === 2) this.paddingNumericCode = '2.5rem';
            if (elem.length === 3) this.paddingNumericCode = '3rem';
        }
    }

    focusOut(event): void {
        this.emmitFocusOut.emit(true);
    }

    lavelEvent(): void {
        this.labelAction.emit(true);
    }

    onWheelEvent(event: WheelEvent): void {
        event.preventDefault();
    }
}
