export * from './cbu.validator';
export * from './check-password.validator';
export * from './credit-card.validator';
export * from './domain.validator';
export * from './email-password.validator';
export * from './email.validator';
export * from './only-numbers.validator';
export * from './password.ferozo.validator';
export * from './password.validator';
export * from './phone-number.validator';
export * from './social-media.validator';
export * from './url.validator';
export * from './whatSapp.validator';
export * from './wp.validator';
export * from './username.validator';
export * from './cost.validator';
export * from './price.validator';
