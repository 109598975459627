import {
    CreateListPayload,
    ManualSyncPayload,
    UpdateConectionPayload,
} from '@app/_models/integrations/envialo-simple.model';

export class SetIntegrationsNav {
    public static readonly type = '[Integrations] SetIntegrationsNav';
}

export class ToogleExecuteSubmit {
    public static readonly type = '[Integrations] ToogleExecuteSubmit';
    constructor(public execute: boolean) {}
}
export class SetLoadingFormSubmitIntegrationAction {
    public static readonly type = '[Integrations] SetLoadingFormSubmitIntegrationAction';
    constructor(public data: boolean) {}
}

export class GetEnvialoSimpleMailList {
    public static readonly type = '[Integrations] GetenvialoSimpleMailList';
}

export class GetEnvialoSimpleConfig {
    public static readonly type = '[Integrations] GetenvialoSimpleConfig';
}

export class CreateContactList {
    public static readonly type = '[Integrations] CreateContactList';
    constructor(public payload: CreateListPayload) {}
}

export class SetEnvialoSimpleConfig {
    public static readonly type = '[Integrations] SetEnvialoSimpleConfig';
    constructor(public payload: UpdateConectionPayload) {}
}

export class ManualSync {
    public static readonly type = '[Integrations] ManualSync';
    constructor(public payload: ManualSyncPayload) {}
}
