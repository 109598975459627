import { Injectable } from '@angular/core';
import { NavItemInterface } from '@app/_interfaces';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DesignService } from './../../../../_services/design/design.service';
import { ResponseHelper } from './../../../../_shared/helpers/ResponseHelper';
import {
    getSiteStructureAction,
    SetDesignNav,
    SetFalseExecuteAction,
    setSiteStructureAction,
    ToggleExecuteAction,
    SetLoadingFormSubmitAction,
    SetTemplatesLoading,
} from './design.actions';

export interface DesignStateModel {
    nav: NavItemInterface[];
    siteStructure: any;
    executeAction: boolean;
    loadingFormSubmit: boolean;
    templatesLoading: boolean;
}

@State<DesignStateModel>({
    name: 'design',
    defaults: {
        nav: [],
        siteStructure: '',
        executeAction: false,
        loadingFormSubmit: false,
        templatesLoading: false,
    },
})
@Injectable()
export class DesignState {
    constructor(
        private designService: DesignService,
        private responseHelper: ResponseHelper,
        private translate: TranslateService
    ) {}

    @Selector()
    public static getState(state: DesignStateModel) {
        return state;
    }

    @Selector()
    public static nav(state: DesignStateModel) {
        return state.nav;
    }

    @Selector()
    public static getLoadingFormSubmit(state: DesignStateModel) {
        return state.loadingFormSubmit;
    }

    @Selector()
    public static getSiteStructure(state: DesignStateModel) {
        return state.siteStructure;
    }

    @Selector()
    public static getExecuteAction(state: DesignStateModel) {
        return state.executeAction;
    }

    @Selector()
    public static templatesLoading(state: DesignStateModel) {
        return state.templatesLoading;
    }

    @Action(getSiteStructureAction)
    public getSiteStructureAction({ getState, patchState, dispatch }: StateContext<DesignStateModel>) {
        return this.designService.getSiteStructure().pipe(
            tap((res: any) => {
                if (res) {
                    const checkResponse = this.responseHelper.checkError(res);
                    if (checkResponse.success) {
                        patchState({ siteStructure: res.data });
                    }
                }
            }),
            catchError((error) => of(error))
        );
    }

    @Action(setSiteStructureAction)
    public setSiteStructureAction(
        { getState, patchState }: StateContext<DesignStateModel>,
        { data }: setSiteStructureAction
    ) {
        patchState({ loadingFormSubmit: true });
        return this.designService.setSiteStructure(data).pipe(
            tap(
                (res: any) => {
                    if (res) {
                        const checkResponse = this.responseHelper.checkError(res);
                        if (checkResponse.success) {
                            patchState({ siteStructure: res.data, loadingFormSubmit: false });
                        }
                    }
                },
                (err) => of(err)
            )
        );
    }

    @Action(SetDesignNav)
    public setNav({ patchState }: StateContext<DesignStateModel>) {
        return patchState({
            nav: [
                {
                    label: this.translate.instant('app.nav.plantillas'),
                    link: 'templates',
                    icon: 'magic',
                    show: true,
                },
                {
                    label: this.translate.instant('app.nav.themes'),
                    link: 'themes',
                    icon: 'palette',
                    show: true,
                },
                {
                    label: this.translate.instant('app.nav.structure'),
                    link: 'site-structure',
                    icon: 'pager',
                    show: true,
                },
                {
                    label: this.translate.instant('app.nav.header'),
                    link: 'header-customization',
                    icon: 'sliders-h',
                    show: true,
                },
                {
                    label: this.translate.instant('app.nav.footer'),
                    link: 'footer-customization',
                    icon: 'sliders-v',
                    show: true,
                },
                {
                    label: this.translate.instant('app.nav.advanced'),
                    link: 'advanced-edition',
                    icon: 'code',
                    show: true,
                },
                {
                    label: this.translate.instant('app.nav.social-network-image'),
                    link: 'social-network-image',
                    icon: 'image-polaroid',
                    show: true,
                },
            ],
        });
    }

    @Action(ToggleExecuteAction)
    public toggleExecuteAction({ patchState, getState }: StateContext<DesignStateModel>) {
        const state = getState();
        patchState({
            executeAction: true,
        });
    }
    @Action(SetFalseExecuteAction)
    public setFalseExecuteAction({ patchState }: StateContext<DesignStateModel>) {
        patchState({
            executeAction: false,
        });
    }
    @Action(SetLoadingFormSubmitAction)
    public setDesignLoadingFormSubmit(
        { patchState }: StateContext<DesignStateModel>,
        { data }: SetLoadingFormSubmitAction
    ) {
        patchState({
            loadingFormSubmit: data,
        });
    }

    @Action(SetTemplatesLoading)
    public SetTemplatesLoading({ patchState }: StateContext<DesignStateModel>, { data }: SetTemplatesLoading) {
        patchState({
            templatesLoading: data,
        });
    }
}
