<div class="file-upload">
    <div class="icon-file-upload" (click)="fileUpload.click()">
        <div
            *ngIf="!urlLogo && !logoLoaded; else elseBlock"
            id="circle"
            class="d-flex justify-content-center align-items-center"
        >
            <i class="fal fa-camera"></i>
        </div>
        <ng-template #elseBlock>
            <img
                [src]="urlLogo ? urlLogo : logoLoaded"
                id="img-circle"
                [className]="applyFaviconClass ? 'favico' : ''"
            />
        </ng-template>
        <span id="span-circle" class="d-flex justify-content-center align-items-center"
            ><i class="fal fa-plus"></i>
        </span>
        <input type="file" class="file-input" accept="image/*" (change)="onFileSelected($event)" #fileUpload />
    </div>
    <div class="text-danger mt-1 mr-4" *ngIf="errors">
        <small *ngIf="errors['maxSize']">El tamaño de la imagen que cargaste, supera el límite permitido.</small>
    </div>
</div>

<div class="texto-file-upload mt-4" *ngIf="showMessages">
    <mc-loading *ngIf="loading" [overlay]="true"></mc-loading>
    <ng-template [ngIf]="!logoSucess">
        <p class="color-texto">
            ¿Tienes un logo? ¡Agrégalo!
            <i class="fal fa-info-circle">
                <p>Te aconsejamos que subas tu imagen en formato JPG, PNG o BMP y que no supere los 2 MB.</p>
            </i>
        </p>
        <p class="color-texto">Si no lo tienes cerca, no te preocupes puedes hacerlo después</p>
    </ng-template>
    <ng-template [ngIf]="logoSucess">
        <p class="color-texto">Listo! tu sitio tiene logo</p>
    </ng-template>
</div>
