import { Pipe, PipeTransform } from '@angular/core';
import sortBy from 'sort-by';
@Pipe({
    name: 'order',
})
export class OrderPipe implements PipeTransform {
    transform(list: Object[], param?: string): Object[] {
        if (param.length) {
            list.sort(sortBy(param));
        }
        return list;
    }
}
