import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'ss-chip',
    templateUrl: './ss-chip.component.html',
    styleUrls: ['./ss-chip.component.css'],
})
export class SsChipComponent {
    @Input() index: string | number;
    @Input() textChip: string;
    @Output() newEvent = new EventEmitter<any>();

    constructor() {}

    value: any;

    closeChip(value: any) {
        this.newEvent.emit(value);
    }
}
