import { Injectable } from '@angular/core';
import { SocialRed } from '@app/_models/social-media/social-media.model';

@Injectable({
    providedIn: 'root',
})
export class SocialMediaHelper {
    socialMediaList: SocialRed[] = [
        {
            type: 'other',
            regexp: new RegExp(
                /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
            ),
            icon: 'fal fa-globe social-icon',
        },
        {
            type: 'facebook',
            regexp: new RegExp(/(?:https?:\/\/)?(?:www\.)?(?:facebook|fb)\.com/),
            icon: 'fab fa-facebook social-icon',
        },
        {
            type: 'instagram',
            regexp: new RegExp(
                /(?:https?:\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/
            ),
            icon: 'fab fa-instagram social-icon',
        },
        {
            type: 'x-twitter',
            regexp: new RegExp(
                /(?:https?:\/\/)?(?:[A-z]+\.)?twitter\.com\/@?(?!home|sharegexp|privacy|tos)([A-z0-9_]+)\/?/
            ),
            icon: 'fab fa-x-twitter social-icon',
        },
        {
            type: 'youtube',
            // regexp: new RegExp(
            //     /(?:https?:\/\/)?(?:[A-z]+\\.)?youtube.com\/(c|channel|user|watch)(\/|\?)([A-z0-9-\\_]+)\/?/
            // ),
            regexp: new RegExp(
                /(?:https?:\/\/)?(?:[A-z]+\\.)?youtube.com\/(@[\w\d]+$)|(c|channel|user|watch)(\/|\?)([A-z0-9-\\_]+)\/?/
            ),
            icon: 'fab fa-youtube social-icon',
        },
        {
            type: 'linkedin',
            regexp: new RegExp(
                /(?:https?:)?\/\/(?:[\w]+\.)?linkedin\.com\/(in|company)\/(?!<permalink>[\w\-\_À-ÿ%]+)\/?/
            ),
            icon: 'fab fa-linkedin social-icon',
        },
        {
            type: 'tiktok',
            regexp: new RegExp(/(?:https?:\/\/)?(?:www\.)?tiktok\.com/),
            icon: 'fab fa-tiktok social-icon',
        },
    ];

    socialExist(socialUrl) {
        let socialItem: SocialRed;
        this.socialMediaList.forEach((item: SocialRed) => {
            if (item.regexp.test(socialUrl)) {
                socialItem = item;
            }
        });
        return socialItem;
    }
}
