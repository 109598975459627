import { Languages } from '@app/_shared/data/languages';

export class GetAvailablePageLanguagesAction {
    public static readonly type = '[Settings] Get Available Page Languages';
}

export class AddPageLanguageAction {
    static readonly type = '[Settings] Add Page Language';
    constructor(public id: any) {}
}

export class DeletePageLanguageAction {
    public static readonly type = '[Settings] Delete Page Language';
    constructor(public id: any) {}
}

export class setDefaultLanguageAction {
    static readonly type = '[Settings] Set Default Language';
    constructor(public id: { default: boolean; newDefault: boolean }) {}
}

export class setLanguageVisibility {
    static readonly type = '[Settings] Set Language Visibility';
    constructor(public id: any, public visible: boolean) {}
}
