<div class="d-flex flex-column align-items-center justify-content-center page-error">
    <div class="img-container">
        <img src="{{ assetsUrl }}{{ mfsPath }}/img/front/dashboard/Ilus_error_500.svg" />
    </div>
    <div class="txt-container">
        <h2>{{ tuvimos_un_problema_inesperado }}</h2>
        <p>{{ te_pedimos_disculpas }}</p>
    </div>
    <div class="div-btn">
        <a class="btn btn-continue" href="/">{{ volver }}</a>
    </div>
</div>
