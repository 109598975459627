import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'emojis',
})
export class EmojisPipe implements PipeTransform {
    emojis = [
        {
            value: '😁',
            code: ':happy_face:',
        },
        {
            value: '😯',
            code: ':surprised_face:',
        },
        {
            value: '😅',
            code: ':grin_face:',
        },
        {
            value: '😎',
            code: ':cool_face:',
        },
        {
            value: '🙄',
            code: ':hmmm:',
        },
        {
            value: '🤔',
            code: ':thinking:',
        },
        {
            value: '😊',
            code: ':pink:',
        },
        {
            value: '🙈',
            code: ':monkey_closed_eyes:',
        },
        {
            value: '👍',
            code: ':thumb_up:',
        },
        {
            value: '👏',
            code: ':hi5:',
        },
        {
            value: '😍',
            code: ':heart_eyes:',
        },
        {
            value: '💯',
            code: ':100:',
        },
        {
            value: '😥',
            code: ':crying:',
        },
    ];

    transform(text: any, parseToEmoji: boolean): string {
        this.emojis.forEach((emoji) => {
            if (parseToEmoji) {
                text = text.replace(new RegExp(emoji.code, 'g'), emoji.value);
            } else {
                text = text.replace(new RegExp(emoji.value, 'g'), emoji.code);
            }
        });

        return text;
    }
}
