import { Component, Input, OnInit } from '@angular/core';
import { IconPrefix, IconName } from '@fortawesome/fontawesome-common-types';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import * as _ from 'lodash';

@Component({
    selector: 'mc-icon',
    template:
        '<i class="{{ hasIcon ? icon[0] + \' fa-\' + icon[1] : \'\'}} {{prefix}} {{nameClass}} {{sizeClass}}" [class.fa-fw]="fixedWidth"></i>',
})
export class McIconComponent {
    @Input() icon: IconProp;
    @Input() name: IconName;
    @Input() prefix: IconPrefix;
    @Input() size: SizeProp;
    @Input() fixedWidth: boolean;

    get hasIcon() {
        const icon = this.icon as string[];
        return icon && _.isArray(icon) && icon.length === 2;
    }

    get nameClass() {
        return this.name ? `fa-${this.name}` : '';
    }

    get sizeClass() {
        return this.size ? `fa-${this.size}` : '';
    }
}
