import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UntypedFormGroup } from '@angular/forms';

interface CheckItem {
    label: string;
    value: any;
}

@Component({
    selector: 'ss-checkbox',
    templateUrl: './ss-checkbox.component.html',
    styleUrls: ['./ss-checkbox.component.css'],
})
export class SScheckboxComponent {
    @Input() form: UntypedFormGroup;
    @Input() tooltipText: string;
    @Input() tooltipPlacement: string = 'auto';
    @Input() type: 'checkbox' | 'radio' = 'radio';
    @Input() name: string;
    @Input() id: string;
    @Input() checkboxLabel: string;
    @Input() inline = true;
    @Input() formText = '';
    @Input() options: CheckItem[] = [
        { label: 'Sí', value: true },
        { label: 'No', value: false },
    ];
    @Output() emmitValue = new EventEmitter<any>();

    constructor(private sanitizer: DomSanitizer) {}

    get formTextContent() {
        return this.sanitizer.bypassSecurityTrustHtml(this.formText);
    }

    get style() {
        return null;
    }

    onChangeValue(value) {
        this.emmitValue.emit(value);
    }
}
