import { SidebarMenuItem } from '@app/_interfaces';

export class CollapseSidebar {
    public static readonly type = '[Sidebar] CollapseSidebar';
}

export class ExpandSidebar {
    public static readonly type = '[Sidebar] ExpandSidebar';
}

export class ToggleSidebarCollapse {
    public static readonly type = '[Sidebar] ToggleSidebarCollapse';
}

export class ForceCollapseSidebar {
    public static readonly type = '[Sidebar] ForceCollapseSidebar';
}

export class ForceExpandSidebar {
    public static readonly type = '[Sidebar] ForceExpandSidebar';
}

export class GetInitialInfo {
    public static readonly type = '[Sidebar] GetInitialInfo';
    constructor() {}
}

export class ToggleSidebarCollapseForced {
    public static readonly type = '[Sidebar] ToggleSidebarCollapseForced';
}
export class CurrencyAction {
    public static readonly type = '[Sidebar] Get currencies';
}
export class SetSidebarMenu {
    public static readonly type = '[Sidebar]  SetSidebarMenu';
    constructor(public data: any) {}
}
