import { AbstractControl, ValidatorFn } from '@angular/forms';

export function emailPassword(name?: string, domain?: string): ValidatorFn {
    const numberUpperLowerRe: RegExp = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{0,}$/);
    const successiveNumbers: RegExp = new RegExp(
        /(\b(\w*123\w*)\b)|(\b(\w*234\w*)\b)|(\b(\w*345\w*)\b)|(\b(\w*456\w*)\b)|(\b(\w*567\w*)\b)|(\b(\w*678\w*)\b)|(\b(\w*789\w*)\b)/
    );
    return (control: AbstractControl): { [key: string]: any } | null => {
        const hasName = name && control.value && control.value.indexOf(name) > -1;
        const hasDomain = domain && control.value && control.value.indexOf(domain) > -1;
        return (
            (control.value &&
                !(
                    numberUpperLowerRe.test(control.value) &&
                    !successiveNumbers.test(control.value) &&
                    !hasName &&
                    !hasDomain
                ) && { pattern: true }) ||
            null
        );
    };
}
