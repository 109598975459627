import { Params } from '@angular/router';
import { Language } from '@app/_models/languages/language';
import { Template } from '@app/_models/templates/template.model';
import { CustomTheme, SelectedTheme } from '@app/_models/themes/theme.model';
import { WizardItem } from '@app/_models/wizard/wizard-item.model';

export class SetGlobalLoading {
    public static readonly type = '[App] SetGlobalLoading';
    constructor(public value: boolean) {}
}
export class SetActivateTemplateWizard {
    public static readonly type = '[App] SetActivateTemplateWizard';
    constructor(public value: boolean) {}
}

export class UpdateDescriptiveName {
    public static readonly type = '[App] UpdateDescriptiveName';
    constructor(public id: string | number, public descriptiveName: string, public type?: 'hosting-reseller') {}
}

export class GetServiceSummary {
    public static readonly type = '[App] GetServiceSummary';
}

export class SetSession {
    public static readonly type = '[App] SetSession';
    constructor(public session: any) {} // Session
}

export class SetLastVisit {
    public static readonly type = '[App] SetLastVisit';
    constructor(public lastVisit: string) {}
}

export class Logout {
    public static readonly type = '[App] Logout';
}

export class SetIsMobile {
    public static readonly type = '[App] SetIsMobile';
    constructor(public isMobile: boolean) {}
}

export class SetHideSidebar {
    public static readonly type = '[App] SetHideSidebar';
    constructor(public hideSidebar: boolean) {}
}

export class GetCountries {
    public static readonly type = '[App] GetCountries';
    constructor(public force?: boolean) {}
}

export class EmptyCart {
    public static readonly type = '[App] EmptyCart';
}

export class CreateOrder {
    public static readonly type = '[App] CreateOrder';
    constructor(public params: Params) {}
}

export class CancelOrder {
    public static readonly type = '[App] CancelOrder';
    constructor(public id: string) {}
}

export class AddItems {
    public static readonly type = '[App] AddItems';
    constructor(public params: Params) {}
}

export class BuyService {
    public static readonly type = '[App] BuyService';
    constructor(public params: Params, public orderParams?: Params) {}
}

export class ProcessServicePurchase {
    public static readonly type = '[App] ProcessServicePurchase';
    constructor(public params: Params, public orderParams: Params) {}
}

export class ApplyCode {
    public static readonly type = '[App] ApplyCode';
    constructor(public code: string) {}
}

export class StopRequestInProgress {
    public static readonly type = '[App] StopRequestInProgress';
}

export class SetIsOnline {
    public static readonly type = '[App] SetIsOnline';
    constructor(public isOnline: boolean) {}
}

export class SetMarketLanguage {
    public static readonly type = '[App] SetMarketLanguage';
    constructor(public marketLanguage: Language) {}
}
export class GetMainMarket {
    public static readonly type = '[marketActions] GetMainMarket';
}
export class GetGeneralInfoApp {
    public static readonly type = '[marketActions] GetGeneralInfoApp';
}
export class GetVerticalList {
    public static readonly type = '[marketActions] GetVerticalList';
}
export class SetVertical {
    public static readonly type = '[marketActions]  SetVertical';
    constructor(public vertical: Object) {}
}

export class GetMarketLang {
    public static readonly type = '[marketActions] GetMarketLang';
}
export class SetMarketLang {
    public static readonly type = '[marketActions] SetMarketLang';
}

export class GetGeneralData {
    public static readonly type = '[marketActions] GetGeneralData';
}

export class GetContactData {
    public static readonly type = '[marketActions] GetContactData';
}
export class GetInfoDashboard {
    public static readonly type = '[marketActions] GetInfoDashboard';
}

export class GetPagesLanguages {
    public static readonly type = '[SitePages] GetPagesLanguages';
    constructor() {}
}

export class SetProfilePhotoUrl {
    public static readonly type = '[marketActions] SetProfilePhotoUrl';
    constructor(public url: string) {}
}
export class SetDemo {
    public static readonly type = '[App] SetDemo';
    constructor(public setDemo: boolean) {}
}

export class SetLangSelected {
    public static readonly type = '[SitePages] SetLangSelected';
    constructor(public lang: any) {}
}
export class SetPhotoProfileSideBarTemp {
    public static readonly type = '[App] SetPhotoProfileSideBarTemp';
    constructor(public image: string) {}
}
export class SetInternalServerError {
    public static readonly type = '[App] SetInternalServerError';
    constructor(public payload: boolean) {}
}

export class SetIsLogged {
    public static readonly type = '[App] SetIsLogged';
    constructor(public isLogged: boolean) {}
}
export class SetSiteConstruction {
    public static readonly type = '[App] SetSiteConstruction';
    constructor(public siteConstruction: boolean) {}
}
export class GetThemes {
    public static readonly type = '[marketActions] GetThemes';
}
export class SetTheme {
    public static readonly type = '[marketActions] setTheme';
    constructor(public theme: SelectedTheme) {}
}

export class GetCustomThemes {
    public static readonly type = '[App] GetCustomThemes';
    constructor() {}
}
export class AddCustomTheme {
    public static readonly type = '[App] AddCustomTheme';
    constructor(public customTheme: CustomTheme) {}
}

export class UpdateCustomTheme {
    public static readonly type = '[App] UpdateCustomTheme';
    constructor(public customTheme: CustomTheme) {}
}

export class DeleteCustomTheme {
    public static readonly type = '[App] DeleteCustomTheme';
    constructor(public customTheme: CustomTheme) {}
}
export class SetCustomTheme {
    public static readonly type = '[marketActions] SetCustomTheme';
    constructor(public customTheme: SelectedTheme) {}
}
export class GetFonts {
    public static readonly type = '[App] GetFonts';
    constructor() {}
}
export class SetUserName {
    public static readonly type = '[App] SetUserName';
    constructor(public userName: string) {}
}

export class ShowAppVersionBanner {
    public static readonly type = '[App] ShowAppVersionBanner';
    constructor(public show: boolean) {}
}

export class SetVerticalList {
    public static readonly type = '[marketActions] SetVerticalList';
    constructor(public verticalList: Array<WizardItem>) {}
}
