import { State, Action, Selector, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
    AddCustomDomain,
    CheckIfDomainExists,
    CreateDomain,
    DeleteParkedDomain,
    GetOwnDomains,
    SetDomain,
    SetPointToDomain,
    SetSelectedParkDomain,
    SetSuggestDomainsEmpty,
} from './domains.actions';
import { DomainsService } from '@app/_services/domains/domains.service';
import { McToastService } from '@app/_services';
import { Domain } from '@app/_models/pages/domain.model';
import { ResponseHelper } from '@app/_shared/helpers/ResponseHelper';
import { Injectable } from '@angular/core';
import { DomainSuggest } from '@app/_models/pages/domain.suggest.model';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';

export interface DomainsStateModel {
    ownDomains: Domain[];
    domainsAvailable: string[];
    loadingGetOwnsDomains: boolean;
    suggestedDomains: DomainSuggest[];
    pendingDomains: number;
    loadingDomains: {};
}

@State<DomainsStateModel>({
    name: 'domains',
    defaults: {
        ownDomains: [],
        domainsAvailable: ['online', 'site', 'website', 'space', 'store'],
        suggestedDomains: [],
        loadingGetOwnsDomains: false,
        pendingDomains: null,
        loadingDomains: {},
    },
})
@Injectable()
export class DomainsState {
    constructor(
        public domainsService: DomainsService,
        private translate: TranslateService,
        public mcToast: McToastService,
        private responseHelper: ResponseHelper,
        private store: Store
    ) {}

    @Selector()
    public static getState(state: DomainsStateModel) {
        return state;
    }

    @Selector()
    public static getOwnDomains(state: DomainsStateModel) {
        return state.ownDomains;
    }
    @Selector()
    public static getPendingDomains(state: DomainsStateModel) {
        return state.pendingDomains;
    }
    @Selector()
    public static getLoadingOwnDomains(state: DomainsStateModel) {
        return state.loadingGetOwnsDomains;
    }

    @Selector()
    public static getAvailableDomains(state: DomainsStateModel) {
        return state.domainsAvailable;
    }

    @Selector()
    public static getSuggestedDomains(state: DomainsStateModel) {
        return state.suggestedDomains;
    }

    @Selector()
    public static getLoadingDomains(state: DomainsStateModel) {
        return state.loadingDomains;
    }

    @Action(GetOwnDomains)
    public getOwnDomains({ patchState, getState }: StateContext<DomainsStateModel>, { page, size }: GetOwnDomains) {
        const state = getState();
        patchState({
            loadingGetOwnsDomains: true,
        });
        this.domainsService.getOwnDomains(page, size).subscribe({
            next: (res) => {
                patchState({
                    ownDomains: res.data.domains,
                    loadingGetOwnsDomains: false,
                    pendingDomains: res.data.dominios_pendientes,
                });
            },
            error: (error) => {
                this.mcToast.danger(`${this.translate.instant('app.domains.momentaneamente-no-disponible')}.`);
            },
        });
    }

    @Action(SetSelectedParkDomain)
    public setSelectedParkDomain({ patchState, getState }: StateContext<DomainsStateModel>, { payload, showToast }) {
        const state = getState();
        return this.domainsService.selectedParkDomain(payload).pipe(
            tap(
                (res) => {
                    if (this.responseHelper.checkError(res)) {
                        patchState({
                            ownDomains: state.ownDomains.map((domain) => {
                                if (domain.description === payload) {
                                    return {
                                        ...domain,
                                        default: !domain.default,
                                    };
                                } else {
                                    return {
                                        ...domain,
                                        default: false,
                                    };
                                }
                            }),
                        });
                        if (showToast)
                            this.mcToast.success(this.translate.instant('app.domains.dominio-preferido-actualizado'));
                    }
                },
                (error) => {
                    this.mcToast.danger(`${this.translate.instant('app.domains.momentaneamente-no-disponible')}.`);
                    throw error;
                }
            )
        );
    }

    @Action(SetDomain)
    public setDomain({ patchState, dispatch }: StateContext<DomainsStateModel>, { payload }) {
        return this.domainsService.addDomain(payload).pipe(
            tap(
                (res) => {
                    patchState({
                        ownDomains: res.data.domains,
                    });
                    this.mcToast.success(this.translate.instant('app.domains.registrate-tu-dominio'));
                },
                (error) => {
                    this.mcToast.danger(`${this.translate.instant('app.domains.momentaneamente-no-disponible')}`);
                    throw throwError('error');
                }
            )
        );
    }

    @Action(AddCustomDomain)
    public addCustomDomain({ patchState, getState }: StateContext<DomainsStateModel>, { payload }) {
        return this.domainsService.addDomain(payload).pipe(
            tap(
                (res) => {
                    patchState({
                        ownDomains: res.data.domains,
                    });
                    this.mcToast.success(this.translate.instant('app.domains.apuntaste-tu-dominio'));
                },
                (error) => {
                    if (error.error.code === '0000008') {
                        this.mcToast.danger(`${this.translate.instant('app.domains.ya-existe')}`);
                    } else {
                        this.mcToast.danger(`${this.translate.instant('app.domains.no_pudimos_apuntar')}`);
                    }
                }
            )
        );
    }

    @Action(DeleteParkedDomain)
    public deleteParkedDomain({ patchState, getState }: StateContext<DomainsStateModel>, { payload }) {
        const state = getState();
        return this.domainsService.deleteParkedDomain(payload).pipe(
            tap(
                (res) => {
                    patchState({
                        ownDomains: state.ownDomains.filter((domain) => domain.id !== payload.id),
                    });
                    this.mcToast.success(this.translate.instant('app.domains.dominio-borrado'));
                },
                (error) => {
                    this.mcToast.danger(`${this.translate.instant('app.domains.momentaneamente-no-disponible')}`);
                }
            )
        );
    }

    @Action(SetPointToDomain)
    public setPointToDomain({ patchState, getState }: StateContext<DomainsStateModel>, { domain, payload }) {
        const state = getState();
        return this.domainsService.changeService(domain, payload).pipe(
            tap(
                (res) => {
                    if (this.responseHelper.checkError(res)) {
                        patchState({
                            ownDomains: res.data.domains,
                        });
                        this.mcToast.success(this.translate.instant('app.domains.dominio-apuntado-exito'));
                    }
                },
                (error) => {
                    this.mcToast.danger(`${this.translate.instant('app.domains.momentaneamente-no-disponible')}`);
                }
            )
        );
    }

    @Action(CheckIfDomainExists)
    public checkIfDomainExists({ patchState, getState }: StateContext<DomainsStateModel>, { domain }) {
        return this.domainsService.checkAvailability(domain).pipe(
            tap((res) => {
                if (res.data.error) {
                    let availableDomains = [];
                    Object.keys(res.data)
                        .filter((key) => key !== 'error')
                        .map((key) => {
                            res.data[key].dominio !== domain ? availableDomains.push(res.data[key]) : null;
                        });
                    patchState({
                        suggestedDomains: availableDomains,
                    });
                    throw throwError('error');
                } else {
                    patchState({
                        suggestedDomains: [],
                    });
                }
            })
        );
    }

    @Action(SetSuggestDomainsEmpty)
    public setSuggestDomainsEmpty({ patchState }: StateContext<DomainsStateModel>) {
        patchState({
            suggestedDomains: [],
        });
    }
    @Action(CreateDomain)
    public createDomain({ patchState, getState }: StateContext<DomainsStateModel>, { payload }) {
        const loadingDomains = getState().loadingDomains;
        loadingDomains[payload] = true;
        patchState({
            loadingDomains,
        });
        return this.domainsService.createDomain(payload).pipe(
            tap(
                (res) => {
                    const loadingDomains = getState().loadingDomains;
                    loadingDomains[payload] = false;
                    patchState({
                        loadingDomains,
                    });
                    if (res.data.resultado) {
                        this.store.dispatch(new GetOwnDomains());
                        this.mcToast.success(this.translate.instant('app.domains.registrate-tu-dominio'));
                    } else {
                        this.mcToast.danger(`${this.translate.instant('app.domains.no_pudimos_registrar')}`);
                    }
                },
                (error) => {
                    const loadingDomains = getState().loadingDomains;
                    loadingDomains[payload] = false;
                    patchState({
                        loadingDomains,
                    });
                    this.mcToast.danger(`${this.translate.instant('app.domains.no_pudimos_registrar')}`);
                }
            )
        );
    }
}
