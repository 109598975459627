<div class="mb-2" [formGroup]="form">
    <div [ngStyle]="style">
        <ng-container *ngIf="type === 'radio'">
            <div class="form-check" [class.form-check-inline]="inline" *ngFor="let option of options">
                <label class="form-check-label">
                    <input
                        class="form-check-input"
                        type="radio"
                        [formControlName]="name"
                        [name]="name"
                        [value]="option.value"
                    />
                    {{ option.label }}
                </label>
            </div>
        </ng-container>
        <ng-container *ngIf="type === 'checkbox'">
            <div class="form-check">
                <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [formControlName]="name" />
                    <small *ngIf="checkboxLabel">{{ checkboxLabel }}</small>
                    <ng-content select="[checkbox-content]"></ng-content>
                </label>
            </div>
        </ng-container>
    </div>
    <small class="form-text text-muted" *ngIf="formText" [innerHTML]="formTextContent"></small>
</div>
