import { EventEmitter, Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
@Injectable({
    providedIn: 'root',
})
export class EmitterService {
    /*** Customer Management ***/
    openOrderDetail: EventEmitter<number> = new EventEmitter();
    /*** Push Notifications ***/
    setReadedNotification: EventEmitter<firebase.messaging.MessagePayload> = new EventEmitter();
    newBackgroundNotification: EventEmitter<firebase.messaging.MessagePayload> = new EventEmitter();
    clearNotifications: EventEmitter<boolean> = new EventEmitter();
}
