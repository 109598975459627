import packageJson from '../../package.json';

export const environment = {
    production: true,
    authPath: 'https://auth.dev.newss.beer',
    editorPath: 'http://editor.dev.newss.beer',
    brandPath: 'https://sitiosimple.com',
    basePath: '',
    url_slug: 'https://api.develop.c0460474.ferozo.com',
    url_sitio: 'http://qa.c0460474.ferozo.com',
    assets_url: 'https://static.newss.beer',
    mfs_path: '/assets',
    appVersion: packageJson.version,
    fonts_api_key: 'AIzaSyDtKFrlMreyP9OO1Ztm9pxDqIxpdRdm_yc',
    checkAppVersion: false,
    enablePushNotifications: true,
    enableBlog: true,
    firebase: {
        apiKey: 'AIzaSyDtPTqLTA_2aLjxMOZ_-9dE6lQHHV7ZIAY',
        authDomain: 'sitiosimple-push-stagin.firebaseapp.com',
        projectId: 'sitiosimple-push-stagin',
        storageBucket: 'sitiosimple-push-stagin.appspot.com',
        messagingSenderId: '1097740647573',
        appId: '1:1097740647573:web:681f9075cf73410ddf2f9c',
        measurementId: 'G-D2Z7M45DDH',
    },
    showLogs: true,
    name: 'LOCAL',
};
