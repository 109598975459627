import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { CreditCard } from '../_utils';

export function creditCardNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (Validators.required(control) !== undefined && Validators.required(control) !== null) {
            return { ccNumber: true };
        }

        const num = control.value.toString().replace(/\s+|-/g, '');

        if (!/^\d+$/.test(num)) {
            return { ccNumber: true };
        }

        const card = CreditCard.cardFromNumber(num);

        if (!card) {
            return { ccNumber: true };
        }

        if (card.length.includes(num.length) && (card.luhn === false || CreditCard.luhnCheck(num))) {
            return null;
        }

        const upperlength = card.length[card.length.length - 1];
        if (num.length > upperlength) {
            const registeredNum = num.substring(0, upperlength);
            if (CreditCard.luhnCheck(registeredNum)) {
                return null;
            }
        }

        return { ccNumber: true };
    };
}
