<loading-animated *ngIf="isLoading$ | async"></loading-animated>

<div class="wrapper-stepper py-5 pl-5">
    <div class="title-template">
        <h2
            class="color-secundario mb-3"
            [innerHTML]="'app.wizard.social_media.que_datos_de_contacto' | translate"
        ></h2>
        <p class="subtitle-content color-texto2">
            {{ 'app.wizard.social_media.completa_esta_informacion_para_que' | translate }}
        </p>
    </div>
    <div class="w-100 scroll-y-auto mb-4">
        <form class="h-100" [formGroup]="contactDataForm" (ngSubmit)="onFormSubmit()">
            <div class="d-flex h-100">
                <div class="col-md-6 pl-0 pr-3">
                    <!-- Email de contacto -->
                    <div class="col-12 p-0 mb-2">
                        <mc-form-group-input
                            [form]="contactDataForm"
                            name="email"
                            [label]="'app.wizard.social_media.email_de_contacto' | translate"
                            class.form-control-lg="size == 'lg'"
                            class.form-control-sm="true"
                            ngClass="{ 'is-invalid': submitted && controlInvalid }"
                            [submitted]="submitted"
                            [sublabel]="'app.wizard.social_media.este_campo_es_obligatorio' | translate"
                            [placeholder]="'app.wizard.social_media.ejemplo@email.com' | translate"
                        >
                        </mc-form-group-input>
                    </div>
                    <!-- Número para llamadas -->
                    <div class="col-12 p-0 form-group label-input-nro mb-3">
                        <label for="">{{ 'app.wizard.social_media.numero_para_llamadas' | translate }}</label>
                        <div class="d-flex flex-wrap align-items-start">
                            <div class="p-0 pr-1 pr-sm-2">
                                <!-- <mat-select-country
                                    appearance="outline"
                                    [value]="
                                        (countryCodePhoneNumber$ | async)
                                            ? (countryCodePhoneNumber$ | async)
                                            : defaultCountryFlag
                                    "
                                    [countries]="countries"
                                    (onCountrySelected)="selectCountryCodePhone($event)"
                                    autocomplete="no"
                                >
                                </mat-select-country> -->
                                <mc-select-cod-country
                                    [idSelect]="'countryCodePhone'"
                                    [defaultCountry]="
                                        (countryCodePhoneNumber$ | async)
                                            ? (countryCodePhoneNumber$ | async)
                                            : defaultCountryFlag
                                    "
                                    (countrySelected)="selectCountryCodePhone($event)"
                                    [countries]="countries"
                                ></mc-select-cod-country>
                            </div>
                            <div class="col p-0">
                                <mc-form-group-input
                                    autocomplete="chrome-off"
                                    name="phone"
                                    type="number"
                                    [form]="contactDataForm"
                                    [submitted]="submitted"
                                    placeholder="11 62064589"
                                    [numericCodeNumber]="
                                        countryCodePhone?.numericCode ?? defaultCountryFlag.numericCode
                                    "
                                    ngClass="{ 'is-invalid': controlInvalid }"
                                >
                                </mc-form-group-input>
                            </div>
                        </div>
                    </div>
                    <!-- Número para whatsapp -->
                    <div class="col-12 p-0 form-group label-input-nro mb-3">
                        <label for="">{{ 'app.wizard.social_media.numero_para_whatsapp' | translate }}</label>
                        <div class="d-flex flex-wrap align-items-start">
                            <div class="p-0 pr-1 pr-sm-2">
                                <!-- <mat-select-country
                                    appearance="outline"
                                    [value]="(countryCodeWp$ | async) ? (countryCodeWp$ | async) : defaultCountryFlag"
                                    [countries]="countries"
                                    (onCountrySelected)="selectCountryCodeWp($event)"
                                    autocomplete="no"
                                >
                                </mat-select-country> -->
                                <mc-select-cod-country
                                    [idSelect]="'countryCodeWp'"
                                    [defaultCountry]="
                                        (countryCodeWp$ | async) ? (countryCodeWp$ | async) : defaultCountryFlag
                                    "
                                    (countrySelected)="selectCountryCodeWp($event)"
                                    [countries]="countries"
                                ></mc-select-cod-country>
                            </div>
                            <div class="col p-0">
                                <mc-form-group-input
                                    [form]="contactDataForm"
                                    name="whatsapp"
                                    type="number"
                                    class.form-control-lg="size == 'lg'"
                                    class.form-control-sm="true"
                                    ngClass="{ 'is-invalid': submitted && controlInvalid }"
                                    [submitted]="submitted"
                                    autocomplete="chrome-off"
                                    [numericCodeNumber]="countryCodeWp?.numericCode ?? defaultCountryFlag.numericCode"
                                    placeholder="11 62064589"
                                >
                                </mc-form-group-input>
                            </div>
                        </div>
                    </div>
                    <!-- Redes sociales -->
                    <div class="d-flex flex-column" formArrayName="socialMedia">
                        <label for="" class="position-relative color-texto mt-3 mt-lg-2 mt-xl-1 label-redes">{{
                            'app.wizard.social_media.redes_sociales' | translate
                        }}</label>
                        <ng-container *ngFor="let item of getSocialMedia.controls; let i = index">
                            <div class="d-flex flex-row bd-highligh input-group">
                                <div class="position-relative col-11 col-md-12 p-0">
                                    <i
                                        class="input-social-group-left-icon"
                                        [ngClass]="
                                            socialMediaHelper.socialExist(item.value)
                                                ? socialMediaHelper.socialExist(item.value).icon
                                                : 'fal fa-globe default-icon'
                                        "
                                    ></i>
                                    <input
                                        [ngStyle]="{
                                            'padding-left': socialMediaHelper.socialExist(item.value)
                                                ? '2.8rem'
                                                : '1rem'
                                        }"
                                        class="form-control border border-end-0 rounded pr-5 mb-2"
                                        [ngClass]="
                                            item.value
                                                ? item.valid
                                                    ? 'border-green'
                                                    : 'border-red'
                                                : !item.pristine
                                                ? item.value
                                                    ? item.valid
                                                        ? 'border-green'
                                                        : 'border-red'
                                                    : ''
                                                : ''
                                        "
                                        type="text"
                                        [formControlName]="i"
                                        [label]="'app.wizard.social_media.redes_sociales' | translate"
                                        #inputElement
                                        [placeholder]="'app.wizard.social_media.www.ejemplo.com' | translate"
                                    />
                                    <i
                                        class="input-social-group-validation-icon"
                                        [ngClass]="
                                            item.value
                                                ? item.valid
                                                    ? 'fal fa-check-circle text-success'
                                                    : 'fal fa-times-circle text-danger'
                                                : !item.pristine
                                                ? item.value
                                                    ? item.valid
                                                        ? 'fal fa-check-circle text-success'
                                                        : 'fal fa-times-circle text-danger'
                                                    : ''
                                                : ''
                                        "
                                    ></i>
                                    <i
                                        class="fal fa-trash ml-2 color-texto position-absolute social-trash"
                                        (click)="deleteSocialNetwork(i)"
                                        [style.color]="
                                            i === 0 && getSocialMedia.controls.length <= 1 ? '#bdbdbd' : '#666'
                                        "
                                    ></i>
                                </div>

                                <div class="invalid-tooltip position-relative d-block pl-0" *ngIf="item.errors">
                                    <small *ngIf="item.errors.isNotSocial">{{
                                        'app.errores.esta_url_no_es_valida' | translate
                                    }}</small>
                                    <small *ngIf="item.errors.required">{{
                                        'app.errores.este_campo_debe_completarse_o_eliminarse' | translate
                                    }}</small>
                                </div>
                            </div>
                        </ng-container>
                        <div class="d-flex justify-content-end w-100 mt-2 col-11 col-md-12 p-0">
                            <a
                                class="mt-1 add-social-link"
                                [ngClass]="
                                    getSocialMedia.controls[0].value
                                        ? getSocialMedia.status == 'VALID'
                                            ? 'color-link'
                                            : getSocialMedia.dirty && getSocialMedia.controls[0].value
                                            ? 'color-link'
                                            : 'color-link-disabled'
                                        : 'color-link-disabled'
                                "
                                (click)="addSocialNetwork()"
                                >{{ 'app.wizard.social_media.agregar_otra_red_social' | translate }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 pr-0 pl-3">
                    <mc-form-group-input
                        [form]="contactDataForm"
                        name="adress"
                        [label]="'app.wizard.social_media.direccion' | translate"
                        autocomplete="chrome-off"
                        class.form-control-lg="size == 'lg'"
                        class.form-control-sm="true"
                        [placeholder]="'app.wizard.social_media.escribe_tu_direccion' | translate"
                        ngClass="{ 'is-invalid': submitted && controlInvalid }"
                        [submitted]="submitted"
                        [sublabel]="'app.wizard.social_media.utiliza_el_orden' | translate"
                    >
                    </mc-form-group-input>
                    <div class="mapouter mt-2">
                        <iframe class="w-100" frameborder="0" scrolling="no" [src]="direction | safe"></iframe>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="btn-div">
        <button class="btn btn-link color-link" cdkStepperPrevious>
            <i class="fas fa-chevron-left mr-1"></i>{{ 'app.global.modals.volver' | translate }}
        </button>
        <div class="h-100">
            <button [disabled]="!this.contactDataForm.valid" (click)="onFormSubmit()" class="btn btn-continue">
                {{ 'app.global.modals.finalizar' | translate }}
            </button>
        </div>
    </div>
</div>
