import { Injectable } from '@angular/core';
import { McToastService } from '@app/_services';
import { ConfigurationService } from '@app/_services/configuration/configuration.service';
import { ResponseHelper } from '@app/_shared/helpers/ResponseHelper';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GetBasicInfo, SetBasicInfo, SetPrivacyData } from './basic-info.actions';

export interface BasicInfoStateModel {
    loading: boolean;
    loadingFormSubmit: boolean;
    data: {
        hasLogo: boolean;
        favico?: any;
        title: string;
        description: string;
        country: string;
        state: string;
        city: string;
        enabledSite: boolean;
        logoName: string;
        useLogoAsfavicon: boolean;
        hasFavicon: boolean;
        faviconName: string;
    };
    dataPrivacy: {
        policy_cookies: boolean;
        policy_data: boolean;
        policy_data_text: string;
        policy_privacity: boolean;
        policy_privacity_url: string;
    };
    socialNetworkImage: string;
    imageLimit: number;
}

@State<BasicInfoStateModel>({
    name: 'basicInfo',
    defaults: {
        data: {
            hasLogo: false,
            favico: null,
            title: '',
            description: '',
            country: '',
            state: '',
            city: '',
            enabledSite: false,
            logoName: '',
            useLogoAsfavicon: false,
            hasFavicon: false,
            faviconName: '',
        },
        dataPrivacy: {
            policy_cookies: false,
            policy_data: false,
            policy_data_text: null,
            policy_privacity: false,
            policy_privacity_url: null,
        },
        loadingFormSubmit: false,
        loading: false,
        socialNetworkImage: '',
        imageLimit: 20,
    },
})
@Injectable()
export class BasicInfoState {
    constructor(
        private configurationService: ConfigurationService,
        private mcToast: McToastService,
        private responseHelper: ResponseHelper
    ) {}

    @Selector()
    public static getBasicInfo(state: BasicInfoStateModel) {
        return state.data;
    }

    @Selector()
    public static getSocialNetworkImage(state: BasicInfoStateModel) {
        return state.socialNetworkImage;
    }

    @Selector()
    public static getPrivacyInfo(state: BasicInfoStateModel) {
        return state.dataPrivacy;
    }

    @Selector()
    public static logoName(state: BasicInfoStateModel) {
        return state.data.logoName;
    }

    @Selector()
    public static faviconName(state: BasicInfoStateModel) {
        return state.data.faviconName;
    }

    @Selector()
    public static getLoadingFormSubmit(state: BasicInfoStateModel) {
        return state.loadingFormSubmit;
    }

    @Selector()
    public static getLoading(state: BasicInfoStateModel) {
        return state.loading;
    }

    @Selector()
    public static getImageLimit(state: BasicInfoStateModel) {
        return state.imageLimit;
    }

    @Action(GetBasicInfo)
    public async getBasicInfo({ patchState }: StateContext<BasicInfoStateModel>) {
        this.configurationService.getPrivacyPolicy().subscribe((privacyPolicyInfo) => {
            patchState({
                dataPrivacy: {
                    policy_cookies: privacyPolicyInfo.data.policy_cookies,
                    policy_data: privacyPolicyInfo.data.policy_data,
                    policy_data_text: privacyPolicyInfo.data.policy_data_text,
                    policy_privacity: privacyPolicyInfo.data.policy_privacity,
                    policy_privacity_url: privacyPolicyInfo.data.policy_privacity_url,
                },
            });
            const checkResponse = this.responseHelper.checkError(privacyPolicyInfo);
            if (checkResponse.success) {
                this.configurationService.getBasicInfo().subscribe((basicInfo) => {
                    const {
                        titulo,
                        descripcion,
                        pais,
                        provincia,
                        ciudad,
                        tienelogo,
                        activo,
                        logo_name,
                        favicon_name,
                        faviconlogo,
                        tienefavicon,
                        social_share_image,
                        total_image_products,
                    } = basicInfo.data;
                    patchState({
                        data: {
                            title: titulo,
                            description: descripcion,
                            country: pais,
                            state: provincia,
                            city: ciudad,
                            hasLogo: tienelogo,
                            enabledSite: activo,
                            logoName: logo_name,
                            useLogoAsfavicon: faviconlogo,
                            hasFavicon: tienefavicon,
                            faviconName: favicon_name,
                        },
                        socialNetworkImage: social_share_image,
                        imageLimit: total_image_products,
                    });
                });
            }
        });
        catchError((error) => of(error));
    }

    @Action(SetBasicInfo)
    public setNewCategory({ patchState }: StateContext<BasicInfoStateModel>, { basicInfo }: SetBasicInfo) {
        patchState({
            loadingFormSubmit: true,
        });
        return this.configurationService.updateBasicInfo(basicInfo).pipe(
            tap(
                (response: any) => {
                    if (response) {
                        const checkResponse = this.responseHelper.checkError(response);
                        if (checkResponse.success) {
                            const {
                                titulo,
                                descripcion,
                                pais,
                                provincia,
                                ciudad,
                                tienelogo,
                                activo,
                                logo_name,
                                faviconlogo,
                                tienefavicon,
                                favicon_name,
                            } = response.data;
                            patchState({
                                loadingFormSubmit: false,
                                data: {
                                    title: titulo,
                                    description: descripcion,
                                    country: pais,
                                    state: provincia,
                                    city: ciudad,
                                    hasLogo: tienelogo,
                                    enabledSite: activo,
                                    logoName: logo_name,
                                    useLogoAsfavicon: faviconlogo,
                                    hasFavicon: tienefavicon,
                                    faviconName: favicon_name,
                                },
                            });
                        }
                    }
                },
                (error) => {
                    patchState({
                        loadingFormSubmit: false,
                    });
                    return error;
                }
            )
        );
    }

    @Action(SetPrivacyData)
    public setPrivacyData({ patchState }: StateContext<BasicInfoStateModel>, { basicInfo }: SetPrivacyData) {
        let policyData = {};
        if (basicInfo.getAll('policy_data')[0] === 'false') {
            policyData = {
                policy_data: false,
                policy_privacity: false,
                policy_privacity_url: '',
            };
        } else {
            policyData = {
                policy_data: true,
                policy_privacity: basicInfo.getAll('policy_privacity')[0] === 'true',
                policy_privacity_url: basicInfo.getAll('policy_privacity_url')[0],
            };
        }
        const data = {
            policy_cookies: basicInfo.getAll('policy_cookies')[0] === 'true',
            policy_data_text: basicInfo.getAll('policy_data_text')[0],
            ...policyData,
        };
        patchState({
            loadingFormSubmit: true,
        });
        return this.configurationService.updatePrivacyInfo(data).pipe(
            tap(
                (privacyPolicyInfo) => {
                    patchState({
                        dataPrivacy: {
                            policy_cookies: privacyPolicyInfo.data.policy_cookies,
                            policy_data: privacyPolicyInfo.data.policy_data,
                            policy_data_text: privacyPolicyInfo.data.policy_data_text,
                            policy_privacity: privacyPolicyInfo.data.policy_privacity,
                            policy_privacity_url: privacyPolicyInfo.data.policy_privacity_url,
                        },
                        loadingFormSubmit: false,
                    });
                },
                (error) => {
                    patchState({
                        loadingFormSubmit: false,
                    });
                    return error;
                }
            )
        );
    }
}
