import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-ss-confirm',
    templateUrl: './ss-confirm.component.html',
    styleUrls: ['./ss-confirm.component.scss'],
})
export class SsConfirmComponent {
    showCancel = true;
    constructor(public dialogRef: MatDialogRef<SsConfirmComponent>, @Inject(MAT_DIALOG_DATA) public params) {
        this.showCancel = params?.showCancel ?? this.showCancel;
    }

    closeDialog() {
        this.dialogRef.close();
    }
    sendData(isClosed: boolean) {
        this.dialogRef.close(isClosed);
    }
}
