import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
    CollapseSidebar,
    ExpandSidebar,
    ForceCollapseSidebar,
    ForceExpandSidebar,
    ToggleSidebarCollapse,
    ToggleSidebarCollapseForced,
    SetSidebarMenu,
    GetInitialInfo,
} from '@app/core/sidebar/store/sidebar.actions';
import { InitialInfo, SidebarMenuItem } from '@app/_interfaces/sidebar-menu-item.interface';
import { find } from 'lodash';
import { SidebarService } from '@app/_services/sidebar/sidebar.service';
import { ResponseHelper } from '@app/_shared/helpers/ResponseHelper';
import { catchError, of, tap } from 'rxjs';

export interface SidebarStateModel {
    loading: boolean;
    menuItems: SidebarMenuItem[];
    collapsed: boolean;
    collapseForced: boolean;
    initialInfo: InitialInfo;
}

@State<SidebarStateModel>({
    name: 'Sidebar',
    defaults: {
        loading: false,
        menuItems: [],
        collapsed: false,
        collapseForced: false,
        initialInfo: null,
    },
})
@Injectable()
export class SidebarState {
    constructor(private sidebarService: SidebarService, private responseHelper: ResponseHelper) {}

    @Selector()
    public static loading(state: SidebarStateModel) {
        return state.loading;
    }

    @Selector()
    public static menu(state: SidebarStateModel) {
        return state.menuItems;
    }

    @Selector()
    public static servicesList(state: SidebarStateModel): SidebarMenuItem[] {
        const serviceMenuItem: SidebarMenuItem = find(
            state.menuItems,
            (menuItem: SidebarMenuItem) => menuItem.id == 'products'
        );
        return serviceMenuItem.children;
    }

    @Selector()
    public static collapsed(state: SidebarStateModel) {
        return state.collapsed;
    }

    @Selector()
    public static collapseForced(state: SidebarStateModel) {
        return state.collapseForced;
    }

    @Selector()
    public static initialInfo(state: SidebarStateModel) {
        return state.initialInfo;
    }

    @Action(ToggleSidebarCollapseForced)
    public toggleSidebarCollapseForced({ getState, patchState }: StateContext<SidebarStateModel>) {
        const state = getState();
        patchState({
            collapseForced: !state.collapseForced,
            collapsed: !state.collapseForced,
        });
    }

    @Action(ForceCollapseSidebar)
    public forceCollapseSidebar({ getState, patchState, dispatch }: StateContext<SidebarStateModel>) {
        dispatch(new CollapseSidebar());
        patchState({ collapseForced: true });
    }

    @Action(ForceExpandSidebar)
    public ForceExpandSidebar({ getState, patchState, dispatch }: StateContext<SidebarStateModel>) {
        dispatch(new ExpandSidebar());
        patchState({ collapseForced: false });
    }

    @Action(ToggleSidebarCollapse)
    public toggleSidebarCollapse({ getState, patchState }: StateContext<SidebarStateModel>) {
        const state = getState();
        patchState({
            collapsed: !state.collapsed,
        });
    }

    @Action(CollapseSidebar)
    public collapseSidebar({ getState, patchState }: StateContext<SidebarStateModel>) {
        const state = getState();

        if (!state.collapsed /*&& state.collapseForced*/) {
            patchState({ collapsed: true });
        }
    }

    @Action(ExpandSidebar)
    public expandSidebar({ getState, patchState }: StateContext<SidebarStateModel>) {
        patchState({ collapsed: false });
    }

    @Action(SetSidebarMenu)
    public setSidebarMenu({ patchState, getState }: StateContext<SidebarStateModel>, { data }: SetSidebarMenu) {
        if (getState().menuItems.length < 1) patchState({ menuItems: data });
    }

    @Action(GetInitialInfo)
    public getInitialInfo({ patchState }: StateContext<SidebarStateModel>) {
        return this.sidebarService.getInitialInfo().pipe(
            tap((response) => {
                patchState({
                    initialInfo: response,
                });
            }),
            catchError((error) => {
                throw error;
            })
        );
    }
}
