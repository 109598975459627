import { Injectable } from '@angular/core';
import { NavItemInterface } from '@app/_interfaces';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetCatalogNav } from './catalog.actions';

export interface CatalogStateModel {
    nav: NavItemInterface[];
}

@State<CatalogStateModel>({
    name: 'catalog',
    defaults: {
        nav: [],
    },
})
@Injectable()
export class CatalogState {
    constructor() {}

    @Selector()
    public static getState(state: CatalogStateModel) {
        return state;
    }

    @Selector()
    public static nav(state: CatalogStateModel) {
        return state.nav;
    }

    @Action(SetCatalogNav)
    public setNav({ patchState }: StateContext<CatalogStateModel>) {
        return patchState({
            nav: [
                {
                    label: 'Products',
                    link: 'products',
                    icon: 'box',
                    show: true,
                },
                {
                    label: 'Categorías',
                    link: 'categories',
                    icon: 'boxes',
                    show: true,
                },
                {
                    label: 'Atributos',
                    link: 'attributes',
                    icon: 'inventory',
                    show: true,
                },
                {
                    label: 'Tags',
                    link: 'tags',
                    icon: 'tag',
                    show: true,
                },
            ],
        });
    }
}
