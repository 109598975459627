export enum NOTIFICATION_TYPE {
    NEW_CLIENT = 'new_client',
    NEW_SALE = 'new_sale',
    PURCHASE_CANCELATION_REQUEST = 'purchase_cancelation_request',
    QUERY_FORM = 'CONTACT_FORM',
    QUERY_PRODUCT = 'product_query',
}

export interface INotificationPayload {
    body?: string;
    title?: string;
}

export interface MessagePayload {
    notification: INotificationPayload;
    data?: { [key: string]: any };
}
