<div class="wrapper-stepper py-5 pl-5">
    <div class="title-template">
        <h2
            class="color-secundario mb-3"
            [innerHTML]="'app.wizard.info_market.cual_es_el_logo_y_el_nombre' | translate"
        ></h2>
        <p class="subtitle-content color-texto2 sub-title">
            {{ 'app.wizard.info_market.si_todavia_no_definiste_el_nombre' | translate }}
        </p>
    </div>
    <div class="info-form mb-4">
        <div class="col-10 col-lg-6 p-0 pr-md-2 pr-xl-4">
            <div class="file-uploader">
                <label class="upload-logo-text">{{ 'app.wizard.info_market.logo' | translate }}</label>
                <app-file-upload-wizard
                    (sendLogoToParent)="getLogo($event)"
                    (deleteLogoEmitter)="deleteLogo()"
                    [logoLoaded]="urlLogo"
                    [stepName]="stepName"
                    [deleteFileLogo]="deleteFileLogo"
                    [errorElementName]="'logo'"
                ></app-file-upload-wizard>
            </div>
        </div>
        <div class="col-10 col-lg-6 p-0 pl-md-2 pl-xl-4 site-name-container">
            <form class="info-market-form w-100" (ngSubmit)="onFormSubmit()">
                <mc-form-group-input
                    [form]="InfoMarketForm"
                    name="alias"
                    [label]="'app.wizard.info_market.nombre' | translate"
                    class.form-control-lg="size == 'lg'"
                    [placeholder]="'app.wizard.info_market.tienda_las_marias' | translate"
                    class.form-control-sm="true"
                    [sublabel]="'app.wizard.info_market.este_campo_es_obligatorio' | translate"
                    ngClass="{ 'is-invalid': submitted && controlInvalid }"
                    [submitted]="submitted"
                >
                </mc-form-group-input>
            </form>
        </div>
    </div>
    <div class="mb-4 iframe-container">
        <iframe
            class="market-iframe"
            #marketIframe
            scrolling="no"
            [src]="iframeUrl | safe"
            sandbox="allow-forms allow-scripts allow-same-origin"
            name="myiFrame"
            [width]="'100%'"
            [height]="'180px'"
            allowfullscreen
            frameBorder="0px"
        ></iframe>
    </div>
    <div class="btn-div">
        <button class="btn btn-link color-link" cdkStepperPrevious>
            <i class="fas fa-chevron-left mr-1"></i>{{ 'app.global.modals.volver' | translate }}
        </button>
        <div class="h-100">
            <button [disabled]="!InfoMarketForm.valid" (click)="onFormSubmit()" class="btn btn-continue">
                {{ 'app.global.modals.continuar' | translate }}
            </button>
        </div>
    </div>
</div>
