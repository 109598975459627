import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from '@app/core/store/app.state';
import { Router } from '@angular/router';
import { environment } from '@env/environment.local';

@Component({
    selector: 'app-internal-server-error',
    templateUrl: './internal-server-error.component.html',
    styleUrls: ['./internal-server-error.component.scss'],
})
export class InternalServerErrorComponent implements OnInit {
    @Select(AppState.wizardStatus)
    wizardStatus$: Observable<boolean>;

    tuvimos_un_problema_inesperado: string;
    te_pedimos_disculpas: string;
    volver: string;

    assetsUrl: string = environment.assets_url;
    mfsPath: string = environment.mfs_path;

    constructor(private store: Store, private router: Router, private translate: TranslateService) {}

    ngOnInit(): void {
        this.setTranslations();
        this.wizardStatus$.subscribe((val) => {
            if (val !== null) {
                const error = this.store.selectSnapshot(AppState.getInternalServerError);
                if (!error) this.router.navigate(['/admin/dashboard']);
            }
        });
    }

    setTranslations() {
        this.tuvimos_un_problema_inesperado = this.translate.instant('app.errores.tuvimos_un_problema_inesperado');
        this.te_pedimos_disculpas = this.translate.instant('app.errores.te_pedimos_disculpas');
        this.volver = this.translate.instant('app.errores.volver_al_inicio');
    }
}
