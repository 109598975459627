<mc-loading [overlay]="true" [fixed]="true" *ngIf="loading"></mc-loading>

<div class="wrapper-stepper py-5 pl-5">
    <div class="title-template">
        <h2
            class="color-secundario mb-3"
            [innerHTML]="'app.wizard.market_sections.que_paginas_quieres_agregar' | translate"
        ></h2>
        <p class="subtitle-content color-texto2">
            {{ 'app.wizard.market_sections.las_paginas_son_secciones_que_organizan' | translate }}
        </p>
    </div>
    <div class="mb-4 scroll-y-auto lista-market-sections" *ngIf="!loading">
        <div class="col-4 col-lg-4" *ngFor="let section of marketSectionsRecommended$ | async; let i = index">
            <div
                [ngClass]="
                    recommendedListId.includes(section.id) || idSectionsList.includes(section.id)
                        ? 'card card-check'
                        : 'card'
                "
                (click)="selectRecommendedSection(section.id)"
            >
                <div class="card-checkbox">
                    <input type="checkbox" [checked]="idSectionsList && idSectionsList.includes(section.id)" disabled />
                </div>
                <div class="card-img-top">
                    <img
                        src="{{ assetsUrl }}{{ mfsPath }}/img/front/wizard/sections/{{ section.category }}.png"
                        alt=""
                        class="h-100 w-100"
                    />
                </div>
                <div class="card-body text-center">
                    <h3 class="color-texto">{{ section.nombre }}</h3>
                    <p class="color-texto" *ngIf="section.description">{{ section.description }}</p>
                </div>
            </div>
        </div>

        <div class="col-4 col-lg-4" *ngFor="let section of marketSectionsOther$ | async; let i = index">
            <div
                [ngClass]="
                    otherListId.includes(section.id) || idSectionsList.includes(section.id) ? 'card card-check' : 'card'
                "
                (click)="selectOtherSection(section.id)"
            >
                <div class="card-checkbox">
                    <input type="checkbox" [checked]="idSectionsList && idSectionsList.includes(section.id)" disabled />
                </div>
                <div class="card-img-top">
                    <img
                        src="{{ assetsUrl }}{{ mfsPath }}/img/front/wizard/sections/{{ section.category }}.png"
                        alt=""
                        class="h-100 w-100"
                    />
                </div>
                <div class="card-body text-center">
                    <h3 class="color-texto">{{ section.nombre }}</h3>
                    <p class="color-texto" *ngIf="section.description">{{ section.description }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="btn-div">
        <button class="btn btn-link color-link" cdkStepperPrevious>
            <i class="fas fa-chevron-left mr-1"></i>{{ 'app.global.modals.volver' | translate }}
        </button>
        <div class="h-100">
            <button
                [disabled]="!sectionsOther.length && !sectionsRecommended.length"
                class="btn btn-continue"
                (click)="sendSections()"
            >
                {{ 'app.global.modals.continuar' | translate }}
            </button>
        </div>
    </div>
</div>
