import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { McIconComponent } from './mc-icon/mc-icon.component';
import { BytesPipe, EmojisPipe, TogglePasswordPipe, OrderPipe, SafePipe } from '../_pipes';
import {
    PreventDefaultDirective,
    FocusOnInputDirective,
    TrimValueAccessorDirective,
    ClickOutsideDirective,
    AutoFocusDirective,
} from '../_directives';
import { McLoadingComponent } from './mc-loading/mc-loading.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { McToastComponent } from './mc-toast/mc-toast.component';
import { TranslateModule } from '@ngx-translate/core';
import { McFormGroupCheckComponent, McFormGroupInputComponent, McSelectCodCountryComponent } from './form-group';
import { NgxsModule } from '@ngxs/store';
import { FileUploadModule } from 'ng2-file-upload';
import { ProfileState } from '../pages/usuario/store/profile.state';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { FlipModule } from 'ngx-flip';
import { McSectionContextualMenuComponent } from './';

import {
    InfoMarketFormComponent,
    SelectTemplateComponent,
    SelectVerticalComponent,
    SocialMediaComponent,
    WelcomeWizardComponent,
    LoadingAnimatedComponent,
} from './ss-stepper';

// SS custom components
import { SScheckboxComponent } from '@app/components/form-group/ss-checkbox/ss-checkbox.component.component';
import { SsChipComponent } from './ss-chip/ss-chip.component';
import { SsStepperComponent } from './ss-stepper/ss-stepper.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgModule } from '@angular/core';
import { FileUploadComponent } from './ss-file-upload/file-upload.component';
import { MarketSectionsComponent } from './ss-stepper/steps/market-sections/market-sections.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { DesignState } from '@app/pages/web-site/design/store/design.state';
import { SettingsState } from '@app/pages/web-site/settings/store/settings.state';
import { BasicInfoState } from '@app/pages/web-site/basic-info/store/basic-info.state';
import { ContactDataState } from '@app/pages/web-site/contact-data/store/contact-data.state';
import { PageLanguagesState } from './../pages/web-site/settings/page-languages/store/page-languages.state';
import { IntegrationState } from '@app/pages/tools/integrations/store/integration.state';
import { DomainsState } from '@app/pages/domains/store/domains.state';
import { TrialExpiredComponent } from './trial-expired/trial-expired.component';
import { StatisticsState } from '@app/pages/statistics/website/store/statistics.state';
import { SsAlertComponent } from './ss-alert/ss-alert.component';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatPaginationIntlService } from '@app/_services/paginator.service';
import { PaginatorComponent } from '@app/components/paginator/paginator.component';
import { SsConfirmComponent } from './ss-confirm/ss-confirm.component';
import { SsButtonComponent } from './ss-button/ss-button.component';
import { CanExitModalComponent } from './can-exit-modal/can-exit-modal.component';
import { FileUploadWizardComponent } from './file-upload-wizard/file-upload-wizard.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CatalogState } from '@app/pages/e-commerce/catalog/store/catalog.state';
import { KeywordPillsComponent } from './keyword-pills/keyword-pills.component';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { SsImageComponent } from './ss-image/ss-image.component';
import { HeaderCustomizationState } from '@app/pages/web-site/design/header-customization/store/header-customization.state';
import { VersionBarComponent } from './version';
import { SsFormGroupInputComponent } from './form-group/ss-form-group-input/ss-form-group-input.component';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgxPaginationModule,
        BsDatepickerModule.forRoot(),
        NgxsModule.forFeature([
            ProfileState,
            DesignState,
            SettingsState,
            BasicInfoState,
            ContactDataState,
            PageLanguagesState,
            IntegrationState,
            DomainsState,
            StatisticsState,
            CatalogState,
            HeaderCustomizationState,
        ]),
        FileUploadModule,
        RouterModule,
        FlipModule,
        CdkStepperModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSelectCountryModule.forRoot('es'),
        TranslateModule,
        MatPaginatorModule,
        InfiniteScrollModule,
        MatChipsModule,
        MatInputModule,
    ],
    declarations: [
        // PIPES
        BytesPipe,
        TogglePasswordPipe,
        EmojisPipe,
        OrderPipe,
        SafePipe,
        // DIRECTIVES
        PreventDefaultDirective,
        FocusOnInputDirective,
        AutoFocusDirective,
        TrimValueAccessorDirective,
        ClickOutsideDirective,
        // COMPONENTS
        McIconComponent,
        McLoadingComponent,
        McFormGroupInputComponent,
        SsFormGroupInputComponent,
        McFormGroupCheckComponent,
        McSelectCodCountryComponent,
        McToastComponent,
        McSectionContextualMenuComponent,
        SsChipComponent,
        SScheckboxComponent,
        SsStepperComponent,
        InfoMarketFormComponent,
        SelectTemplateComponent,
        SelectVerticalComponent,
        SocialMediaComponent,
        FileUploadComponent,
        MarketSectionsComponent,
        LoadingAnimatedComponent,
        WelcomeWizardComponent,
        TrialExpiredComponent,
        SsAlertComponent,
        PaginatorComponent,
        SsConfirmComponent,
        SsButtonComponent,
        CanExitModalComponent,
        FileUploadWizardComponent,
        KeywordPillsComponent,
        SsImageComponent,
        VersionBarComponent,
    ],
    exports: [
        // MODULES
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        BsDatepickerModule,
        NgxPaginationModule,
        CdkStepperModule,
        MatSelectCountryModule,
        InfiniteScrollModule,
        MatChipsModule,
        MatInputModule,
        // PIPES
        BytesPipe,
        TogglePasswordPipe,
        OrderPipe,
        SafePipe,
        // DIRECTIVES
        PreventDefaultDirective,
        FocusOnInputDirective,
        AutoFocusDirective,
        // COMPONENTS
        McIconComponent,
        McLoadingComponent,
        McFormGroupInputComponent,
        SsFormGroupInputComponent,
        McFormGroupCheckComponent,
        McSelectCodCountryComponent,
        McToastComponent,
        EmojisPipe,
        McSectionContextualMenuComponent,
        TrimValueAccessorDirective,
        ClickOutsideDirective,
        SsChipComponent,
        SScheckboxComponent,
        SsStepperComponent,
        InfoMarketFormComponent,
        SelectTemplateComponent,
        SelectVerticalComponent,
        SocialMediaComponent,
        MarketSectionsComponent,
        LoadingAnimatedComponent,
        WelcomeWizardComponent,
        TrialExpiredComponent,
        FileUploadComponent,
        PaginatorComponent,
        SsButtonComponent,
        CanExitModalComponent,
        FileUploadWizardComponent,
        KeywordPillsComponent,
        SsImageComponent,
        VersionBarComponent,
    ],
    providers: [
        BytesPipe,
        CurrencyPipe,
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginationIntlService,
        },
    ],
})
export class McCommonModule {}
