<div class="full-screen-wizard bg-color-secundario scroll-y-auto welcome-wizard">
    <div class="col-10 col-lg-6 text-white texto-welcome">
        <div class="logo-ss">
            <a>
                <img src="{{ assetsUrl }}{{ mfsPath }}/img/front/wizard/SitioSimple_Logo_blanco.svg" alt="wizard-img" />
            </a>
        </div>
        <div class="texto-div">
            <h1 class="texto-div-title">{{ title }}</h1>
            <h3 class="texto-div-content mt-5">
                {{ 'app.wizard.sigue_5_sencillos_pasos' | translate }}
            </h3>
            <h3 class="texto-div-subcontent mt-5">{{ 'app.wizard.buen_trato' | translate }}</h3>
        </div>
        <div class="btn-div">
            <button class="btn btn-continue color-destacados bg-color-primario bg-primario-hover" (click)="emitEvent()">
                {{ textButton }}
            </button>
        </div>
    </div>
    <div class="col-8 col-lg-4 d-none d-lg-block imagen-welcome">
        <div>
            <img src="{{ assetsUrl }}{{ mfsPath }}/img/front/wizard/welcome/ilustracion_wizard_bienvenido.svg" />
        </div>
    </div>
</div>
