<div class="form-group m-0" [formGroup]="form" [ngClass]="formGroupClass">
    <label [class]="labelClass" [for]="name" *ngIf="label" (click)="lavelEvent()">{{ label }}</label>
    <label [for]="name" *ngIf="sublabel" class="sublabel d-block">{{ sublabel }}</label>
    <i
        class="fal fa-info-circle ss-tooltip ml-1 color-secundario"
        *ngIf="tooltipText"
        [ngbTooltip]="htmlContent"
        [triggers]="trigger"
        [autoClose]="'outside'"
        placement="right"
        [tooltipClass]="tooltipCustomClass"
    ></i>
    <p class="mb-1 input-description" *ngIf="inputDescription">{{ inputDescription }}</p>
    <div class="position-relative">
        <div
            class="spinner-border position-absolute"
            *ngIf="pending"
            [ngStyle]="{ right: '.375rem', top: '.375rem', 'z-index': '2' }"
        ></div>
        <mc-icon
            [name]="type === 'password' ? 'eye-slash' : 'eye'"
            prefix="fal"
            size="lg"
            class="position-absolute cursor-pointer"
            (click)="toggleTypePassword()"
            *ngIf="isPassword"
            [ngStyle]="{
                right: !control.errors || !control.dirty ? '.5rem' : '2.3rem',
                top: '.8rem !important',
                'z-index': '4'
            }"
        >
        </mc-icon>
        <mc-icon
            [name]="icon"
            [prefix]="iconPrefix"
            *ngIf="icon && !isPassword && !pending"
            size="lg"
            class="position-absolute"
            [ngStyle]="{ right: '.5rem', top: '1rem', 'z-index': '4' }"
        >
        </mc-icon>
        <div class="input-group" [class.input-group-sm]="size === 'sm'" [class.input-group-lg]="size === 'lg'">
            <ng-content select="[prepend]"></ng-content>
            <input
                class="form-control h-100"
                [autocomplete]="autocomplete"
                [type]="type"
                [id]="name"
                [formControlName]="name"
                [placeholder]="placeholder"
                [focusOnInput]="focus"
                [class.is-invalid]="submitted && control.invalid"
                [ngClass]="formControlClass"
                trim-value-accessor
                [attr.disabled]="disabled ? '' : null"
                (input)="onChangeValue($event.target.value)"
                [style.padding-left]="paddingNumericCode"
                (focusout)="focusOut($event)"
                (wheel)="onWheelEvent($event)"
            />
            <i
                *ngIf="(submitted || hasChange) && control.errors as errors"
                class="fal fa-times-circle position-absolute color-secundario invalid-tooltip-icon"
            ></i>
            <p class="numericCodeNumber position-absolute color-texto" *ngIf="numericCodeNumber">
                +{{ numericCodeNumber }}
            </p>
            <p class="numericCodeNumber position-absolute color-texto" *ngIf="currencyString">
                {{ currencyString }}
            </p>
            <ng-content select="[append]"></ng-content>

            <div
                class="invalid-tooltip d-block position-relative p-0"
                *ngIf="hasChange && !negativeValue && hasNegativeValue"
            >
                <small>No se aceptan valores negativos.</small>
            </div>
        </div>
        <div
            class="invalid-tooltip d-block position-relative p-0 mb-0"
            *ngIf="(submitted || hasChange) && control.errors as errors"
        >
            <small *ngIf="errors['required']">{{ 'app.errores.campo_requerido' | translate }}</small>
            <small *ngIf="errors['whitespace']">{{ 'app.errores.campo_invalido' | translate }}</small>
            <small *ngIf="errors['onlyNumbers']">{{ 'app.errores.debe_contener_solo_numeros' | translate }}</small>
            <small *ngIf="errors['email']">{{ 'app.errores.email_invalido' | translate }}</small>
            <small *ngIf="errors['wp']">{{ 'app.errores.whatsapp_invalido' | translate }}</small>
            <small *ngIf="errors['phoneNumber']">{{ 'app.errores.whatsapp_invalido' | translate }}</small>
            <small *ngIf="errors['whatsapp']">{{ 'app.errores.whatsapp_invalido' | translate }}</small>
            <small *ngIf="errors['domain']">{{ 'app.errores.dominio_invalido' | translate }}</small>
            <small *ngIf="errors['url']">{{ 'app.errores.url_invalida' | translate }}</small>
            <small *ngIf="errors['password']">{{ 'app.errores.debe_contener_letras_y_numeros' | translate }}</small>
            <small *ngIf="errors['cbu']">CBU inválido. </small>
            <small *ngIf="errors['userName']"
                >{{ 'app.profile.validaciones.error-caracteres-especiales' | translate }}
            </small>
            <small *ngIf="errors['invalidCost']">El costo no puede ser mayor que el precio </small>
            <small *ngIf="errors['invalidPrice']">El precio no puede ser menor que el costo </small>

            <small *ngIf="errors['minlength'] as minlength"
                >{{ 'app.errores.debe_contener_al_menos' | translate }} {{ minlength['requiredLength'] }} caracteres.
            </small>
            <small *ngIf="errors['maxlength'] as maxlength"
                >{{ 'app.errores.debe_contener_como_máximo' | translate }} {{ maxlength['requiredLength'] }} caracteres.
            </small>
            <small *ngIf="errors['min'] as min">Mayor o igual a {{ min['min'] }}. </small>
            <small *ngIf="errors['max'] as max">{{ numberMaxErrorMsg }} {{ max['max'] }}. </small>
            <small *ngIf="errors['pattern']">
                <span *ngIf="!patternValidationMessage">{{ 'app.errores.campo_invalido' | translate }}</span>
                <span *ngIf="patternValidationMessage">{{ patternValidationMessage }}</span>
            </small>
            <small *ngIf="errors['matching']">{{ 'app.errores.contraseñas_no_coinciden' | translate }}</small>
        </div>
    </div>
    <small class="form-text text-muted" *ngIf="formText" [innerHTML]="formTextContent"></small>
</div>

<ng-template #htmlContent>
    <span innerHtml="{{ tooltipText }}"></span>
</ng-template>
