<div class="selectCodCountry position-relative w-100" (clickOutside)="hideSelect()">
    <button
        class="button-select form-control w-100 h-100 d-flex align-items-center p-2"
        [value]="valueCode ?? defaultCountry.numericCode"
        (click)="toggleClass()"
        type="button"
    >
        <img
            src="{{ assetsUrl }}{{ mfsPath }}/img/front/countryFlags/{{ srcImg ?? defaultCountry.image_url }}"
            class="width-img-flag rounded"
        />
        <span class="ml-2">{{ countryName ?? defaultCountry[nameKey] }}</span>
    </button>
    <div class="container-select position-absolute w-100 rounded bg-white d-none" [id]="idSelect">
        <ul class="ul-select select-scrollbar my-2 mx-0 p-0">
            <li *ngFor="let country of countries" class="d-flex align-items-center p-2" (click)="selectOption(country)">
                <img
                    src="{{ assetsUrl }}{{ mfsPath }}/img/front/countryFlags/{{ country.image_url }} "
                    value="{{ country.numericCode }} "
                    class="width-img-flag rounded"
                />
                <span class="ml-2">{{ country[nameKey] }}</span>
            </li>
        </ul>
    </div>
</div>
