import { Injectable } from '@angular/core';
import { McRouter } from '@app/_services';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment.local';

@Injectable({
    providedIn: 'root',
})
export class BlogGuard implements CanActivate {
    constructor(private mcRouter: McRouter) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        let res = environment.enableBlog;

        return of(res);
    }
}
