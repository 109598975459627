import { SocialRed } from '@app/_models/social-media/social-media.model';
import { ChangeTemplateData, Template } from '@app/_models/templates/template.model';

export class SetReadyStep {
    public static readonly type = '[StepperActions] SetReadyStep';
    constructor(public param: boolean) {}
}

export class SetNextStep {
    public static readonly type = '[StepperActions] SetNextStep';
    constructor(public param: boolean) {}
}

export class SetSubmitForm {
    public static readonly type = '[StepperActions] SetSubmitForm';
    constructor(public param: boolean) {}
}

export class SetCurrentStep {
    public static readonly type = '[StepperActions] SetCurrentStep';
    constructor(public param: number) {}
}

export class SetCurrentStepContent {
    public static readonly type = '[StepperActions] SetCurrentStep';
    constructor(public param: any) {}
}

export class SetOpenModalStepper {
    public static readonly type = '[StepperActions] SetOpenModalStepper';
    constructor(public param: boolean) {}
}
export class SetSaveInfoWizard {
    public static readonly type = '[StepperActions] SetSaveInfoWizard';
    constructor(public param: boolean) {}
}
export class InitializeWizard {
    public static readonly type = '[StepperActions] InitializeWizard';
    constructor(public param: boolean) {}
}
export class GetStepsList {
    public static readonly type = '[marketActions] GetStepsList';
    constructor(public wizardName: string) {}
}

export class SetAssociatedVertical {
    public static readonly type = '[marketActions] SetAssociatedVertical';
    constructor(public param: string) {}
}

export class GetMarketSections {
    public static readonly type = '[marketActions] GetMarketSections';
    constructor() {}
}

export class SetMarketSections {
    public static readonly type = '[marketActions] SetMarketSections';
    constructor(public sectionsList: any) {}
}

export class SetSocialMedia {
    public static readonly type = '[marketActions] SetSocialMedia';
    constructor(public socialMediaList: SocialRed[]) {}
}

export class UpdateGeneralData {
    public static readonly type = '[marketActions] UpdateGeneralData';
    constructor(public data: any) {}
}

export class WizardSetContactData {
    public static readonly type = '[marketActions] WizardSetContactData';
    constructor(public contactData: any) {}
}
export class WizardSetWspData {
    public static readonly type = '[marketActions] WizardSetWspData';
    constructor(public wspData: any) {}
}
export class SetStepperStatus {
    public static readonly type = '[marketActions] SetStepperStatus';
    constructor(public stepperStatus: any) {}
}

export class SetWizardStatus {
    public static readonly type = '[marketActions] SetWizardStatus';
    constructor(public param: boolean) {}
}

export class SetIsLoading {
    public static readonly type = '[stepperActions] SetIsLoading';
    constructor(public param: boolean) {}
}

export class SetButtonStatus {
    public static readonly type = '[marketActions] SetButtonStatus';
    constructor(public param: boolean) {}
}

export class GetTemplates {
    public static readonly type = '[marketActions] GetTemplates';
    constructor(public verticalId?: number) {}
}
export class SetTemplate {
    public static readonly type = '[marketActions] SetTemplate';
    constructor(public template: Template) {}
}

export class GetAllTemplates {
    public static readonly type = '[marketActions] GetAllTemplates';
    constructor(public pageNumber: number) {}
}

export class ApplyTemplate {
    public static readonly type = '[marketActions] ApplyTemplate';
    constructor(public options: ChangeTemplateData) {}
}

export class ShowOthersTemplates {
    public static readonly type = '[marketActions] ShowOthersTemplates';
    constructor(public param: boolean) {}
}
export class ResetTemplatePaginator {
    public static readonly type = '[marketActions] ResetTemplatePaginator';
    constructor() {}
}
export class ResetOthersTemplates {
    public static readonly type = '[marketActions] ResetOthersTemplates';
    constructor() {}
}

export class SetTemplateInState {
    public static readonly type = '[marketActions] SetTemplateInState';
    constructor(public template: Template) {}
}
