<mc-loading [overlay]="true" [fixed]="true" *ngIf="loading"></mc-loading>

<div class="wrapper-stepper py-5 pl-5">
    <div class="title-template">
        <h2
            class="color-secundario mb-3"
            [innerHTML]="'app.wizard.select_template.que_diseño_te_gusta_mas' | translate"
        ></h2>
        <p class="subtitle-content color-texto2">
            {{ 'app.wizard.select_template.elige_una_plantilla' | translate }}
        </p>
    </div>
    <div class="mb-4 scroll-y-auto templates-list" #templateList>
        <ng-container *ngFor="let template of templateList$ | async">
            <div class="mt-1" [ngClass]="getClass()">
                <div
                    *ngIf="template.rutathumb && !this.loading"
                    [ngClass]="
                        templateData?.idTemplate == template.id ? 'template-select text-center' : 'product text-center'
                    "
                >
                    <div class="template-box position-relative mb-3">
                        <span class="banner-top"><p>. . .</p></span>
                        <span
                            *ngIf="templateData !== null && templateData?.idTemplate == template.id"
                            class="border-template"
                        ></span>
                        <img src="{{ uri }}{{ mfsPath }}{{ template.rutathumb }}" alt="..." />
                        <a class="icon-select" (click)="selectTemplate(template)">
                            {{ 'app.wizard.select_template.seleccionar' | translate }}
                        </a>
                        <a class="icon-zoom" (click)="showPreviewTemplate(template.rutazoom)">
                            {{ 'app.wizard.select_template.previsualizar' | translate }}
                        </a>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- Modal preview template -->
        <ng-template #templateModal let-c="close" let-d="dismiss">
            <div class="modal-header">
                <button type="button" class="close bg-color-destacados" aria-label="Close" (click)="d('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body overflow-y-auto">
                <img src="{{ uri }}{{ mfsPath }}{{ zoomPath }}" alt="..." class="w-100" />
            </div>
        </ng-template>
        <!-- othersTemplateList -->
        <ng-container *ngIf="othersTemplateList$ | async">
            <div class="col-6 col-md-4 mt-1" z *ngFor="let otherTemplate of othersTemplateList$ | async">
                <div
                    *ngIf="otherTemplate.rutathumb && !this.loading"
                    [ngClass]="
                        templateData?.idTemplate == otherTemplate.id
                            ? 'template-select text-center'
                            : 'product text-center'
                    "
                >
                    <div class="template-box position-relative mb-3">
                        <span class="banner-top"><p>. . .</p></span>
                        <span
                            *ngIf="templateData !== null && templateData?.idTemplate == otherTemplate.id"
                            class="border-template"
                        ></span>
                        <img src="{{ uri }}{{ mfsPath }}{{ otherTemplate.rutathumb }}" alt="..." />
                        <a class="icon-select" (click)="selectTemplate(otherTemplate)">
                            {{ 'app.wizard.select_template.seleccionar' | translate }}
                        </a>
                        <a class="icon-zoom" (click)="showPreviewTemplate(otherTemplate.rutazoom)">
                            {{ 'app.wizard.select_template.previsualizar' | translate }}
                        </a>
                    </div>
                </div>
            </div>
        </ng-container>
        <div *ngIf="moreTemplatesBtn" class="d-flex w-100 pr-2 justify-content-end">
            <span class="show-more-templates-btn d-flex align-items-center" (click)="showMoreTemplates()"
                >{{ 'app.wizard.select_template.mostrar_mas' | translate }} <i class="fal fa-angle-down ml-2"></i
            ></span>
        </div>

        <!--END  othersTemplateList -->
    </div>

    <div class="btn-div mt-2">
        <button class="btn btn btn-link color-link" (click)="goBack()">
            <i class="fas fa-chevron-left mr-1"></i>{{ 'app.global.modals.volver' | translate }}
        </button>
        <div class="h-100">
            <button [disabled]="button === true" class="btn btn-continue" (click)="sendData()">
                {{ 'app.global.modals.continuar' | translate }}
            </button>
        </div>
    </div>
</div>
