import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UntypedFormGroup } from '@angular/forms';

interface CheckItem {
    label: string;
    value: any;
}

@Component({
    selector: 'mc-form-group-check',
    templateUrl: './mc-form-group-check.component.html',
    styleUrls: ['./mc-form-group-check.component.css'],
})
export class McFormGroupCheckComponent {
    @Input() form: UntypedFormGroup;
    @Input() type: 'checkbox' | 'radio' = 'radio';
    @Input() name: string;
    @Input() checkboxLabel: string;
    @Input() inline = true;
    @Input() formText = '';
    @Input() options: CheckItem[] = [
        { label: 'Sí', value: true },
        { label: 'No', value: false },
    ];

    constructor(private sanitizer: DomSanitizer) {}

    get formTextContent() {
        return this.sanitizer.bypassSecurityTrustHtml(this.formText);
    }

    get style() {
        return null;
    }
}
