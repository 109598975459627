export class DesignAction {
    public static readonly type = '[Design] Add item';
    constructor(public payload: string) {}
}

export class SetDesignNav {
    public static readonly type = '[Design] SetDesignNav';
}

export class getSiteStructureAction {
    public static readonly type = '[Design] GetSiteStructure';
    constructor() {}
}

export class setSiteStructureAction {
    public static readonly type = '[Design] SetSiteStructure';
    constructor(public data: any) {}
}
export class ToggleExecuteAction {
    public static readonly type = '[Design] ToggleExecuteAction';
    constructor() {}
}

export class SetFalseExecuteAction {
    public static readonly type = '[Design] SetFalseExecuteAction';
    constructor() {}
}
export class SetLoadingFormSubmitAction {
    public static readonly type = '[Design] SetLoadingFormSubmitAction';
    constructor(public data: boolean) {}
}

export class SetTemplatesLoading {
    public static readonly type = '[Design] SetTemplatesLoading';
    constructor(public data: boolean) {}
}
