import { AbstractControl, ValidatorFn } from '@angular/forms';

export function domain(): ValidatorFn {
    const nameRe: RegExp = new RegExp(
        /^(?:http(s)?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,11}?$/
    );

    return (control: AbstractControl): { [key: string]: any } | null =>
        (control.value && !nameRe.test(control.value) && { domain: true }) || null;
}
