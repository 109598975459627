<mc-loading [overlay]="true" [fixed]="true" *ngIf="loading"></mc-loading>

<div class="wrapper-stepper py-5 pl-5">
    <div class="title-vertical">
        <h2 class="color-secundario mb-3" [innerHTML]="'app.wizard.select_vertical.a_que_rubro' | translate"></h2>
        <p class="subtitle-content color-texto2">
            {{ 'app.wizard.select_vertical.elige_la_actividad_que_realizas' | translate }}
        </p>
    </div>
    <div class="mb-4 div-verticales">
        <div class="col-4 text-left" *ngFor="let vertical of verticalList$ | async">
            <p
                [ngClass]="
                    verticalSelected == vertical.id
                        ? 'select-chip bg-color-destacados color-secundario'
                        : 'vertical-chip color-texto2'
                "
                (click)="setVertical(vertical.id, vertical.name)"
            >
                <i class="far fa-{{ vertical.icon }} mr-2"></i> {{ vertical.name }}
            </p>
        </div>
    </div>
    <div class="btn-div justify-content-end">
        <button [disabled]="!verticalSelected" class="btn btn-continue" (click)="sendVertical()">
            {{ 'app.global.modals.continuar' | translate }}
        </button>
    </div>
</div>

<ng-template #verticalsModal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            {{ 'app.wizard.select_vertical.modals.elige_otra_categoria' | translate }}
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body overflow-y-auto">
        <form [formGroup]="verticalsForm">
            <mc-form-group-input
                name="name"
                [placeholder]="'app.wizard.select_vertical.modals.ferreteria' | translate"
                [form]="verticalsForm"
                [submitted]="submitted"
                (input)="verticalsFilter($event.target.value)"
                autocomplete="off"
            >
            </mc-form-group-input>
        </form>
        <div class="list-group scroll-y-auto">
            <a
                *ngFor="let associatedVertical of associatedVerticalsFiltered | order : 'name'"
                [ngClass]="
                    associatedVertical.name == (associatedVertical$ | async).name
                        ? 'associated-list-item list-group-item list-group-item-action vertical-camel-case active'
                        : 'associated-list-item list-group-item list-group-item-action vertical-camel-case'
                "
                (click)="selectAssociatedVertical(associatedVertical)"
                >{{ associatedVertical.name
                }}<i
                    *ngIf="associatedVertical.name == (associatedVertical$ | async).name"
                    class="fas fa-check-circle ml-2 step-pass"
                ></i
            ></a>
        </div>
    </div>
    <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-skip" (click)="d('Cross click')">
            {{ 'app.global.modals.cancelar' | translate }}
        </button>
        <button type="button" class="btn btn-continue" (click)="closeVerticalModal()">
            {{ 'app.global.modals.continuar' | translate }}
        </button>
    </div>
</ng-template>
