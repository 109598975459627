import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
    GetGeneralInfoApp,
    GetMainMarket,
    GetVerticalList,
    SetVertical,
    SetVerticalList,
} from '@app/core/store/app.actions';
import { AppState } from '@app/core/store/app.state';
import { StepperHelper } from '@app/_shared/helpers/StepperHelper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { associatedVerticals } from '../../../../_shared/data/associatedVerticals';
import { ResetOthersTemplates, SetAssociatedVertical, SetButtonStatus } from '../../store/stepper.actions';
import { StepperState } from '../../store/stepper.state';
import { WizardItem } from '@app/_models/wizard/wizard-item.model';

@Component({
    selector: 'select-vertical',
    templateUrl: './select-vertical.component.html',
    styleUrls: ['./select-vertical.component.css'],
})
export class SelectVerticalComponent implements OnInit, OnDestroy {
    loading: boolean;
    verticalSelected: number = 0;
    defaultVerticalId: number;
    verticalData: any;
    verticalsForm: UntypedFormGroup;
    associatedVerticals = associatedVerticals;
    associatedVerticalsFiltered: Array<any> = [];
    associatedVerticalSelected: any;
    verticalList: Array<WizardItem> = [];
    servicesList: Array<number> = [];

    @Input() stepName: string;
    @ViewChild('verticalsModal') verticalsModal: any;
    @Select(AppState.getVerticalList)
    verticalList$: Observable<any>;
    @Select(AppState.getVerticalType)
    verticalType$: Observable<any>;
    @Select(StepperState.associatedVertical)
    associatedVertical$: Observable<any>;

    @Select(StepperState.getStepperGeneralStatus)
    stepperGeneralStatusResponse$: Observable<any>;

    constructor(
        public translate: TranslateService,
        private store: Store,
        private stepperHelper: StepperHelper,
        private cdkStepper: CdkStepper,
        private cdkStep: CdkStep,
        private modalService: NgbModal,
        private fb: UntypedFormBuilder
    ) {
        this.setServices();
    }

    ngOnInit(): void {
        this.verticalList$.subscribe((value: Array<any>) => {
            if (value.length === 0) {
                this.store.dispatch(new GetVerticalList()).subscribe((verticals) => {
                    this.setVerticals(verticals.App.verticalList);
                });
            } else {
                this.hasDataLoaded();
            }
        });
        this.callForMainMarket();
        this.stepperHelper.setCurrentStepName(this.stepName);
        this.stepperHelper.rememberStatus(this.stepName, this.cdkStep);
        this.buildForm();
        this.checkButtonStatus();
    }

    ngOnDestroy(): void {
        this.stepperHelper.updateStatusStep(this.stepName, this.cdkStep);
    }

    private setServices() {
        this.servicesList = this.store.selectSnapshot(AppState.getServicesList);

        if (!this.servicesList?.length) {
            this.store.dispatch(new GetGeneralInfoApp()).subscribe((response) => {
                this.servicesList = response.Sidebar.initialInfo.data.services.map(({ id }) => id);
            });
        }
    }

    private setVerticals(verticals: Array<WizardItem>): void {
        if (this.servicesList.length && !this.servicesList.includes(2)) {
            this.verticalList = verticals.filter((v) => v.id !== 1);
        } else {
            this.verticalList = verticals;
        }

        this.store.dispatch(new SetVerticalList(this.verticalList));
    }

    setVertical(idVertical): void {
        this.verticalSelected = idVertical;
        if (idVertical == 11) this.openVerticalModal(this.verticalsModal);
        this.checkButtonStatus();
    }

    sendVertical(): void {
        if (this.verticalSelected) {
            const verticalList = this.store.selectSnapshot(AppState.getVerticalList);
            const verticalId = this.verticalSelected === 11 ? verticalList[0]?.id : this.verticalSelected;
            this.loading = true;
            this.store.dispatch(new SetAssociatedVertical(''));
            this.store.dispatch(new SetVertical({ vertical: verticalId })).subscribe({
                next: () => {
                    localStorage.setItem('showMoreTemplatesBtn', 'true');
                    this.store.dispatch(new ResetOthersTemplates());
                    this.stepperHelper.sendStepperStatus();
                    this.store.dispatch(new GetMainMarket()).subscribe(() => {
                        this.loading = false;
                        this.cdkStepper.next();
                    });
                },
            });

            const data = { verticalId };
            this.stepperHelper.updateDataStep(this.stepName, data);
        }
    }

    hasDataLoaded(): void {
        const data = this.stepperHelper.getDataStep(this.stepName);
        if (data) {
            this.verticalData = data;
            this.verticalSelected = this.verticalData.verticalId;
        }
    }

    openVerticalModal(modal): void {
        if (this.associatedVerticalSelected) {
            const selectedExist = this.associatedVerticalsFiltered.find(
                (value) => value.name == this.associatedVerticalSelected.name
            );
            if (!selectedExist) this.associatedVerticalsFiltered.push(this.associatedVerticalSelected);
        }
        this.modalService.open(modal, {
            windowClass: 'd-flex align-items-center justify-content-center',
            backdrop: 'static',
            animation: true,
        });
    }

    buildForm(): void {
        this.verticalsForm = this.fb.group({
            name: [''],
        });
    }

    verticalsFilter(inputValue): void {
        if (this.associatedVerticalSelected) {
            this.associatedVerticalsFiltered.push(this.associatedVerticalSelected);
        }
        if (inputValue.length >= 3) {
            let associateVerticalsFiltered = this.associatedVerticals.filter((value) =>
                this.sanitizeString(value.name).includes(this.sanitizeString(inputValue))
            );
            if (associateVerticalsFiltered) {
                this.associatedVerticalsFiltered = associateVerticalsFiltered;
            } else {
                if (this.associatedVerticalSelected) {
                    this.associatedVerticalsFiltered = [this.associatedVerticalSelected];
                } else {
                    this.associatedVerticalsFiltered = [];
                }
            }
        } else {
            this.associatedVerticalSelected
                ? (this.associatedVerticalsFiltered = [this.associatedVerticalSelected])
                : (this.associatedVerticalsFiltered = []);
        }
    }

    selectAssociatedVertical(vertical): void {
        const verticalList = this.store.selectSnapshot(AppState.getVerticalList);
        const verticalId = vertical.id === 11 ? verticalList[0]?.id : vertical.id;
        const data = { verticalId };
        this.stepperHelper.updateDataStep(this.stepName, data);
        this.verticalSelected = verticalId;
        this.associatedVerticalSelected = vertical;
        this.store.dispatch(new SetAssociatedVertical(vertical));
        this.store.dispatch(new SetVertical({ vertical: verticalId })).subscribe({
            next: () => {
                localStorage.setItem('showMoreTemplatesBtn', 'true');
                this.store.dispatch(new ResetOthersTemplates());
                this.stepperHelper.sendStepperStatus();
            },
        });
    }

    closeVerticalModal(): void {
        this.modalService.dismissAll();
        this.loading = true;
        this.store.dispatch(new GetMainMarket()).subscribe(() => {
            this.loading = false;
            this.cdkStepper.next();
        });
    }

    sanitizeString(str: string): string {
        return str
            .toLocaleLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
    }

    callForMainMarket(): void {
        this.loading = true;
        this.store.dispatch(new GetMainMarket()).subscribe(() => (this.loading = false));
    }

    checkButtonStatus(): void {
        this.verticalList$.subscribe((value: Array<any>) => {
            let vertical;
            if (value.length === 0) {
                vertical = 0;
                this.store.dispatch(new SetButtonStatus(true));
            } else {
                this.stepperGeneralStatusResponse$.subscribe((value: any) => {
                    const data = this.stepperHelper.getDataStep(this.stepName);
                    vertical = data?.verticalId;
                    if (vertical === this.verticalSelected) {
                        this.store.dispatch(new SetButtonStatus(false));
                    } else {
                        this.store.dispatch(new SetButtonStatus(true));
                    }
                });
            }
        });
    }
}
