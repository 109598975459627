import { Component } from '@angular/core';
import { environment } from '@env/environment.local';

@Component({
    selector: 'loading-animated',
    templateUrl: './loading-animated.component.html',
    styleUrls: ['./loading-animated.component.scss'],
})
export class LoadingAnimatedComponent {
    assetsUrl: string = environment.assets_url;
    mfsPath: string = environment.mfs_path;
    constructor() {}
}
