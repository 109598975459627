import { countryCodes } from '@app/_shared/data/country-codes';

export const utils = {
    getPaginateParams(data) {
        return {
            totalItems: data.total,
            pageSize: data.per_page,
            itemsPerPage: data.per_page,
            currentPage: data.current_page,
            lastPage: data.last_page,
        };
    },
    secondsToHHMMSS(valueInSeconds) {
        let seconds: number | string = Math.floor(valueInSeconds);
        let hours: number | string = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        let minutes: number | string = Math.floor(seconds / 60);
        seconds -= minutes * 60;

        if (hours < 10) hours = '0' + hours;
        if (minutes < 10) minutes = '0' + minutes;
        if (seconds < 10) seconds = '0' + seconds;
        return `${hours}:${minutes}:${seconds}`;
    },
    clearCache(reloadAfterClear: boolean = true) {
        if ('caches' in window) {
            caches.keys().then((names) => {
                names.forEach(async (name) => {
                    await caches.delete(name);
                });
                if (reloadAfterClear) window.location.reload();
            });
        }
    },

    getNumericCodeFromNumber(number: string): string {
        return number?.trim().split(' ')[0].replace('+', '');
    },

    getCountryFlag(value: string) {
        // get country from bo
        if (value?.length === 2) {
            return countryCodes.find(
                (country) => country.alpha2Code?.toLocaleUpperCase() === value?.toLocaleUpperCase()
            );
        }
        // get country from a number
        const numericCode = this.getNumericCodeFromNumber(value);
        return countryCodes.find((country) => country.numericCode === numericCode);
    },

    arrayToTree(arr, parent_id = 0) {
        if (arr?.length > 0) {
            return arr
                .filter((item) => item.parent_id === parent_id)
                .map((child) => ({ ...child, children: this.arrayToTree(arr, child.category_id) }));
        }
    },

    flattenArray(arr) {
        if (arr?.length > 0) {
            let children = [];
            const flattenChildren = arr.map((m) => {
                if (m.children && m.children.length) {
                    children = [...children, ...m.children];
                }
                return m;
            });

            return flattenChildren.concat(children.length ? this.flattenArray(children) : children);
        }
    },

    assignDepth(arr, depth = 0) {
        arr?.forEach((item) => {
            item.depth = depth;
            if (item.children?.length > 0) {
                this.assignDepth(item.children, depth + 1);
            }
        });
    },

    getFileExtensionFromName(filename) {
        return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
    },

    generateSlug(value: string): string {
        if (value) {
            return value
                .toString()
                .toLowerCase()
                .replace(/[:=+&/*;%#$?¿)(]/gi, '-')
                .replace(/[äÄáÁÂâàÀãåÃãÆæ]/gi, 'a')
                .replace(/[ëËéÉÊêèÈ]/gi, 'e')
                .replace(/[ïÏíÍÎîìÌ]/gi, 'i')
                .replace(/[öÖóÓÔôòÒØøÕõ]/gi, 'o')
                .replace(/[üÜúÚÛûùÙ]/gi, 'u')
                .replace(/[ñÑ]/gi, 'n')
                .replace(/[çÇ]/gi, 'c')
                .replace(/[ýÝ]/gi, 'y')
                .replace(/[ðÐ]/gi, 'd')
                .replace(/[^A-Za-z0-9\s\-]/gi, '')
                .replace(/\s+/g, '-')
                .replace(/[^\w\-]+/g, '')
                .replace(/\-\-+/g, '-')
                .replace(/^-+/, '')
                .replace(/-+$/, '');
        } else {
            return '';
        }
    },

    replaceEmptySpaces(string: string, replacement: string = '') {
        return string.replace(/\s+/g, replacement);
    },

    //DATE

    subtractDays(date: Date, days: number): Date {
        date.setDate(date.getDate() + days);
        return date;
    },

    transformDate(date: Date): string {
        const dateItems = date.toString().split(' ');
        const day: string = dateItems[2];
        const month = dateItems[1];
        const year = dateItems[3];
        const hours = dateItems[4];
        const monthNumber = this.getMonthNumberFromName(month, day, year);
        return `${year}-${monthNumber}-${day} ${hours}`;
    },

    getMonthNumberFromName(monthName: string, day: string, year: string): number {
        return new Date(`${monthName} ${day}, ${year}`).getMonth() + 1;
    },

    // receives a 'AAA-M-D' format and returns a 'AAA-M-DD HH:MM:SS' format
    changeDateFormat(dateString: string, hours: string): string {
        const date = new Date(dateString);
        const isoString = date.toISOString();
        const [year, month, day] = isoString.match(/^(\d{4})-(\d{2})-(\d{2})/).slice(1);
        const formattedDate = `${year}-${month}-${day} ${hours}`;
        return formattedDate;
    },
};
