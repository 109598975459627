import { CdkStep } from '@angular/cdk/stepper';
import { Injectable } from '@angular/core';
import { SetStepperStatus } from '@app/components/ss-stepper/store/stepper.actions';
import { Step } from '@app/_models/steps/step.model';
import { Store } from '@ngxs/store';

@Injectable({
    providedIn: 'root',
})
export class StepperHelper {
    stepper: Array<any> = this.getGeneralStepperData() || [];
    stepItem: Step;
    stepperStatus: any;

    constructor(private store: Store) {}

    findStep(stepName: string): any {
        const step = this.stepper.find((step) => step.stepName == stepName);
        return step;
    }

    setCurrentStepIndex(stepIndex: number): void {
        localStorage.setItem('currentStepIndex', JSON.stringify(stepIndex));
    }

    getCurrentStepIndex(): number {
        return JSON.parse(localStorage.getItem('currentStepIndex'));
    }

    setCurrentStepName(stepName: string): void {
        localStorage.setItem('currentStep', stepName);
    }

    getCurrentStep(): string {
        return localStorage.getItem('currentStep');
    }

    setCurrentStepInfo(status: CdkStep, data?: any): void {
        const currentStepInfo = {
            stepName: this.getCurrentStep(),
            stepStatus: {
                interacted: status.interacted,
                completed: status.completed,
                requerido: status.optional,
            },
            data: data,
        };
        localStorage.setItem('currentStepInfo', JSON.stringify(currentStepInfo));
        this.setStepInfo(currentStepInfo);
    }

    getCurrentStepInfo(): any {
        return JSON.parse(localStorage.getItem('currentStepInfo'));
    }

    setStepInfo(stepInfo): void {
        if (!this.stepExist(stepInfo.stepName)) {
            this.stepper.push(stepInfo);
        } else {
            this.stepper.forEach((step) => {
                if (step.stepName == stepInfo.stepName) {
                    step = stepInfo;
                }
            });
        }
        localStorage.setItem('generalStepperData', JSON.stringify(this.stepper));
    }

    getGeneralStepperData(): any {
        return JSON.parse(localStorage.getItem('generalStepperData'));
    }

    setGeneralStepperData(generalStepperData: any): void {
        localStorage.setItem('generalStepperData', JSON.stringify(generalStepperData));
        this.stepper = this.getGeneralStepperData();
    }

    stepExist(stepName: string): boolean {
        let stepExist: boolean;
        this.findStep(stepName) ? (stepExist = true) : (stepExist = false);
        return stepExist;
    }

    updateDataStep(stepName, data): void {
        const step = this.findStep(stepName);
        if (step) {
            step.data = data;
            this.setCurrentStepInfo(step);
        }
    }

    getDataStep(stepName): any {
        const step = this.findStep(stepName);
        if (step) {
            return step.data;
        }
    }

    updateStatusStep(stepName, status): void {
        const step = this.findStep(stepName);
        if (step) {
            step.stepStatus = {
                interacted: status.interacted,
                completed: status.completed,
                requerido: status.optional,
            };
            this.setCurrentStepInfo(step);
        }
    }

    getStatusStep(stepName: string): Step {
        const step = this.findStep(stepName);
        if (step && step.stepStatus) {
            return step.stepStatus;
        }
    }

    rememberStatus(stepName: string, status: CdkStep, data?): void {
        const hasStatus = this.getStatusStep(stepName);
        if (!hasStatus) {
            this.setCurrentStepInfo(status, data);
        }
    }

    resetStepperInfo(): void {
        localStorage.removeItem('currentStep');
        localStorage.removeItem('currentStepIndex');
        localStorage.removeItem('currentStepInfo');
        localStorage.removeItem('generalStepperData');
        localStorage.removeItem('step2ScrollPosition');
    }

    sendStepperStatus() {
        this.stepperStatus = this.getGeneralStepperData() || null;
        this.store.dispatch(
            new SetStepperStatus({
                data: this.stepperStatus,
                latestStepName: this.getCurrentStep(),
                latestStepIndex: this.getCurrentStepIndex(),
            })
        );
    }

    deleteStatusWizard() {
        localStorage.removeItem('wizard-formData');
        this.store.dispatch(
            new SetStepperStatus({
                data: [],
                latestStepName: 'select-vertical',
                latestStepIndex: 0,
            })
        );
    }

    rememberFormData(stepName: string, formValue: any, logo?: string) {
        const data = localStorage.getItem('wizard-formData');
        const objects = JSON.parse(data);
        if (objects) {
            const index = objects.findIndex((obj) => obj.stepName === stepName);
            if (index !== -1) {
                objects[index].formValue = formValue;
                objects[index].logo = logo;
            } else {
                objects.push({
                    stepName: stepName,
                    formValue: formValue,
                    logo: logo,
                });
            }
            localStorage.setItem('wizard-formData', JSON.stringify(objects));
        } else {
            const objectsArray = [];
            objectsArray.push({
                stepName: stepName,
                formValue: formValue,
                logo: logo,
            });
            localStorage.setItem('wizard-formData', JSON.stringify(objectsArray));
        }
    }

    getFormData(stepName: string) {
        const data = localStorage.getItem('wizard-formData');
        if (data) {
            return JSON.parse(data).find((obj) => obj.stepName === stepName);
        } else {
            return null;
        }
    }
}
