<div
    class="mc-sidebar mc-sidebar--theme-dark d-flex flex-column shadow-sm"
    (clickOutside)="expandSidebarMobile = false"
    *ngIf="wizardStatus$ | async"
>
    <!-- Logo -->
    <div class="content-logo-iconburguer">
        <a
            class="mc-sidebar__brand d-flex align-items-center justify-content-center"
            [href]="dwLink"
            [disableTooltip]="!(collapsed$ | async)"
            ngbTooltip="SitioSimple.com"
            placement="right"
        >
            <img
                [class]="(collapsed$ | async) ? 'mc-sidebar__brand-sm' : 'mc-sidebar__brand-lg'"
                [src]="
                    (collapsed$ | async)
                        ? assetsUrl + mfsPath + '/img/front/logos/isologo_sitio_simple_blanco.png'
                        : assetsUrl + mfsPath + '/img/front/logos/SitioSimple_Logo_blanco.svg'
                "
            />
        </a>
        <!-- Collapse sidebar -->
        <div class="menu-mobile-collapse cursor-pointer d-block d-md-none" (click)="collapseMobileSidebar()">
            <i
                class="burguer far fa-bars color-white"
                [ngClass]="{ 'd-none': expandSidebarMobile, 'd-block': !expandSidebarMobile }"
            ></i>
            <i
                class="cross far fa-times color-white"
                style="font-size: 1.75rem"
                [ngClass]="{ 'd-none': !expandSidebarMobile, 'd-block': expandSidebarMobile }"
            ></i>
        </div>
    </div>
    <div class="menu-mobile-expanded d-md-flex mt-3 mt-md-0" [ngClass]="{ 'd-none': !expandSidebarMobile }">
        <div class="mc-sidebar__menu bg-color-secundario d-flex flex-column flex-fill overflow-y-auto mb-4">
            <ul
                class="mc-sidebar__tree mc-sidebar__tree--first-level list-unstyled mt-2"
                [class.px-0]="collapsed$ | async"
                [class.px-3]="!(collapsed$ | async)"
            >
                <!-- Menu items -->
                <ng-container *ngFor="let menuItem of menu$ | async">
                    <li
                        *ngIf="menuItem.show"
                        class="px-1"
                        #menuItemTooltip="ngbTooltip"
                        [ngbTooltip]="menuItem.label"
                        [disableTooltip]="!(collapsed$ | async)"
                        [class.has-submenu]="menuItem.children.length > 0"
                        placement="right"
                        container="body"
                        routerLinkActive="active"
                    >
                        <ng-container *ngIf="menuItem.children.length === 0">
                            <a
                                class="d-flex align-items-center rounded"
                                routerLink="{{ adminPrefix$ | async }}/{{ menuItem.url }}"
                                (click)="expandSidebarMobile = false"
                            >
                                <mc-icon
                                    prefix="fal"
                                    size="lg"
                                    [fixedWidth]="true"
                                    *ngIf="menuItem.icon"
                                    [name]="menuItem.icon"
                                >
                                </mc-icon>
                                <span class="label" *ngIf="menuItem.label">
                                    {{ 'app.sidebar.' + menuItem.id | translate }}
                                </span>
                                <i
                                    *ngIf="menuItem?.icon === 'home' && newNotifications()"
                                    class="fal fa-bell-exclamation ml-auto color-primario"
                                ></i>
                            </a>
                        </ng-container>
                        <ng-container *ngIf="menuItem.children.length > 0">
                            <a
                                class="d-flex align-items-center rounded"
                                href="#"
                                (click)="toggleMenuItem(menuItem, menuItemTooltip)"
                                preventDefault
                            >
                                <mc-icon
                                    prefix="fal"
                                    size="lg"
                                    [fixedWidth]="true"
                                    *ngIf="menuItem.icon"
                                    [name]="menuItem.icon"
                                >
                                </mc-icon>
                                <span class="label" *ngIf="menuItem.label">
                                    {{ 'app.sidebar.' + menuItem.id | translate }}
                                </span>
                                <mc-icon
                                    class="ml-auto"
                                    prefix="fal"
                                    [name]="menuItem.collapsed ? 'angle-down' : 'angle-up'"
                                ></mc-icon>
                            </a>
                        </ng-container>
                        <!-- MenuItems children -->
                        <ul
                            class="mc-sidebar__tree mc-sidebar__tree--second-level list-unstyled"
                            style="height: auto"
                            [class.collapsed]="menuItem.children.length === 0 || menuItem.collapsed"
                            id="{{ menuItem.id }}"
                        >
                            <ng-container *ngFor="let subMenuItem of menuItem.children">
                                <li routerLinkActive="active">
                                    <a
                                        *ngIf="subMenuItem.show"
                                        class="d-flex align-items-center"
                                        routerLink="{{ adminPrefix$ | async }}/{{ subMenuItem.url }}"
                                        (click)="expandSidebarMobile = false"
                                    >
                                        <span class="label" *ngIf="subMenuItem.label">
                                            {{ 'app.sidebar.' + subMenuItem.id | translate }}
                                        </span>
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </li>
                </ng-container>
            </ul>
        </div>
        <!-- Ver sitio -->
        <div class="content-icon-external-link bg-color-secundario ml-1 pb-3">
            <a
                (click)="goToMarket()"
                id="market-link"
                [ngClass]="{ 'ml-4': collapseSidebarForClass == false, 'ml-2 pl-1': collapseSidebarForClass == true }"
                style="font-size: 1.5rem; color: white; text-decoration: none; cursor: pointer"
            >
                <i class="fal fa-external-link"></i>
                <span
                    style="font-size: 0.875rem; margin-left: 0.75rem; color: #ebebff"
                    [ngClass]="{ 'd-none': collapseSidebarForClass == true }"
                    >{{ 'app.dashboard.ver_sitio' | translate }}</span
                >
            </a>
        </div>
        <!-- Logout and perfil -->
        <div class="icons-perfil-logout">
            <div
                class="mc-sidebar__profile d-flex justify-content-start align-items-center cursor-pointer"
                (click)="goToProfile()"
            >
                <div
                    *ngIf="profilePhotoTemp$ | async"
                    id="circle"
                    class="d-flex justify-content-center align-items-center"
                >
                    <img
                        [src]="profilePhotoTemp$ | async"
                        class="mc-sidebar__profile-img rounded-circle"
                        style="object-fit: cover"
                        [class.ml-2]="collapsed$ | async"
                        [class.ml-3]="!(collapsed$ | async)"
                        [ngbTooltip]="'Editar perfil'"
                        [placement]="(collapsed$ | async) ? 'right' : 'top'"
                        container="body"
                        alt="user-profile-photo"
                    />
                </div>
                <img
                    class="mc-sidebar__profile-img rounded-circle"
                    src="{{ assetsUrl }}{{ mfsPath }}/img/front/dashboard/avatar-default.png"
                    [class.ml-2]="collapsed$ | async"
                    [class.ml-3]="!(collapsed$ | async)"
                    [ngbTooltip]="'Editar perfil'"
                    [placement]="(collapsed$ | async) ? 'right' : 'top'"
                    container="body"
                    alt="user-profile-photo-circle"
                />

                <ng-container *ngIf="!(collapsed$ | async)">
                    <div
                        class="mc-sidebar__profile-info d-flex flex-column ml-3 justify-content-center"
                        *ngIf="userName"
                    >
                        <span class="text-nowrap text-truncate"> {{ userName$ | async }}</span>
                    </div>
                </ng-container>
            </div>
            <div
                class="mc-sidebar__logout d-flex align-items-center justify-content-start cursor-pointer"
                (click)="logout()"
                [class.pl-3]="collapsed$ | async"
                [class.pl-4]="!(collapsed$ | async)"
                [ngbTooltip]="'Cerrar sesión'"
                [disableTooltip]="!(collapsed$ | async)"
                placement="right"
                container="body"
            >
                <mc-icon prefix="fal" name="sign-out"></mc-icon>
                <small class="text-uppercase ml-2">{{ 'app.profile.cerrar_sesion' | translate }} </small>
            </div>
        </div>
        <div
            class="mc-sidebar__arrow d-none d-md-flex align-items-center rounded-circle justify-content-end cursor-pointer shadow-sm"
            (click)="toggleSidebarCollapseForced()"
        >
            <mc-icon prefix="far" [name]="(collapsed$ | async) ? 'angle-right' : 'angle-left'"></mc-icon>
        </div>
    </div>
</div>
