import { Injectable } from '@angular/core';
import { GetGeneralInfoApp } from '@app/core/store/app.actions';
import { AppState } from '@app/core/store/app.state';
import { GetSales, SetFilters } from '@app/pages/e-commerce/catalog/sales/store/sales.actions';
import { NavItemInterface } from '@app/_interfaces';
import { SalesFilters } from '@app/_models/e-commerce/sales/sales.model';
import {
    Browsers,
    Devices,
    EntryPage,
    FilterMap,
    MergeData,
    Referrers,
    ResponseStatics,
    SaleChartItem,
    UserCountry,
    Visits,
} from '@app/_models/pages/statistics/website.model';
import { WebsiteService } from '@app/_services/statistics/website.service';
import { utils } from '@app/_utils';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import dayjs from 'dayjs';
import _ from 'lodash/fp';
import { lastValueFrom } from 'rxjs';
import {
    AddSiteToMatomo,
    CallForOrders,
    CallForSales,
    CallForVisitsByBrowser,
    CallForVisitsByCountry,
    CallForVisitsByDevices,
    CallForVisitsByOrigin,
    CallForVisitsByPage,
    CallForVisitsSummary,
    CallForVisitsSummaryChart,
    ResetCharts,
    SetDate,
    SetLoading,
    SetRangeDate,
    SetStatisticsNav,
} from './statistics.actions';

export interface StoreStateModel {
    loading: boolean;
    date?: string;
    visitsSummary: {};
    visitsSummaryChart: Visits[];
    sales: SaleChartItem[];
    userCountry: UserCountry[];
    entryPage: EntryPage[];
    devices: Devices[];
    referrers: Referrers[];
    browsers: Browsers[];
    totalPagesViews: number;
    nav: NavItemInterface[];
    when: string;
}

@State<StoreStateModel>({
    name: 'Statistics',
    defaults: {
        loading: false,
        visitsSummary: {},
        visitsSummaryChart: [],
        sales: [],
        nav: [],
        userCountry: [],
        entryPage: [],
        devices: [],
        referrers: [],
        browsers: [],
        totalPagesViews: 0,
        when: '/day/today',
    },
})
@Injectable()
export class StatisticsState {
    dateObj: Date;
    months = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ];

    constructor(private webSite: WebsiteService, private translate: TranslateService, private store: Store) {}

    // selectors
    @Selector()
    public static getLoading(state: StoreStateModel) {
        return state.loading;
    }

    @Selector()
    public static getDate(state: StoreStateModel) {
        return state.date;
    }

    @Selector()
    public static getNav(state: StoreStateModel) {
        return state.nav;
    }

    @Selector()
    public static getWhen(state: StoreStateModel) {
        return state.when;
    }

    @Selector()
    public static getVisitsSummary(state: StoreStateModel) {
        return state.visitsSummary;
    }

    @Selector()
    public static getSales(state: StoreStateModel) {
        return state.sales;
    }

    @Selector()
    public static getUserCountry(state: StoreStateModel) {
        return state.userCountry;
    }

    @Selector()
    public static getEntryPages(state: StoreStateModel) {
        return state.entryPage;
    }

    @Selector()
    public static getDevices(state: StoreStateModel) {
        return state.devices;
    }

    @Selector()
    public static getReferrers(state: StoreStateModel) {
        return state.referrers;
    }

    @Selector()
    public static getBrowsers(state: StoreStateModel) {
        return state.browsers;
    }

    @Selector()
    public static getVisitsSummaryChart(state: StoreStateModel) {
        return state.visitsSummaryChart;
    }

    @Selector()
    public static getTotalPagesViews(state: StoreStateModel) {
        return state.totalPagesViews;
    }

    // actions
    @Action(AddSiteToMatomo)
    public addSiteToMatomo(ctx: StateContext<StoreStateModel>) {
        this.webSite.addSiteToMatomo().subscribe();
    }

    @Action(SetDate)
    public setDate({ patchState }: StateContext<StoreStateModel>, { date }: SetDate) {
        switch (date) {
            case 'yesterday':
                this.dateObj = new Date();
                this.dateObj.setDate(this.dateObj.getDate() - 1);
                patchState({
                    date:
                        this.dateObj.getDate() +
                        ' de ' +
                        this.months[this.dateObj.getMonth()] +
                        ' de ' +
                        this.dateObj.getFullYear(),
                    when: '/day/yesterday',
                });
                break;
            case 'today':
                this.dateObj = new Date();
                patchState({
                    date:
                        this.dateObj.getDate() +
                        ' de ' +
                        this.months[this.dateObj.getMonth()] +
                        ' de ' +
                        this.dateObj.getFullYear(),
                    when: '/day/today',
                });
                break;
            case 'last-7-days':
                this.dateObj = new Date();
                this.dateObj.setDate(this.dateObj.getDate() - 7);
                patchState({
                    date: `Últimos 7 días`,
                    when: '/day/last7',
                });
                break;
            case 'current-month':
                this.dateObj = new Date();
                patchState({
                    date: this.months[this.dateObj.getMonth()],
                    when: '/month/today',
                });
                break;
            case 'last-trimester':
                this.dateObj = new Date();
                patchState({
                    date: `${this.months[dayjs().subtract(3, 'month').month()]} - ${
                        this.months[dayjs().subtract(2, 'month').month()]
                    } - ${this.months[dayjs().subtract(1, 'month').month()]}`,
                    when: '/day/last90',
                });
                break;
            case 'current-year':
                this.dateObj = new Date();

                patchState({
                    date: this.dateObj.getFullYear().toString(),
                    when: '/year/today',
                });
                break;
            default:
                // todo: endpoint-personalizar
                this.dateObj = new Date(date);
                patchState({
                    date:
                        this.dateObj.getDate() +
                        ' de ' +
                        this.months[this.dateObj.getMonth()] +
                        ' de ' +
                        this.dateObj.getFullYear(),
                });
                break;
        }
    }

    @Action(SetRangeDate)
    public setRangeDate({ patchState }: StateContext<StoreStateModel>, { date }: SetRangeDate) {
        const dateStart = new Date(date.start);
        const dateEnd = new Date(date.end);
        const dateStartForApi = dateStart.getFullYear() + '-' + (dateStart.getMonth() + 1) + '-' + dateStart.getDate();
        const dateEndForApi = dateEnd.getFullYear() + '-' + (dateEnd.getMonth() + 1) + '-' + dateEnd.getDate();

        patchState({
            date:
                dateStart.getDate() +
                ' de ' +
                this.months[dateStart.getMonth()] +
                ' de ' +
                dateStart.getFullYear() +
                '- ' +
                dateEnd.getDate() +
                ' de ' +
                this.months[dateEnd.getMonth()] +
                ' de ' +
                dateEnd.getFullYear(),
            when: `/range/${dateStartForApi},${dateEndForApi}`,
        });
    }

    @Action(CallForVisitsSummaryChart)
    public callForVisitSummaryChart(
        { getState, patchState, dispatch }: StateContext<StoreStateModel>,
        { when }: CallForVisitsSummaryChart
    ) {
        dispatch(new SetLoading(true));
        const state = getState();
        if (state.when === '/day/today' || state.when === '/day/yesterday' || state.when === '/day/last7') {
            this.webSite.getVisitsSummary('/day/last7').subscribe((res: ResponseStatics) => {
                const data: Object = JSON.parse(res.data['visits-summary']);
                const arrayToChart = [];
                for (const key of Object.keys(data)) {
                    arrayToChart.push({ label: key, value: data[key].nb_visits });
                }
                patchState({
                    visitsSummaryChart: arrayToChart,
                });
                dispatch(new SetLoading(false));
            });
        }

        if (state.when === '/month/today' || state.when === '/day/last90') {
            this.webSite.getVisitsSummary('/month/last6').subscribe((res: ResponseStatics) => {
                const data: Object = JSON.parse(res.data['visits-summary']);
                const arrayToChart = [];
                for (const key of Object.keys(data)) {
                    arrayToChart.push({ label: key, value: data[key].nb_visits });
                }
                patchState({
                    visitsSummaryChart: arrayToChart,
                });
                dispatch(new SetLoading(false));
            });
        }
        if (state.when === '/year/today') {
            this.webSite.getVisitsSummary('/year/last3').subscribe((res: ResponseStatics) => {
                const data: Object = JSON.parse(res.data['visits-summary']);
                const arrayToChart = [];
                for (const key of Object.keys(data)) {
                    arrayToChart.push({ label: key, value: data[key].nb_visits });
                }
                patchState({
                    visitsSummaryChart: arrayToChart,
                });
                dispatch(new SetLoading(false));
            });
        }
        // if (state.when.startsWith('/range')) {
        //     const range1 = state.when.split(',')[0].split('/')[2];
        //     const date1 = new Date(range1);
        //     const range2 = state.when.split(',')[1];
        //     const date2 = new Date(range2);
        //     let data1;
        //     let data2;
        //     if (date1.getMonth() !== date2.getMonth()) {
        //         this.webSite.getVisitsSummaryByMonth(range1).subscribe((res: ResponseStatics) => {
        //             data1 = JSON.parse(res.data['visits-summary']);
        //             this.webSite.getVisitsSummaryByMonth(range2).subscribe((res1: ResponseStatics) => {
        //                 data2 = JSON.parse(res1.data['visits-summary']);
        //                 const arrayToChart = [];
        //                 arrayToChart.push({
        //                     label: `${this.months[date1.getMonth()]} de ${date1.getFullYear()}`,
        //                     value: data1.nb_visits
        //                 }, {label: `${this.months[date2.getMonth()]} de ${date2.getFullYear()}`, value: data2.nb_visits});
        //
        //                 patchState({
        //                     visitsSummaryChart: arrayToChart
        //                 });
        //             });
        //         });
        //     } else {
        //         const dateWithOneLessMonth = date2.getFullYear() + '-' + (date2.getMonth()) + '-' + date2.getDate();
        //         this.webSite.getVisitsSummaryByMonth(dateWithOneLessMonth).subscribe((res: ResponseStatics) => {
        //             data1 = JSON.parse(res.data['visits-summary']);
        //             this.webSite.getVisitsSummaryByMonth(range2).subscribe((res1: ResponseStatics) => {
        //                 data2 = JSON.parse(res1.data['visits-summary']);
        //                 const arrayToChart = [];
        //                 arrayToChart.push({
        //                     label: `${this.months[date2.getMonth() - 1]} de ${date2.getFullYear()}`,
        //                     value: data2.nb_visits
        //                 }, {label: `${this.months[date1.getMonth()]} de ${date1.getFullYear()}`, value: data1.nb_visits});
        //
        //                 patchState({
        //                     visitsSummaryChart: arrayToChart
        //                 });
        //             });
        //         });
        //     }
        // }
    }

    @Action(CallForVisitsByCountry)
    public callForVisitsByCountry(
        { getState, patchState, dispatch }: StateContext<StoreStateModel>,
        { when }: CallForVisitsByCountry
    ) {
        dispatch(new SetLoading(true));
        const state = getState();
        this.webSite.getVisitsByCountry(when).subscribe((res: ResponseStatics) => {
            if (
                state.when === '/day/today' ||
                state.when === '/day/yesterday' ||
                state.when === '/month/today' ||
                state.when === '/year/today' ||
                state.when.startsWith('/range')
            ) {
                patchState({
                    userCountry: [...JSON.parse(res.data['user-country'])],
                });
            } else {
                const data = JSON.parse(`${res.data['user-country']}`);
                if (_.isEmpty(data)) {
                    patchState({
                        userCountry: [],
                    });
                }
                const arrayDataMerged = this.mergeData(data);
                patchState({
                    userCountry: [...arrayDataMerged],
                });
            }
            dispatch(new SetLoading(false));
        });
    }

    @Action(CallForVisitsByPage)
    public callForVisitsByPage(
        { getState, patchState, dispatch }: StateContext<StoreStateModel>,
        { when }: CallForVisitsByPage
    ) {
        dispatch(new SetLoading(true));
        const state = getState();
        this.webSite.getVisitsByEntryPage(when).subscribe((res: ResponseStatics) => {
            if (
                state.when === '/day/today' ||
                state.when === '/day/yesterday' ||
                state.when === '/month/today' ||
                state.when === '/year/today' ||
                state.when.startsWith('/range')
            ) {
                const values = JSON.parse(res.data['entry-page']);
                let totalPagesViews = 0;
                values.map((item) => {
                    totalPagesViews += item.nb_visits;
                });
                patchState({
                    entryPage: [...values],
                    totalPagesViews: totalPagesViews,
                });
            } else {
                const data: Object = JSON.parse(res.data['entry-page']);
                if (_.isEmpty(data, true)) {
                    patchState({
                        entryPage: [],
                    });
                }
                const arrayDataMerged = this.mergeData(data);
                let totalPagesViews = 0;
                arrayDataMerged.map((item) => {
                    totalPagesViews += item.nb_visits;
                });
                patchState({
                    entryPage: [...arrayDataMerged],
                    totalPagesViews: totalPagesViews,
                });
            }
            dispatch(new SetLoading(false));
        });
    }

    @Action(CallForVisitsByDevices)
    public callForVisitsByDevices(
        { getState, patchState, dispatch }: StateContext<StoreStateModel>,
        { when }: CallForVisitsByDevices
    ) {
        dispatch(new SetLoading(true));
        const state = getState();
        this.webSite.getVisitsByDevices(when).subscribe((res: ResponseStatics) => {
            if (
                state.when === '/day/today' ||
                state.when === '/day/yesterday' ||
                state.when === '/month/today' ||
                state.when === '/year/today' ||
                state.when.startsWith('/range')
            ) {
                patchState({
                    devices: [...JSON.parse(res.data['device-detection'])],
                });
            } else {
                const data: Object = JSON.parse(res.data['device-detection']);
                if (_.isEmpty(data, true)) {
                    patchState({
                        devices: [],
                    });
                }

                const arrayDataMerged = this.mergeData(data);
                patchState({
                    devices: [...arrayDataMerged],
                });
            }
            dispatch(new SetLoading(false));
        });
    }

    @Action(CallForVisitsByOrigin)
    public callForVisitsByOrigin(
        { getState, patchState, dispatch }: StateContext<StoreStateModel>,
        { when }: CallForVisitsByOrigin
    ) {
        dispatch(new SetLoading(true));
        const state = getState();
        this.webSite.getVisitsByOrigins(when).subscribe((res: ResponseStatics) => {
            if (
                state.when === '/day/today' ||
                state.when === '/day/yesterday' ||
                state.when === '/month/today' ||
                state.when === '/year/today' ||
                state.when.startsWith('/range')
            ) {
                patchState({
                    referrers: [...JSON.parse(res.data.referrers)],
                });
            } else {
                const data: Object = JSON.parse(`${res.data.referrers}`);
                if (_.isEmpty(data, true)) {
                    patchState({
                        referrers: [],
                    });
                }

                const arrayDataMerged = this.mergeData(data);
                patchState({
                    referrers: [...arrayDataMerged],
                });
            }
            dispatch(new SetLoading(false));
        });
    }

    @Action(CallForVisitsByBrowser)
    public callForVisitsByBrowser(
        { getState, patchState, dispatch }: StateContext<StoreStateModel>,
        { when }: CallForVisitsByBrowser
    ) {
        dispatch(new SetLoading(true));
        const state = getState();
        this.webSite.getVisitsByBrowser(when).subscribe((res: ResponseStatics) => {
            if (
                state.when === '/day/today' ||
                state.when === '/day/yesterday' ||
                state.when === '/month/today' ||
                state.when === '/year/today' ||
                state.when.startsWith('/range')
            ) {
                patchState({
                    browsers: [...JSON.parse(res.data.browsers)],
                });
            } else {
                const data: Object = JSON.parse(res.data.browsers);
                if (_.isEmpty(data, true)) {
                    patchState({
                        browsers: [],
                    });
                }

                const arrayDataMerged = this.mergeData(data);
                patchState({
                    browsers: [...arrayDataMerged],
                });
            }
            dispatch(new SetLoading(false));
        });
    }

    mergeData(data: Object) {
        const mergeData = [];
        for (const key of Object.keys(data)) {
            if (!_.isEmpty(data[key])) {
                const arrayOfData: Array<any> = data[key];
                arrayOfData.forEach((loopObject) => {
                    let foundValue = false;
                    mergeData.map((mergedData: MergeData) => {
                        if (mergedData.label === loopObject.label) {
                            mergedData.nb_visits += loopObject.nb_visits;
                            foundValue = true;
                        }
                    });
                    if (!foundValue) {
                        mergeData.push(loopObject);
                    }
                });
            }
        }
        return mergeData;
    }

    @Action(ResetCharts)
    public resetCharts({ patchState }: StateContext<StoreStateModel>) {
        patchState({
            visitsSummaryChart: [],
            visitsSummary: {},
            userCountry: [],
            entryPage: [],
            devices: [],
            referrers: [],
            browsers: [],
            totalPagesViews: 0,
        });
    }

    @Action(SetLoading)
    public setLoading({ patchState }: StateContext<StoreStateModel>, { param }: SetLoading) {
        patchState({ loading: param });
    }

    @Action(SetStatisticsNav)
    public setNav({ patchState }: StateContext<StoreStateModel>) {
        const nav = [
            {
                label: this.translate.instant('app.statistics.ventasLabel'),
                link: 'sales',
                icon: 'light fa-tag',
                show: true,
                availableOnlyInFeature: [2],
            },
            {
                label: this.translate.instant('app.statistics.visitas'),
                link: 'visits',
                icon: 'user',
                show: true,
            },
            {
                label: this.translate.instant('app.statistics.fuentes_trafico'),
                link: 'source-traffic',
                icon: 'traffic-cone',
                show: true,
            },
            {
                label: this.translate.instant('app.statistics.nav_sistema_operativo'),
                link: 'browser-os',
                icon: 'phone-laptop',
                show: true,
            },
            {
                label: this.translate.instant('app.statistics.ubicacion_geografica'),
                link: 'geo-location',
                icon: 'globe-americas',
                show: true,
            },
        ];

        const servicesIds = this.store.selectSnapshot(AppState.getServicesList);

        if (servicesIds?.length) {
            nav.forEach((item) => {
                if (item.availableOnlyInFeature && item.availableOnlyInFeature.length) {
                    item.show = _.intersection(servicesIds, item.availableOnlyInFeature).length > 0;
                }
            });
        } else {
            this.store.dispatch(new GetGeneralInfoApp()).subscribe((response) => {
                const services = response.Sidebar.initialInfo.data.services.map(({ id }) => id);
                nav.forEach((item) => {
                    if (item.availableOnlyInFeature && item.availableOnlyInFeature.length) {
                        item.show = _.intersection(services, item.availableOnlyInFeature).length > 0;
                    }
                });
            });
        }

        patchState({
            nav: nav,
        });
    }

    @Action(CallForSales)
    public callForSales(
        { getState, patchState, dispatch }: StateContext<StoreStateModel>,
        { when, currency }: CallForVisitsSummary
    ) {
        dispatch(new SetLoading(true));
        const FILTER_MAP: FilterMap = Object.freeze({
            '/day/yesterday': this.processYesterdayFilter,
            '/day/today': this.processTodayFilter,
            '/day/last7': this.processLast7Filter,
            '/month/today': this.processMonthFilter,
            '/day/last90': this.processLast90Filter,
            '/year/today': this.processYearFilter,
            '/range': this.processRangeFilter,
        });
        let filters: SalesFilters = {};

        this.webSite.getSales(when).subscribe((res) => {
            if (when.startsWith('/range')) {
                const startAndLimit: string[] = when.split('/range/')[1].split(',');
                const dateStart: string = utils.changeDateFormat(startAndLimit[0], '00:00:00');
                const dateLimit: string = utils.changeDateFormat(startAndLimit[1], '23:59:59');
                filters = FILTER_MAP['/range'](dateStart, dateLimit);
            } else {
                filters = FILTER_MAP[`${when}`]();
            }
            this.store.dispatch(new CallForOrders({ ...filters, start: 0, limit: 5, currency_code: currency }));
            patchState({
                sales: res?.data?.sales,
            });
        });
    }

    @Action(CallForOrders)
    public callForOrders(
        { getState, patchState, dispatch }: StateContext<StoreStateModel>,
        { filters }: CallForOrders
    ) {
        this.store.dispatch(new SetFilters(filters)).subscribe({
            next: () => {
                this.store.dispatch(new GetSales()).subscribe({
                    next: () => {
                        dispatch(new SetLoading(false));
                    },
                    error: () => dispatch(new SetLoading(false)),
                });
            },
            error: () => dispatch(new SetLoading(false)),
        });
    }

    public processYesterdayFilter(): SalesFilters {
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        const startValue = utils.subtractDays(now, -1);
        const date_start = utils.transformDate(startValue);
        now.setHours(23, 59, 59);
        const date_limit = utils.transformDate(now);
        return { date_start, date_limit };
    }

    public processTodayFilter(): SalesFilters {
        const now = new Date();
        const limitValue = utils.transformDate(now);
        now.setHours(0, 0, 0, 0);
        const date_start = utils.transformDate(now);
        const date_limit = limitValue;
        return { date_start, date_limit };
    }

    public processLast7Filter(): SalesFilters {
        const now = new Date();
        const limitValue = utils.transformDate(now);
        const modifiedDate = utils.subtractDays(now, -7);
        const startValue = utils.transformDate(modifiedDate);
        const date_start = startValue;
        const date_limit = limitValue;
        return { date_start, date_limit };
    }

    public processMonthFilter(): SalesFilters {
        const now = new Date();
        const currentMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        currentMonth.setHours(0, 0, 0, 0);
        const date_start = utils.transformDate(currentMonth);
        const date_limit = utils.transformDate(now);
        return { date_start, date_limit };
    }

    public processLast90Filter(): SalesFilters {
        const now = new Date();
        const date_limit = utils.transformDate(now);
        const modifiedDate = utils.subtractDays(now, -90);
        const date_start = utils.transformDate(modifiedDate);
        return { date_start, date_limit: date_limit };
    }

    public processYearFilter(): SalesFilters {
        const now = new Date();
        const currentYear = new Date(now.getFullYear(), 0, 1);
        currentYear.setHours(0, 0, 0, 0);
        const date_start = utils.transformDate(currentYear);
        const date_limit = utils.transformDate(now);
        return { date_start, date_limit };
    }

    public processRangeFilter(date_start: string, date_limit: string): SalesFilters {
        return { date_start, date_limit };
    }
}
