<mc-loading *ngIf="loading$ | async" [overlay]="true" [fixed]="true"></mc-loading>

<version-bar *ngIf="(showAppVersionBanner$ | async) && (isLogged$ | async)"></version-bar>

<div
    class="layout-page d-block d-md-flex"
    load-mercadopago-script
    load-stripe-script
    [class.sidebar-collapsed]="sidebarCollapsed$ | async"
    [class.sidebar-expanded]="!(sidebarCollapsed$ | async)"
    [style.min-height]="(internalServerError$ | async) ? '100vh' : ''"
    [hidden]="loading$ | async"
>
    <ng-container *ngIf="isLogged$ | async">
        <mc-sidebar *ngIf="!(hideSidebar$ | async)"></mc-sidebar>
        <app-trial-expired
            *ngIf="(wizardStatus$ | async) && !(isWizardLoading$ | async) && showBanner"
            [bannerType]="bannerType"
            [remainingDemoDays]="remainingDemoDays"
        ></app-trial-expired>
    </ng-container>

    <div
        [ngStyle]="{
            'margin-top.px': showBanner ? trialEndedNotificationHeight : 0,
            width: showBanner ? '100%' : '100%'
        }"
    >
        <router-outlet></router-outlet>
    </div>
</div>
