import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { CommomHeaders } from '@app/_shared/httpHeaders/CommonHeaders';
import { WizardItem } from '@app/_models/wizard/wizard-item.model';
import { ContactData } from '@app/_interfaces/configuration/contactdata.interface';
import { environment } from '@env/environment.local';
import { SocialRed } from '@app/_models/social-media/social-media.model';
import { CustomTheme, SelectedTheme } from '@app/_models/themes/theme.model';
import { ChangeTemplateData } from '@app/_models/templates/template.model';

@Injectable({
    providedIn: 'root',
})
export class MarketService {
    constructor(private api: ApiService, private headers: CommomHeaders) {}
    getGeneralInfoApp(): Observable<any> {
        return this.api.getFz(`/api/v1/back/info/initial`, this.headers.getAuthHeaders());
    }

    getInfoMainMarket(): Observable<any> {
        return this.api.getFz(`/api/v1/back/mainmarket`, this.headers.getAuthHeaders());
    }

    getVerticalList(): Observable<any> {
        return this.api.getFz(`/api/v1/back/verticals`, this.headers.getAuthHeaders());
    }

    setVertical(vertical: WizardItem): Observable<any> {
        return this.api.patch(`/api/v1/back/mainmarket/vertical`, vertical, this.headers.getAuthHeaders());
    }
    // THEMES
    getThemes(): Observable<any> {
        return this.api.getFz(`/api/v1/back/themes`, this.headers.getAuthHeaders());
    }

    setTheme(data: SelectedTheme): Observable<any> {
        return this.api.patch(`/api/v1/back/themes/select`, data, this.headers.getAuthHeaders());
    }

    getCustomThemes(): Observable<any> {
        return this.api.getFz(`/api/v1/back/themes/custom`, this.headers.getAuthHeaders());
    }

    setCustomTheme(data): Observable<any> {
        return this.api.post(`/api/v1/back/themes/custom`, data, this.headers.getAuthHeaders());
    }

    updateCustomTheme(data: CustomTheme) {
        return this.api.put(`/api/v1/back/themes/custom/${data.id}`, data, this.headers.getAuthHeaders());
    }

    deleteCustomTheme(data: CustomTheme) {
        return this.api.delete(`/api/v1/back/themes/custom/${data.id}`, this.headers.getAuthHeaders());
    }

    getFonts(): Observable<any> {
        return this.api.getFz(
            `https://www.googleapis.com/webfonts/v1/webfonts?key=${environment.fonts_api_key}`,
            this.headers.getAuthHeaders()
        );
    }
    // THEMES
    getTemplates(verticalId: number): Observable<any> {
        if (verticalId) {
            return this.api.getFz(`/api/v1/back/templates?vertical=${verticalId}`, this.headers.getAuthHeaders());
        } else {
            return this.api.getFz(`/api/v1/back/templates`, this.headers.getAuthHeaders());
        }
    }

    getAllTemplates(pageNumber: number): Observable<any> {
        const params: string = `?page=${pageNumber}`;
        return this.api.getFz(`/api/v1/back/templates${params}`, this.headers.getAuthHeaders());
    }

    applyTemplate(options: ChangeTemplateData): Observable<any> {
        return this.api.put(`/api/v1/back/templates/apply`, options, this.headers.getAuthHeaders());
    }

    setTemplate(idtemplate: number): Observable<any> {
        return this.api.post(`/api/v1/back/templates`, { idtemplate: idtemplate }, this.headers.getAuthHeaders());
    }
    getInfoDashboard(): Observable<any> {
        return this.api.getFz(`/api/v1/back/dashboard`, this.headers.getAuthHeaders());
    }

    setContactData(data: ContactData): Observable<any> {
        return this.api.put(`/api/v1/back/config/contact-info`, data, this.headers.getAuthHeaders());
    }

    setWhatsappData(data): Observable<any> {
        return this.api.put(`/api/v1/back/config/whatsapp-button`, data, this.headers.getAuthHeaders());
    }

    getImageLogo(name: string) {
        return `${
            environment.url_sitio
        }/${name}/admin/galeriaimagenes/obtenerimagen/?time=${Date.now()}&id=sitio_logo&useDensity=true&width=200&height=200&tipoEscala=crop`;
    }

    setEmailAndAlias(emailAndAlias: any): Observable<any> {
        return this.api.patch(`/api/v1/back/mainmarket/mail-alias`, emailAndAlias, this.headers.getAuthHeaders());
    }

    setPersonalizationCode(data: any) {
        return this.api.put(`/api/v1/back/config/custom-code`, data, this.headers.getAuthHeaders());
    }

    setSocialMedia(socialMediaList: SocialRed[]): Observable<any> {
        return this.api.put(
            `/api/v1/back/config/social-media`,
            { socialNetworks: socialMediaList },
            this.headers.getAuthHeaders()
        );
    }

    setWizardStatus(param: boolean): Observable<any> {
        return this.api.postFz(`/api/v1/back/wizard/wizard-inicial/finish`, this.headers.getAuthHeaders());
    }

    getCustomCode(): Observable<any> {
        return this.api.getFz(`/api/v1/back/config/full`, this.headers.getAuthHeaders());
    }

    getPagesLanguages() {
        return this.api.getFz(`/api/v1/back/languages`, this.headers.getAuthHeaders());
    }
    setPanelLanguage(lang: string) {
        const language = { marketlocale: lang };
        return this.api.put(`/api/v1/back/config/language`, language, this.headers.getAuthHeaders());
    }
}
