import { AbstractControl, ValidatorFn } from '@angular/forms';

export function password(): ValidatorFn {
    const passwordRe: RegExp = new RegExp(
        /^(?!.*(012|123|234|345|456|567|678|789))(?=.*\d)(?=.*[a-z])(?=.*[\*@\/])(?=.*[A-Z])[\da-zA-Z\*\/@]{8,30}$/i
    );

    return (control: AbstractControl): { [key: string]: any } | null =>
        (control.value && !passwordRe.test(control.value) && { password: true }) || null;
}
